import React, { useEffect, useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet, Input } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import PasswordInputField from "../../components/PasswordInputField.component";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import { WarningAlert } from "../../components";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface ForgotPasswordProps {
  open: boolean;
  setOpen: any;
  forgotFormType?: string;
  resetEmail?: string;
  accesskey?: string;
  setOpenLogin?: any;
}

// checked
const ForgotPassword = memo(function ForgotPassword({
  open,
  setOpen,
  forgotFormType,
  setOpenLogin,
}: ForgotPasswordProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [formType, setFormType] = useState("forgot");
  const [password, setPassword] = useState("");
  const [resetKey, setResetKey] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [resetPasswordKey, setResetPasswordKey] = useState("");
  const [evs, setEvs] = useState<any>(Services.Storage.getEVS());
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState("");

  const resetPasswordLink = () => {
    setIsLoading(true);
    setOpen(false);
    setOpenLogin && setOpenLogin(false);
    const data = {
      email: email.trim(),
    };
    const evs_data = {
      is_email_sent: true,
      email_sent_time: new Date(),
      times_of_email_sent: (evs && evs.times_of_email_sent + 1) || 1,
    };
    Services.Auth.ResetPasswordLink(data)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          Swal.fire(
            t("forgot_password_modal.sweat_alert.success_title"),
            t("forgot_password_modal.sweat_alert.reset_success_message")
          );
          Services.Storage.setEVS(evs_data);
          setEvs(evs_data);
          return timeDifference();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire(
          t("forgot_password_modal.sweat_alert.error_title"),
          t("forgot_password_modal.sweat_alert.error_success_message")
        );
      });
  };

  useEffect(() => {
    forgotFormType && setFormType(forgotFormType);
  }, [forgotFormType]);

  useEffect(() => {
    if (formType !== "forgot" && location?.search != "") {
      const searchInfo = location.search.split("&");
      const mail = searchInfo[0].split("=")[1];
      const accesskey = searchInfo[1].split("=")[1];
      setResetKey(accesskey);
      setEmail(mail);
    }
  }, [location, formType]);

  const resetPassword = () => {
    setIsLoading(true);
    setOpen(false);
    setOpenLogin && setOpenLogin(false);
    const data = {
      email: email.trim(),
      reset_password_key: resetPasswordKey.trim(),
      password: password.trim(),
    };
    Services.Auth.ResetPassword(data)
      .then((res) => {
        setIsLoading(false);
        Swal.fire({
          icon: res.status ? "success" : "error",
          title: res.message,
        });
        if (res.status) {
          Services.Storage.clearSession();
          return navigate("/");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
          });
        }
        return navigate("/", { replace: true });
      });
  };

  const verifyResetToken = () => {
    setIsLoading(true);
    const data = {
      email: email,
      reset_token: resetKey,
    };
    Services.Auth.VerifyPasswordToken(data)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          setIsTokenVerified(true);
          setResetPasswordKey(res.data.reset_password_key);
          setOpen(true);
        } else {
          setIsTokenVerified(false);
          return navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setOpen(false);
        setOpenLogin && setOpenLogin(false);
        setIsTokenVerified(false);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
          });
        }
        return navigate("/", { replace: true });
      });
  };

  const handleSubmit = () => {
    if (formType === "forgot") {
      resetPasswordLink();
    } else {
      isTokenVerified && resetPassword();
    }
  };

  useEffect(() => {
    resetKey && verifyResetToken();
  }, [resetKey]);

  const timeDifference = () => {
    let isdisabled = false;
    if (evs && Object.keys(evs)?.length > 0 && formType === "forgot") {
      const emailSentMinute = moment(evs.email_sent_time).format("mm");
      const currentMinute = moment(new Date()).format("mm");
      const difference = Math.abs(
        Number(currentMinute) - Number(emailSentMinute)
      );
      // we can reset a password only 3 time in 24 hours
      if (evs?.times_of_email_sent > 3) {
        // if password has been sent over 3 times in a day then reset password butn should be disabled
        difference < 1440 ? (isdisabled = true) : (isdisabled = false);
        setMessage(difference < 1440 ? t("sopqhotr") : "");
      } else {
        // we can reset password after 5 minutes
        difference < 5 ? (isdisabled = true) : (isdisabled = false);
        setMessage(difference < 5 ? t("sopqhomn") : "");
      }
    } else {
      isdisabled = false;
    }
    setDisabled(isdisabled);
  };

  useEffect(() => {
    timeDifference();
  }, [evs, formType]);

  return (
    <>
      <div>
        {((formType !== "forgot" && isTokenVerified) ||
          formType === "forgot") && (
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            hideBackdrop={true}
            onClose={() => {
              setOpen(false);
              setOpenLogin && setOpenLogin(false);
            }}
            sx={Utils.Theme.JoyModalStyle}
          >
            <Sheet variant="outlined" sx={Utils.Theme.JoyModalSheetStyle}>
              <ModalClose
                variant="outlined"
                sx={Utils.Theme.JoyModalCloseStyle}
              />
              <Formik
                initialValues={{ email: "", password: "", confirmPassword: "" }}
                onSubmit={async (values) => {
                  await new Promise((resolve) => setTimeout(resolve, 500));
                  handleSubmit();
                }}
                validationSchema={Yup.object().shape({
                  email:
                    formType === "forgot"
                      ? Yup.string()
                          .trim()
                          .email()
                          .required(
                            t(
                              "forgot_password_modal.validation.email.required_message"
                            )
                          )
                      : Yup.string().trim(),
                  password:
                    formType !== "forgot"
                      ? Yup.string()
                          .trim()
                          .required(
                            t(
                              "forgot_password_modal.validation.password.required_message"
                            )
                          )
                          .max(
                            8,
                            t(
                              "forgot_password_modal.validation.password.max_length_message"
                            )
                          )
                          .matches(
                            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$!%*?&~()_\-=+<>?/^{};:'"|.,\[\]\\])[A-Za-z\d@#$!%*?&~()_\-=+<>?/^{};:'"|.,\[\]\\]{6,8}$/,
                            t(
                              "forgot_password_modal.validation.password.match_message"
                            )
                          )
                          .min(
                            6,
                            t(
                              "forgot_password_modal.validation.password.min_length_message"
                            )
                          )
                      : Yup.string().trim(),
                  confirmPassword:
                    formType !== "forgot"
                      ? Yup.string()
                          .trim()
                          .required(
                            t(
                              "forgot_password_modal.validation.confirm_password.required_message"
                            )
                          )
                          .oneOf(
                            [Yup.ref("password")],
                            t(
                              "forgot_password_modal.validation.confirm_password.one_of"
                            )
                          )
                          .min(
                            6,
                            t(
                              "forgot_password_modal.validation.confirm_password.min_length_message"
                            )
                          )
                          .max(
                            8,
                            t(
                              "forgot_password_modal.validation.confirm_password.max_length_message"
                            )
                          )
                      : Yup.string().trim(),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form
                      onSubmit={handleSubmit}
                      autoComplete={"off"}
                      noValidate
                      className="modalForm"
                    >
                      <div className="w-100">
                        <div className="flex flex-row ">
                          <div className=" w-full">
                            <div className="mx-auto my-auto  flex items-center justify-center h-auto w-12 ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-26 h-26 mb-3"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                />
                              </svg>
                            </div>
                            <h3 className="head text-center leading-6 font-bold text-[var(--dark-text-color)]">
                              {formType === "forgot"
                                ? t("forgot_password_modal.title.forgot")
                                : t("forgot_password_modal.title.reset")}{" "}
                              {t("forgot_password_modal.title.password")}
                            </h3>
                            <div className="flex justify-center items-center">
                              <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                                <Button
                                  variant="text"
                                  type="button"
                                  onClick={() => {
                                    document.getElementById("signin")?.click();
                                    setOpen(false);
                                    // setOpenLogin && setOpenLogin(false);
                                  }}
                                >
                                  {t(
                                    "forgot_password_modal.button_text.back_to_sign_in"
                                  )}
                                </Button>
                              </ThemeProvider>
                            </div>
                            <div
                              className={
                                formType !== "forgot" ? "hidden pt-8" : "pt-8"
                              }
                            >
                              <label htmlFor="email" className="p1">
                                {t(
                                  "forgot_password_modal.form.email_address_label"
                                )}
                                <sup className="requireField">*</sup>
                              </label>
                              <div className="InputFieldSetting">
                                <Input
                                  type="email"
                                  name="email"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setEmail(e.target.value?.trim());
                                    handleChange(e);
                                  }}
                                  value={values.email.trim()}
                                  sx={
                                    errors.email && touched.email
                                      ? Utils.Theme.JoyInputError
                                      : Utils.Theme.JoyGrayShadeInputStyle2
                                  }
                                  onBlur={handleBlur}
                                />
                              </div>
                              {((errors.email && touched.email) || message) && (
                                <WarningAlert
                                  message={errors.email || message}
                                />
                              )}
                            </div>
                            {formType !== "forgot" && (
                              <>
                                <div className="py-2">
                                  <PasswordInputField
                                    // fieldName = label
                                    fieldName={t(
                                      "forgot_password_modal.form.password_label"
                                    )}
                                    setPassword={setPassword}
                                    password={password}
                                    required={formType !== "forgot"}
                                    name="password"
                                    id="password"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    formikProps={props}
                                  />
                                  {errors.password && touched.password && (
                                    <WarningAlert message={errors.password} />
                                  )}
                                </div>
                                <div className="py-2">
                                  <PasswordInputField
                                    fieldName={t(
                                      "forgot_password_modal.form.confirm_password_label"
                                    )}
                                    setPassword={setConfirmPassword}
                                    password={confirmPassword}
                                    required={formType !== "forgot"}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    formikProps={props}
                                  />
                                  {errors.confirmPassword &&
                                    touched.confirmPassword && (
                                      <WarningAlert
                                        message={errors.confirmPassword}
                                      />
                                    )}
                                </div>
                              </>
                            )}

                            <div className="mt-4 ">
                              <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                                <Button
                                  variant="contained"
                                  type="submit"
                                  className=""
                                  disabled={
                                    isLoading || disabled || isSubmitting
                                  }
                                  fullWidth
                                >
                                  {t(
                                    "forgot_password_modal.button_text.submit"
                                  )}
                                </Button>
                              </ThemeProvider>
                            </div>
                            {formType === "forgot" && (
                              <>
                                <div className=" flex justify-start items-center">
                                  <ThemeProvider
                                    theme={Utils.Theme.ButtonTheme}
                                  >
                                    <Button
                                      variant="text"
                                      type="button"
                                      onClick={() => {
                                        window.open(
                                          "/about#sign in questions",
                                          "_blank"
                                        );
                                        setOpen(false);
                                        setOpenLogin && setOpenLogin(false);
                                      }}
                                    >
                                      {t(
                                        "forgot_password_modal.button_text.help"
                                      )}
                                    </Button>
                                  </ThemeProvider>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </Sheet>
          </Modal>
        )}
      </div>
    </>
  );
});

export default ForgotPassword;
