import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";
// import i18next from 'i18next';
import "./i18n";
// import "intl-pluralrules";
import Context from "./context";

// i18next.init({
//   lng: 'en', // if you're using a language detector, do not define the lng option
//   debug: true,
//   resources: {
//     en: {
//       translation: {
//         "key": "Welcome to purchase Auto Bids"
//       }
//     }
//   }
// });

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  hydrate(
    <Context.LanguageContextProvider>
      <App />
    </Context.LanguageContextProvider>,
    rootElement
  );
} else {
  render(
    <Context.LanguageContextProvider>
      <App />
    </Context.LanguageContextProvider>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
