import React, { useState, memo, useEffect } from "react";
import { ListingCardProps } from "../../type/Auction.type";
import { useNavigate } from "react-router-dom";
import Services from "../../services";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import CircleIcon from "./CircleIcon.component";
import Utils from "../../utils";

const ListingCardComponent = memo(
  ({
    id,
    imgSrc,
    title,
    descBtnType,
    description,
    detailList,
    address,
    listType,
    getListings,
    item,
  }: ListingCardProps) => {
    const { t } = useTranslation();
    const isUserLogedIn = isUser_loged_in();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [auctionData, setAuctionData] = useState<any>({});

    useEffect(() => {
      item && setAuctionData(item);
    }, [item]);

    const addToWatchList = (id: number) => {
      setLoading(true);
      const data = {
        listing_id: id,
      };
      Services.WatchList.AddToWatchList(data)
        .then((res) => {
          setLoading(false);
          setAuctionData({ ...item, watching: true });
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    const deleteFromWatchList = (id: number) => {
      setLoading(true);
      Services.WatchList.DeleteFromWatchList(id)
        .then((res) => {
          setLoading(false);
          setAuctionData({ ...item, watching: false });
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    const isAutionEnd = (data: any) => {
      if (
        data?.auction?.status === "live" &&
        Utils.CalculateDays(data?.auction?.auction_end) > 0
      ) {
        return false;
      }
      return true;
    };

    return (
      <div className="relative max-w-[500px] flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl border border-gray-200 px-4 py-3 space-y-1 h-full">
        <div>
          {!isAutionEnd(auctionData) &&
            auctionData?.has_reserve_price === false && (
              <span className="inline-block whitespace-nowrap rounded-full bg-black px-4 py-1.5 text-center align-baseline text-sm font-semibold leading-none text-yellow-400">
                {t("card_data.no_reserve")}
              </span>
            )}
        </div>
        <div>
          <div
            className="font-bold text-2xl cursor-pointer truncate"
            onClick={() =>
              navigate(
                `/auctions/${
                  auctionData?.year?.replace(/\s+/g, "-") +
                  "-" +
                  auctionData?.make?.replace(/\s+/g, "-") +
                  "-" +
                  auctionData?.model?.replace(/\s+/g, "-") +
                  (auctionData?.title
                    ? "-" + auctionData?.title?.replace(/\s+/g, "-")
                    : "")
                }`,
                {
                  state: { uuid: auctionData?.uuid },
                }
              )
            }
          >
            {auctionData?.title ? " " + auctionData?.title : ""}
          </div>
          <div className="text-gray-500 text-md truncate">
            {auctionData?.make}
            <CircleIcon className="w-1 h-1 text-gray-500 " />
            {auctionData?.model + " " + auctionData?.year}
            <CircleIcon className="w-1 h-1 text-gray-500 " />
            {auctionData?.mileage}
            {" KM"}
          </div>
        </div>
        <div>
          <div className="relative overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
            <div className=" flex justify-center">
              <img
                src={`${process.env.REACT_APP_HOST_API_KEY}/${
                  imgSrc?.exterior[0] || ""
                }`}
                alt={
                  auctionData?.year +
                  " " +
                  auctionData?.make +
                  " " +
                  auctionData?.model +
                  (auctionData?.title ? " " + auctionData?.title : "")
                }
                className="w-full cursor-pointer"
                loading="lazy"
                onClick={() =>
                  navigate(
                    `/auctions/${
                      auctionData?.year?.replace(/\s+/g, "-") +
                      "-" +
                      auctionData?.make?.replace(/\s+/g, "-") +
                      "-" +
                      auctionData?.model?.replace(/\s+/g, "-") +
                      (auctionData?.title
                        ? "-" + auctionData?.title.replace(/\s+/g, "-")
                        : "")
                    }`,
                    {
                      state: { uuid: auctionData?.uuid },
                    }
                  )
                }
              />
            </div>
            <Grid container spacing={0} className="rounded-b-xl p-0 h-fu ll">
              <Grid item xs={5.95} className="text-center bg-yellow-300 p-0">
                <div className="font-bold text-black text-2xl">{t("new")}</div>
                <div className="text-gray-500 text-md">{t("listing")}</div>
              </Grid>
              <Grid item xs={0.1} />
              <Grid item xs={5.95} className="text-center bg-yellow-300 p-0">
                <div className="font-bold text-black text-2xl">
                  {" "}
                  {auctionData?.current_bid?.price
                    ? auctionData?.current_bid?.price
                    : 0}{" "}
                  CHF
                </div>
                <div className="text-gray-500 text-md">
                  {auctionData?.auction?.status === "sold"
                    ? t("card_data.auction_status.sold")
                    : t("card_data.auction_status.bid")}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center truncate">
            <div className="text-md font-semibold">
              {t("card_data.body_type")}
            </div>
            <div className="text-md font-bold">{auctionData?.body_style}</div>
          </div>
          <div className="flex justify-between items-center truncate">
            <div className="text-md  font-semibold">
              {t("card_data.engine")}
            </div>
            <div className="text-md font-bold">{auctionData?.engine}</div>
          </div>
          <div className="flex justify-between items-center truncate">
            <div className="text-md  font-semibold">
              {t("card_data.transmission")}
            </div>
            <div className="text-md font-bold">{auctionData?.transmission}</div>
          </div>
          <div className="flex justify-between items-center truncate">
            <div className="text-md  font-semibold">{t("card_data.color")}</div>
            <div className="text-md font-bold">
              <div className="flex -space-x-2">
                <Tooltip title={t("details_submission_page.2xwpepu29")}>
                  <span
                    className={`w-6 h-6 border-2 border-white rounded-full drop-shadow-sm`}
                    style={{
                      background: auctionData?.interior_color,
                    }}
                  ></span>
                </Tooltip>
                <Tooltip title={t("details_submission_page.e5gzqci3g")}>
                  <span
                    className={`w-6 h-6 border-2 border-white rounded-full drop-shadow-sm`}
                    style={{
                      background: auctionData?.exterior_color,
                    }}
                  ></span>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center truncate">
            <div className="text-md  font-semibold">
              {t("card_data.locaiton")}
            </div>
            <div className="text-md font-bold">
              {auctionData?.zip_code} {auctionData?.city_and_province}{" "}
              {auctionData?.country}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ListingCardComponent;
