import { useEffect, useState, lazy, Suspense } from "react";
import { Icons, Images } from "../../assets";
import PageComponents from "../components";
import {
  Button,
  ThemeProvider,
  IconButton,
  Alert,
  Grid,
  Tooltip,
} from "@mui/material";
import Utils from "../../utils";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import SearchIcon from "@mui/icons-material/Search";
import ShareModal from "../../components/ShareDailog.component";
import Services from "../../services";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Countdown from "react-countdown";
import Swal from "sweetalert2";
import moment from "moment";
import {
  AuctionDetailsSkeletonComponent,
  WarningAlert,
} from "../../components";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import { AuthPages } from "../";
import ImagesGallery from "../components/ImagesGallery";
import AuctionCardComponent from "../components/AuctionCard.component";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Hooks from "../../hooks";

const CarShowCase = lazy(
  () => import("../components/CarShowCase.component.page")
);

const AddCreditCard = lazy(
  () => import("../components/AddCreditCard.component")
);

type typeShowCars = Boolean;
type anyObject = any;
type filterType = string | null;

type typeData = {
  text: string;
  parent_id?: number;
};

interface typeProps {
  list_id?: string | number;
}

export default function Auction({ list_id }: typeProps) {
  const screen = Hooks.useScreen();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const isUserLogedIn = isUser_loged_in();
  const { t } = useTranslation();
  const isUserLoged = Services.Auth.IsUserLogedIn();
  const [user, setUser] = useState(Services.Storage.getCurrentUser());
  const [showCars, setShowCars] = useState<boolean>(false);
  const [showCommentMenu, setShowCommentMenu] = useState<typeShowCars>(false);
  const [showCreditCard, setShowCreditCard] = useState<typeShowCars>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [auction_info, setAuctionInfo] = useState<anyObject>({});
  const [comments, setComments] = useState<any[]>([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState<filterType>(null);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [auction_images, setAuctionImages] = useState<any[]>([]);
  const [commentResponse, setCommentResponse] = useState<any>({});
  const [total, setTotal] = useState<number>(0);
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [loadOldComments, setLoadOldComments] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [auctionEndingSoon, setAuctionEndingSoon] = useState<any[]>([]);
  const [newlyListings, setNewlyListings] = useState<any[]>([]);
  const [pastAuctions, setPastAuctions] = useState<any[]>([]);
  const [commentErr, setCommentErr] = useState<string>("");
  const [auctionEnded, setAuctionEnded] = useState<boolean>(false);
  const [oneTimeReload, setOneTimeReload] = useState<boolean>(false);
  const [galleryHeight, setGalleryHeight] = useState<string>("auto");
  const [sliderIndex, setSliderIndex] = useState<number | null>(null);
  const [loadComment, setLoadComment] = useState<boolean>(false);
  const [id, setId] = useState<string | number>("");

  useEffect(() => {
    setAuctionEnded(
      auction_info?.auction?.status !== "live"
        ? true
        : Utils.CalculateDays(auction_info?.stats?.auction_end) <= 0
    );
  }, [
    Utils.CalculateDays(auction_info?.stats?.auction_end) > 0,
    auction_info?.auction?.status,
  ]);

  useEffect(() => {
    setId(list_id || params?.uuid || location?.state?.uuid || "");
  }, [location?.state, params?.uuid, list_id]);

  useEffect(() => {
    if (
      !oneTimeReload &&
      auction_info?.stats === "live" &&
      auction_info?.stats?.auction_end &&
      Utils.CalculateDays(auction_info?.stats?.auction_end) === 0 &&
      id
    ) {
      setOneTimeReload(true);
      window.location.reload();
    }
  }, [
    auction_info?.stats?.auction_end,
    Utils.CalculateDays(auction_info?.stats?.auction_end) === 0,
  ]);

  function auto_grow(element: any) {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + "px";
  }

  useEffect(() => {
    let dynamicTextArea = document.getElementById("dynamicTextArea");
    dynamicTextArea && auto_grow(dynamicTextArea);
  }, []);

  const showShareModal = () => {
    setIsModalOpen((open) => !open);
  };

  const getListOfAuction = (id: any, isLoader?: boolean) => {
    window?.scrollTo(0, 0);
    !isLoader && setLoading(true);
    Services.Listing.GetList(id)
      .then((res) => {
        setLoading(false);
        if (res.status && res.data) {
          let data = res.data;
          setAuctionInfo(data);
          if (data?.auction?.status === "live") {
            getResults("auctions", "ending_soon");
            return getResults("auctions", "newly_listed");
          }
          return getResults();
        } else {
          return navigate("/404");
        }
      })
      .catch((error) => {
        setLoading(false);
        return navigate("/404");
      });
  };

  const getComments = (id: any, isReset?: boolean) => {
    Services.Comment.GetComments(id, offset, limit, filter).then((res) => {
      if (res.status) {
        if (isReset) {
          return setComments(res.data);
        }
        if (res.status) {
          setTotal(res.total);
          if (loadOldComments) {
            if (res?.data?.length > 0) {
              if (comments.length > 0) {
                let allComments: any[] = [...comments];
                res?.data?.map((item: any) => {
                  let commentExist = false;
                  comments?.length > 0 &&
                    comments?.map((comment) => {
                      item.id === comment.id && (commentExist = true);
                    });
                  if (!commentExist) {
                    allComments.push(item);
                  }
                });
                return setComments(allComments);
              }
            }
            setLoadOldComments(false);
          }
          return setComments(res.data);
        }
      }
    });
  };

  useEffect(() => {
    if (id) {
      getListOfAuction(id);
      getComments(id);
    }
  }, [id]);

  useEffect(() => {
    auction_info?.id && getComments(auction_info.id);
  }, [auction_info]);

  useEffect(() => {
    id && getComments(id);
  }, [filter, loadOldComments, offset, id]);

  const handlePostComment = () => {
    const data: typeData = {
      text: comment,
    };
    if (commentResponse.id) {
      data.parent_id = commentResponse.id;
    }
    setLoadComment(true);
    Services.Comment.PostComment(
      params.uuid
        ? params.uuid
        : location.state.uuid
          ? location.state.uuid
          : location.state.id,
      data
    )
      .then((res) => {
        setLoadComment(false);
        if (res.status) {
          Swal.fire("Success", "Comment saved successfuly");
          setComment("");
          getComments(id, true);
          setCommentResponse({});
        }
      })
      .catch((error) => {
        setLoadComment(false);
      });
  };

  const handleCarImages = (photos: any) => {
    let array: any[] = [];
    Object.keys(photos)?.length > 0 &&
      Object.keys(photos)?.map((photoCategory: string) => {
        let photo = photos[photoCategory];
        photo?.map((item: string) => {
          array.push(item);
        });
      });

    setAuctionImages(array);
  };

  useEffect(() => {
    auction_info?.photos && handleCarImages(auction_info.photos);
  }, [auction_info]);

  const addToWatchList = (id?: number) => {
    setIsLoading(true);
    const data = {
      listing_id: id ? id : auction_info.id,
    };

    Services.WatchList.AddToWatchList(data)
      .then((res) => {
        setIsLoading(false);
        getListOfAuction(data?.listing_id, true);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const deleteFromWatchList = (id?: number) => {
    Services.WatchList.DeleteFromWatchList(id ? id : auction_info.id).then(
      (res) => {
        getListOfAuction(id ? id : auction_info.id, true);
      }
    );
  };

  const handleAuctionCount = () => {
    isUserLogedIn &&
      auction_info?.auction?.id &&
      Services.Auction.CountAuctionView(auction_info?.auction?.id);
  };

  useEffect(() => {
    handleAuctionCount();
  }, [auction_info]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleKeyDown = (e: any) => {
    if (e.key === "Backspace") {
      !comment && setCommentResponse({});
    }
  };

  const getResults = (list_type?: string, sort?: string) => {
    auctionEndingSoon?.length == 0 &&
      newlyListings?.length === 0 &&
      Services.Listing.GetListings(
        // offset 0
        0,
        list_type === "auctions" ? 3 : 6,
        list_type ? list_type : "results",
        sort
      ).then((res) => {
        if (res?.status && res?.data) {
          if (list_type === "auctions") {
            return sort === "ending_soon"
              ? setAuctionEndingSoon(res.data)
              : setNewlyListings(res.data);
          } else {
            return setPastAuctions(res.data);
          }
        }
      });
  };

  const getProfile = () => {
    isUserLogedIn &&
      Services.Profile.GetProfile().then((res) => {
        res?.status && setUser(res.data);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      const newImageHeight =
        (document.getElementById("main-image-in-gallery") as HTMLImageElement)
          ?.height || "auto";
      setGalleryHeight(`${newImageHeight}px`); // Update image Height when resize
    };

    window.addEventListener("resize", handleResize);

    handleResize();
    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (auction_images?.length > 0) {
      const newImageHeight =
        (document.getElementById("main-image-in-gallery") as HTMLImageElement)
          ?.height || "auto";
      setGalleryHeight(`${newImageHeight}px`); // Update image Height when update data
    }
  }, [auction_images]);

  const handleFilter = (value: string) => {
    setShowCommentMenu(!showCommentMenu);
    setFilter(value);
  };

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      if (e?.target?.id === "filterComments") {
        setShowCommentMenu(!showCommentMenu);
      } else {
        setShowCommentMenu(false);
      }
    });
  }, []);

  return (
    <>
      <PageComponents.MetaComponent
        title={
          auction_info?.title
            ? auction_info?.title
            : t("auction_details_page.title")
        }
      />
      {loading ? (
        <div className="containerSpacing mt-16">
          <AuctionDetailsSkeletonComponent />
        </div>
      ) : auction_info && Object.keys(auction_info)?.length > 0 ? (
        <div className="containerSpacing mt-16">
          <div className="">
            {/*   desktop title  */}
            <div className="leading-normal	pb-1 justify-between items-center hidden sm:flex">
              <div>
                <div className="flex justify-start items-center gap-2">
                  <h2 className="head	font-bold">
                    {auction_info?.year +
                      " " +
                      auction_info?.make +
                      " " +
                      auction_info?.model +
                      (auction_info?.title ? " " + auction_info?.title : "")}
                  </h2>
                  <button
                    className={`textColor flex-col gap-1 cursor-pointer ${
                      auction_info?.watching ? "" : "displayONHover"
                    }  transition-all dhrOnhover p1`}
                    onClick={() =>
                      isUserLoged
                        ? auction_info?.watching
                          ? deleteFromWatchList()
                          : addToWatchList()
                        : document.getElementById("signin")?.click()
                    }
                    disabled={loading || isLoading}
                  >
                    {auction_info?.watching ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 576 512"
                      >
                        <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                      </svg>
                    )}
                    <hr className="bgColor rounded p-[1px] shadow-sm" />
                  </button>
                </div>
                <div className="gap-1 flex items-baseline">
                  {auction_info?.auction?.status === "live" &&
                    !auction_info?.has_reserve_price &&
                    !auctionEnded && (
                      <p className="bgColor text-white tracking-wide p2 px-2.5 py-0.5 uppercase m-0 rounded  inline-block shadow-sm">
                        {t("auction_details_page.no_reserve_text")}
                      </p>
                    )}
                  <h3 className="p00">
                    {auction_info?.description && auction_info?.description}
                  </h3>
                </div>
              </div>
              <button
                type="button"
                className="flex justify-center items-center p00"
                onClick={showShareModal}
              >
                {t("auction_details_page.share")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="m-4 h-4 ml-2"
                >
                  <path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
                </svg>
              </button>
            </div>
            {auction_images?.length > 0 && (
              <ImagesGallery
                setShowCars={setShowCars}
                imageArray={auction_images}
                sliderIndex={sliderIndex}
                setSliderIndex={setSliderIndex}
              />
            )}
            {/*  alert title */}
            <div className="justify-between items-center flex sm:hidden py-2 ">
              <div className="w-full h-full">
                <div className="flex justify-start items-center gap-2">
                  <h2 className="head	font-bold ">
                    <span className="leading-normal">
                      {auction_info?.year +
                        " " +
                        auction_info?.make +
                        " " +
                        auction_info?.model +
                        (auction_info?.title ? " " + auction_info?.title : "")}
                    </span>
                  </h2>
                  <button
                    className={`textColor flex-col gap-1 cursor-pointer ${
                      auction_info?.watching ? "" : "displayONHover"
                    }  transition-all dhrOnhover`}
                    onClick={() =>
                      isUserLoged
                        ? auction_info?.watching
                          ? deleteFromWatchList()
                          : addToWatchList()
                        : document.getElementById("signin")?.click()
                    }
                    disabled={loading || isLoading}
                  >
                    {auction_info?.watching ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 576 512"
                      >
                        <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                      </svg>
                    )}
                    <hr className="bgColor rounded p-[1px] shadow-sm" />
                  </button>
                </div>
                <div className="flex items-baseline gap-1">
                  <h3 className="p00 leading-normal">
                    {" "}
                    {auction_info?.auction?.status === "live" &&
                      !auction_info?.has_reserve_price && (
                        <p className="bgColor text-white p2 tracking-wide	 px-2.5 py-0.5 uppercase m-0 rounded  inline-block shadow-sm w-fit text-nowrap">
                          {t("auction_details_page.no_reserve_text")}
                        </p>
                      )}{" "}
                    {auction_info?.description}
                  </h3>
                </div>
                <p className="textGray p1 font-light">
                  {auctionEnded
                    ? t("auction_details_page.ended_text")
                    : t("auction_details_page.ending_text")}{" "}
                  {auction_info?.stats?.auction_end &&
                    moment(auction_info?.stats?.auction_end).format("MMMM DD") +
                      " " +
                      t("auction_details_page.at_text") +
                      " " +
                      moment(auction_info?.stats?.auction_end).format(
                        "HH:MM A"
                      )}
                </p>
                <div className="flex justify-between items-end content-end w-full h-full pt-3 flex-wrap">
                  <div className="flex ">
                    <div className="border-l-8 borderColor mr-3 rounded"></div>
                    {/* <img src={Images.CarFax} alt="car fax" className="w-1/2" /> */}
                    <div className="w-full border p-1 rounded-lg shadow	flex justify-start items-center gap-1">
                      <div className="w-5">
                        <img
                          src={Icons.CarCheckList}
                          alt="car fax"
                          className="w-full "
                        />
                      </div>
                      <div className="flex flex-col justify-center items-start gap-0 leading-3 font-semibold text-justify">
                        <p className="text-[1rem]">Show Car</p>
                        <p className="p3">Check Report</p>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="flex justify-center items-center p00"
                    onClick={showShareModal}
                  >
                    {t("auction_details_page.share")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-4 ml-2 m-auto"
                    >
                      <path d="M400 255.4V240 208c0-8.8-7.2-16-16-16H352 336 289.5c-50.9 0-93.9 33.5-108.3 79.6c-3.3-9.4-5.2-19.8-5.2-31.6c0-61.9 50.1-112 112-112h48 16 32c8.8 0 16-7.2 16-16V80 64.6L506 160 400 255.4zM336 240h16v48c0 17.7 14.3 32 32 32h3.7c7.9 0 15.5-2.9 21.4-8.2l139-125.1c7.6-6.8 11.9-16.5 11.9-26.7s-4.3-19.9-11.9-26.7L409.9 8.9C403.5 3.2 395.3 0 386.7 0C367.5 0 352 15.5 352 34.7V80H336 304 288c-88.4 0-160 71.6-160 160c0 60.4 34.6 99.1 63.9 120.9c5.9 4.4 11.5 8.1 16.7 11.2c4.4 2.7 8.5 4.9 11.9 6.6c3.4 1.7 6.2 3 8.2 3.9c2.2 1 4.6 1.4 7.1 1.4h2.5c9.8 0 17.8-8 17.8-17.8c0-7.8-5.3-14.7-11.6-19.5l0 0c-.4-.3-.7-.5-1.1-.8c-1.7-1.1-3.4-2.5-5-4.1c-.8-.8-1.7-1.6-2.5-2.6s-1.6-1.9-2.4-2.9c-1.8-2.5-3.5-5.3-5-8.5c-2.6-6-4.3-13.3-4.3-22.4c0-36.1 29.3-65.5 65.5-65.5H304h32zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H408c39.8 0 72-32.2 72-72V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v64c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* all other info */}
          <div className="">
            <div className="flex  py-2  lg:w-full mx-auto ">
              {/* left bar info display */}
              <div className="w-full xl:w-8/12 xl:pr-1">
                {/* bidding info */}
                <div
                  className={`w-full mx-auto ${
                    auctionEnded
                      ? "bg-transparent"
                      : "fixed lg:static bottom-0 lg:top-[90px] bg-white z-[999999999]"
                  }  m-0 left-0 p-0 sm:px-2 lg:px-0 sm:py-1 lg:py-0`}
                >
                  <div className=" flex justify-center sm:justify-between items-stretch gap-0 sm:gap-0.5 md:gap-1 lg:gap-2 xl:gap-3 w-full">
                    <div
                      className={`flex   ${
                        Utils.CalculateDays(auction_info?.stats?.auction_end) >=
                          0 &&
                        Utils.CalculateDays(auction_info?.stats?.auction_end) <
                          1
                          ? " bg-[var(--error-color)] text-white"
                          : "bg-black textGray"
                      } justify-between items-center gap-0.5 sm:gap-2 px-5 py-1.5 sm:py-3 rounded-none sm:rounded p00 font-semibold w-full mx-0 ${
                        auctionEnded ? "sm:mx-1" : "sm:w-9/12 md:w-10/12"
                      }  cursor-pointer`}
                      onClick={() => {
                        document.getElementById("biddingInfo")?.scrollIntoView({
                          behavior: "smooth",
                          block: "end",
                          inline: "end",
                        });
                      }}
                    >
                      <div className=" flex gap-1 sm:gap-2 justify-start items-center p1">
                        {auctionEnded ? (
                          <Tooltip
                            title={
                              "Auction Ended at " +
                              moment(auction_info?.stats?.auction_end)?.format(
                                "MM/DD/YYYY"
                              )
                            }
                          >
                            <span className="text-white p1">
                              {moment(auction_info?.stats?.auction_end)?.format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={
                              t("sopqh3oex") +
                              " " +
                              (Utils.CalculateDays(
                                auction_info?.stats?.auction_end
                              ) > 1 ? (
                                Utils.CalculateDays(
                                  auction_info?.stats?.auction_end
                                )
                                  ?.toFixed(0)
                                  .toString() +
                                " " +
                                (Utils.CalculateDays(
                                  auction_info?.stats?.auction_end
                                ) > 1
                                  ? t("auction_details_page.days")
                                  : t("auction_details_page.day"))
                              ) : (
                                <Countdown
                                  date={auction_info?.stats?.auction_end}
                                />
                              ))
                            }
                          >
                            <div className="flex justify-start items-center gap-1 sm:gap-2 ">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="w-3.5 h-3.5 sm:w-4 sm:h-4"
                                >
                                  <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                                </svg>
                              </span>
                              <p className="hidden md:block">
                                {t("sopqh3oex")}
                              </p>
                              <span className="text-white">
                                {Utils.CalculateDays(
                                  auction_info?.stats?.auction_end
                                ) > 1 ? (
                                  Utils.CalculateDays(
                                    auction_info?.stats?.auction_end
                                  )
                                    ?.toFixed(0)
                                    .toString() +
                                  " " +
                                  (screen?.width > 640
                                    ? Utils.CalculateDays(
                                        auction_info?.stats?.auction_end
                                      ) > 1
                                      ? t("auction_details_page.days")
                                      : t("auction_details_page.day")
                                    : "d")
                                ) : (
                                  <Countdown
                                    date={auction_info?.stats?.auction_end}
                                  />
                                )}
                              </span>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      <Tooltip
                        title={
                          (auction_info?.auction?.status === "live"
                            ? t(
                                "auction_details_page.auction_status.highest_bid"
                              )
                            : auction_info?.auction?.status === "sold"
                              ? t("auction_details_page.auction_status.sold")
                              : t("auction_details_page.auction_status.bid")) +
                          " $" +
                          (auction_info?.current_bid?.price
                            ? auction_info?.current_bid?.price
                            : 0)
                        }
                      >
                        <div className=" flex gap-1 sm:gap-2 justify-start items-center">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-4 h-4"
                            >
                              <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                            </svg>
                          </span>
                          <p className="hidden md:block">
                            {auction_info?.auction?.status === "live"
                              ? t(
                                  "auction_details_page.auction_status.highest_bid"
                                )
                              : auction_info?.auction?.status === "sold"
                                ? t("auction_details_page.auction_status.sold")
                                : t("auction_details_page.auction_status.bid")}
                          </p>
                          <span className="text-white">
                            $
                            {auction_info?.current_bid?.price
                              ? auction_info?.current_bid?.price
                              : 0}
                          </span>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={
                          t("auction_details_page.bids_text") +
                          " " +
                          auction_info?.stats?.bids
                        }
                      >
                        <div className="gap-0.5 sm:gap-1 md:gap-2 justify-start items-center flex">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-4 h-4"
                            >
                              <path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z" />
                            </svg>
                          </span>
                          <p className="hidden md:block">
                            {t("auction_details_page.bids_text")}
                          </p>
                          <span className="text-white">
                            {auction_info?.stats?.bids}
                          </span>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={
                          t("auction_details_page.comment_text") +
                          " " +
                          auction_info?.stats?.comments
                        }
                      >
                        <div className="flex gap-1 sm:gap-2 justify-start items-center">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-4 h-4"
                            >
                              <path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z" />
                            </svg>
                          </span>
                          <p className="hidden md:block">
                            {t("auction_details_page.comment_text")}
                          </p>
                          <span className="text-white">
                            {auction_info?.stats?.comments}
                          </span>
                        </div>
                      </Tooltip>
                    </div>
                    {!auctionEnded && (
                      <div className="lg:w-2/12">
                        <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                          <Button
                            variant="contained"
                            className="h-full "
                            sx={{
                              textTransform: "capitalize",
                              borderRadius: screen?.width > 640 ? "4px" : 0,
                            }}
                            fullWidth
                            onClick={() =>
                              isUserLogedIn
                                ? setShowCreditCard(true)
                                : setOpenLogin(true)
                            }
                            disabled={
                              auction_info?.seller?.email === user?.email
                            }
                          >
                            <span className="hidden sm:block py-[0.425rem]">
                              {t("auction_details_page.place_bid_text")}
                            </span>
                            <span className="block sm:hidden sm:py-[0.425rem] ">
                              {t("auction_details_page.bid_text")}
                            </span>
                          </Button>
                        </ThemeProvider>
                      </div>
                    )}
                  </div>
                </div>
                {/* small screen title */}
                <div
                  className={`w-full ${
                    auctionEnded ? "" : "hidden"
                  } py-2 pt-5 px-2 sm:px-1`}
                >
                  <Link
                    to={
                      "/search/" +
                      auction_info?.make +
                      " " +
                      auction_info?.model
                    }
                  >
                    <Button
                      color="success"
                      fullWidth
                      sx={{ textTransform: "none", padding: 0 }}
                    >
                      <Alert
                        severity="success"
                        icon={
                          <SearchIcon
                            sx={{
                              color: "var(--dark-text-color)",
                              fontSize: "1.5rem",
                              margin: "auto",
                            }}
                          />
                        }
                        className="border border-[var(--success-color)] w-full "
                      >
                        <p className="p0">
                          {t("auction_details_page.auction_ended_text")}{" "}
                          {auction_info?.make + " " + auction_info?.model}{" "}
                          {t("auction_details_page.auction_ended_text2")}{" "}
                          <Link
                            to={
                              "/search/" +
                              auction_info?.make +
                              " " +
                              auction_info?.model
                            }
                            className="font-bolder underline"
                          >
                            {t("auction_details_page.auction_ended_text3")}
                          </Link>
                          .
                        </p>
                      </Alert>
                    </Button>
                  </Link>
                </div>
                <div className="w-full hidden sm:flex justify-between items-center gap-2">
                  <div className="flex justify-between items-center content-center w-full h-full pt-3">
                    <div className="flex">
                      <div className="w-full border p-2 rounded-lg shadow	flex justify-start items-center gap-1">
                        <div className="w-[1.6rem]">
                          <img
                            src={Icons.CarCheckList}
                            alt="car fax"
                            className="w-full "
                          />
                        </div>
                        <div className="flex flex-col justify-center items-start gap-0 leading-4 font-semibold text-justify">
                          <p className="text-[1.2rem]">Show Car</p>
                          <p className="p2">Check Report</p>
                        </div>
                      </div>
                    </div>
                    <p className="textGray p1 font-light">
                      {auctionEnded
                        ? t("auction_details_page.ended_text")
                        : t("auction_details_page.ending_text")}{" "}
                      {auction_info?.stats?.auction_end &&
                        moment(auction_info.stats.auction_end).format(
                          "MMMM DD"
                        ) +
                          "th " +
                          t("auction_details_page.at_text") +
                          " " +
                          moment(auction_info.stats.auction_end).format(
                            "HH:MM A"
                          )}
                    </p>
                  </div>
                </div>
                {/*  facts info */}
                <PageComponents.FactsInfo data={auction_info} />
                {/*  Author Quote */}
                {auction_info?.review && (
                  <PageComponents.AuthorQuote data={auction_info} />
                )}
                {/* Highlights */}
                {auction_info?.highlights && (
                  <PageComponents.AuctionDetail
                    heading={t("auction_details_page.highlight_title")}
                    html={auction_info?.highlights}
                  />
                )}
                {/* Equipment */}
                {auction_info?.equipment && (
                  <PageComponents.AuctionDetail
                    heading={t("auction_details_page.equipment_title")}
                    html={auction_info?.equipment}
                  />
                )}
                {/* Modification */}
                {auction_info?.modifications && (
                  <PageComponents.AuctionDetail
                    heading={t("auction_details_page.modification_title")}
                    html={auction_info?.modifications}
                  />
                )}
                {/* Notewortyhy_features */}
                {auction_info.noteworthy_features && (
                  <PageComponents.AuctionDetail
                    heading={t("noteworthy_features")}
                    html={auction_info.noteworthy_features}
                  />
                )}
                {/* Known Flaws */}
                {auction_info?.flaws && (
                  <PageComponents.AuctionDetail
                    heading={t("auction_details_page.known_flaws_title")}
                    html={auction_info?.flaws}
                  />
                )}
                {/* Recent Service History */}
                {auction_info?.service_history && (
                  <PageComponents.AuctionDetail
                    heading={t(
                      "auction_details_page.recent_service_history_title"
                    )}
                    html={auction_info?.service_history}
                  />
                )}
                {/* Other Items Included in Sale */}
                {auction_info?.other_items && (
                  <PageComponents.AuctionDetail
                    heading={t("auction_details_page.oter_items_title")}
                    html={auction_info?.other_items}
                  />
                )}
                {/* Ownership History */}
                {auction_info?.ownership_history && (
                  <PageComponents.AuctionDetail
                    heading={t("auction_details_page.ownership_history_title")}
                    html={auction_info?.ownership_history}
                  />
                )}
                {/* Seller Notes */}
                {auction_info?.seller_notes && (
                  <PageComponents.AuctionDetail
                    heading={t("auction_details_page.seller_notes_title")}
                    html={auction_info?.seller_notes}
                  />
                )}
                {/* Video  */}
                {auction_info?.videos?.length > 0 && (
                  <PageComponents.CarVideos videoUrls={auction_info?.videos} />
                )}
                {/*  Seller Q&A */}
                <PageComponents.SellerQA
                  auctionEnded={auctionEnded}
                  data={{ profile: user, auction: auction_info }}
                  getProfile={getProfile}
                />
                {/*  show Quote for live auction */}
                {!auctionEnded && <PageComponents.Quote />}
                {/*  Place Bid */}
                <PageComponents.BiddingInfo
                  data={{ ...auction_info, user }}
                  addToWatchList={addToWatchList}
                  deleteFromWatchList={deleteFromWatchList}
                  loading={loading}
                  setShowCreditCard={setShowCreditCard}
                  auctionEnded={auctionEnded}
                />
                {/*  Comment Section */}
                <div className="py-4">
                  <div className="flex justify-between items-center">
                    <h3 className="head font-bold">
                      {t("auction_details_page.comment_section.title")}
                    </h3>
                    <div className="">
                      <ul className=" justify-end items-center gap-3 p1 hidden md:flex">
                        <li
                          className={`${
                            filter === "newest" ? "opacity-100" : "opacity-80"
                          } cursor-pointer`}
                          onClick={() => setFilter("newest")}
                        >
                          {t(
                            "auction_details_page.comment_section.sort_options.newest"
                          )}
                        </li>
                        <li
                          className={`${
                            filter === "most_upvoted"
                              ? "opacity-100"
                              : "opacity-80"
                          } cursor-pointer`}
                          onClick={() => setFilter("most_upvoted")}
                        >
                          {t(
                            "auction_details_page.comment_section.sort_options.most_upvoted"
                          )}
                        </li>
                        <li
                          className={`${
                            filter === "seller_comments"
                              ? "opacity-100"
                              : "opacity-80"
                          } cursor-pointer`}
                          onClick={() => setFilter("seller_comments")}
                        >
                          {t(
                            "auction_details_page.comment_section.sort_options.seller_comments"
                          )}
                        </li>
                        <li
                          className={`${
                            filter === "bid_history"
                              ? "opacity-100"
                              : "opacity-80"
                          } cursor-pointer`}
                          onClick={() => setFilter("bid_history")}
                        >
                          {t(
                            "auction_details_page.comment_section.sort_options.bid_history"
                          )}
                        </li>
                      </ul>
                      <button
                        className="flex justify-end items-center gap-1 font-semibold text-sm md:hidden right-2"
                        id="filterComments"
                      >
                        {t(
                          "auction_details_page.comment_section.sort_options." +
                            (filter ? filter : "newest")
                        )}
                        <p
                          className={`my-auto ${
                            showCommentMenu ? "rotate-270" : "rotate-180"
                          }`}
                        >
                          <KeyboardArrowUpIcon />
                        </p>
                      </button>
                      <ul
                        className={` flex-col absolute px-4 py-6 right-4 shadow-2xl md:hidden border drop-shadow-sm	bg-white rounded z-[999] gap-1 w-44 sm:w-48 p1 ${
                          showCommentMenu ? "flex" : " hidden"
                        }`}
                      >
                        <li
                          className={`${
                            filter === "newest" ? "opacity-100" : "opacity-80"
                          } flex justify-between items-center gap-2`}
                          onClick={() => handleFilter("newest")}
                        >
                          {t(
                            "auction_details_page.comment_section.sort_options.newest"
                          )}
                          {filter === "newest" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-3.5 block"
                            >
                              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                          )}
                        </li>
                        <li
                          className={`${
                            filter === "most_upvoted"
                              ? "opacity-100"
                              : "opacity-80"
                          } flex justify-between items-center gap-2`}
                          onClick={() => handleFilter("most_upvoted")}
                        >
                          {t(
                            "auction_details_page.comment_section.sort_options.most_upvoted"
                          )}
                          {filter === "most_upvoted" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-3.5 block"
                            >
                              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                          )}
                        </li>
                        <li
                          className={`${
                            filter === "seller_comments"
                              ? "opacity-100"
                              : "opacity-80"
                          } flex justify-between items-center gap-2`}
                          onClick={() => handleFilter("seller_comments")}
                        >
                          {t(
                            "auction_details_page.comment_section.sort_options.seller_comments"
                          )}
                          {filter === "seller_comments" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-3.5 block"
                            >
                              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                          )}
                        </li>
                        <li
                          className={`${
                            filter === "bid_history"
                              ? "opacity-100"
                              : "opacity-80"
                          } flex justify-between items-center gap-2`}
                          onClick={() => handleFilter("bid_history")}
                        >
                          {t(
                            "auction_details_page.comment_section.sort_options.bid_history"
                          )}
                          {filter === "bid_history" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                              stroke="currentColor"
                              strokeWidth="2"
                              fill="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="w-3.5 block"
                            >
                              <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Comment box */}
                  <div className="" id="auctionComment">
                    <div className="w-full border flex justify-between items-center rounded px-3 py-2.5 focus-within:border-[--main-color] shadow-sm focus-within:border-2 p1">
                      {commentResponse &&
                        Object.keys(commentResponse)?.length > 0 &&
                        commentResponse?.user && (
                          <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                            <Button
                              className="self-start shadow"
                              variant="outlined"
                              sx={{
                                paddingY: "0.1rem",
                                paddingX: "1rem",
                                borderColor: "#e5e7eb",
                                minWidth: "fit-content",
                              }}
                              disabled
                            >
                              <p className="font-semibold normal-case py-[0.1rem] px-1 textColor p1">
                                Re: {commentResponse?.user?.username}
                              </p>
                            </Button>
                          </ThemeProvider>
                        )}
                      <div className="InputFieldSetting">
                        <textarea
                          className="border-none focus:border-none focus-visible:border-none active:border-none focus:outline-none focus-visible:outline-none active:outline-none hover:outline-none hover:border-none w-full textAreaResizeStyling px-1.5 p1"
                          onInput={(e) => auto_grow(e?.target)}
                          id="dynamicTextArea"
                          value={comment}
                          onChange={(e) => {
                            setComment(e?.target?.value);
                            let error = "";
                            ["lorem", "ipsum", "sex", "some bad word!"]?.map(
                              (key: string) => {
                                e?.target?.value
                                  ?.toLowerCase()
                                  ?.includes(key) &&
                                  (error =
                                    "This contains inapproprate content");
                              }
                            );
                            setCommentErr(error);
                          }}
                          maxLength={1200}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <IconButton
                        color="primary"
                        aria-label="add to comment cart"
                        className="bgColor iconbutnStyleOnDisable shadow-lg self-end	text-white"
                        size="small"
                        onClick={() =>
                          isUserLogedIn
                            ? handlePostComment()
                            : setOpenLogin(true)
                        }
                        disabled={
                          comment?.length < 1 ||
                          (commentErr ? true : false) ||
                          loadComment ||
                          loading
                        }
                      >
                        <ArrowDownwardRoundedIcon
                          className="text-black"
                          fontSize="inherit"
                        />
                      </IconButton>
                    </div>
                    <WarningAlert message={commentErr} />
                  </div>
                  {/*  display all comments  */}
                  <div className="py-5">
                    <PageComponents.AuctionComments
                      data={{ comments, auction_info }}
                      getComments={getComments}
                      setCommentResponse={setCommentResponse}
                      setLoadComments={setLoadOldComments}
                      total={total}
                      setOffset={setOffset}
                      limit={limit}
                    />
                  </div>
                </div>
              </div>
              {/* right side bar */}
              <div className="hidden xl:block w-4/12 pl-2 space-y-12">
                {auctionEndingSoon?.length > 0 && (
                  <div className="">
                    <h3 className="head font-bold pb-4 pt-1">
                      {t(
                        "auction_details_page.comment_section.headings.auction_ending_soon"
                      )}
                    </h3>
                    <Grid container spacing={2}>
                      {auctionEndingSoon?.map((item, index) => (
                        <Grid item className="px-3-" xs={12} key={index}>
                          <AuctionCardComponent
                            item={item}
                            showDetailsInProp={false}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}

                {newlyListings?.length > 0 && (
                  <div className="">
                    <h3 className="head font-bold pb-4">
                      {t(
                        "auction_details_page.comment_section.headings.newly_listed"
                      )}
                    </h3>
                    <Grid container spacing={2}>
                      {newlyListings?.map((item, index) => (
                        <Grid item className="px-3-" xs={12} key={index}>
                          <AuctionCardComponent
                            item={item}
                            showDetailsInProp={false}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}

                {auctionEndingSoon?.length === 0 &&
                  newlyListings?.length === 0 &&
                  pastAuctions?.length > 0 && (
                    <>
                      <div className="">
                        <h3 className="head font-bold  pb-4">
                          {t(
                            "auction_details_page.comment_section.headings.past_auction"
                          )}
                        </h3>
                        <Grid container spacing={2}>
                          {pastAuctions?.map((item, index) => (
                            <Grid item className="px-3-" xs={12} key={index}>
                              <AuctionCardComponent
                                item={item}
                                showDetailsInProp={false}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
          {/* display list */}
        </div>
      ) : (
        <p className="info_message">
          {t("auction_details_page.comment_section.headings.no_auction_found")}{" "}
        </p>
      )}
      {/*  show all cars images list */}
      {!loading && auction_info?.photos && (
        <Suspense>
          {showCars && (
            <CarShowCase
              showCars={showCars}
              setShowCars={setShowCars}
              data={auction_info.photos}
              sliderIndex={sliderIndex}
              setSliderIndex={setSliderIndex}
            />
          )}
        </Suspense>
      )}

      {/*  credit card modal info */}
      <Suspense>
        {showCreditCard && (
          <AddCreditCard
            showCreditCard={showCreditCard}
            setShowCreditCard={setShowCreditCard}
            auction_info={auction_info}
            getListOfAuction={getListOfAuction}
            getComments={getComments}
          />
        )}
      </Suspense>

      {/* share modal */}
      {isModalOpen && (
        <ShareModal
          setIsModalOpen={setIsModalOpen}
          auctionInfo={auction_info}
        />
      )}

      {/* sign in */}
      {openLogin && (
        <AuthPages.Login openLogin={openLogin} setOpenLogin={setOpenLogin} />
      )}
    </>
  );
}
