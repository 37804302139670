import { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services";
import PageComponents from "../components";
import { PreLoaderComponent } from "../../components";
import { useTranslation } from "react-i18next";

const AuthorQuote = lazy(() => import("../components/AuthorQuote.component"));

// checked
const Review = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState<any>({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAuctionInfo = () => {
    params.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res) => {
        if (res.status) {
          const data = res.data;
          setListing(data);
          if (
            data.application_status !== "approved" ||
            !data.title ||
            !data.description ||
            !data.location ||
            !data.engine ||
            !data.drive_train ||
            !data.body_style ||
            !data.exterior_color ||
            !data.interior_color ||
            !data.service_history ||
            data.photos?.exterior?.length === 0 ||
            data.photos?.interior?.length === 0 ||
            data.photos?.mechanical?.length === 0 ||
            data.photos?.docs?.length === 0 ||
            !data.schedule_time_slot
          ) {
            return navigate(`/application/${params?.list_id}`);
          }
        }
      });
  };

  useEffect(() => {
    getAuctionInfo();
  }, [params]);

  return (
    <>
      <PageComponents.MetaComponent title={t("user_review_page.title")} />
      <div className="">
        <div className="flex flex-col">
          <div className="flex justify-between items-center pb-4">
            <h1 className="p1 font-normal">
              <span
                className="text-[var(--main-color)] hover:text-[var(--dark-color)] cursor-pointer"
                onClick={() => navigate("/listings")}
              >
                {t("user_review_page.dbhj5iewg")}
              </span>{" "}
              {listing?.year ? "—" : ""} {listing?.year} {listing?.make}{" "}
              {listing?.model} {listing?.title ? listing?.title : ""}
            </h1>
          </div>
          <Suspense fallback={<PreLoaderComponent />}>
            {listing && Object.keys(listing).length > 0 && listing.review ? (
              <AuthorQuote data={listing} />
            ) : (
              <h2 className="head font-bold pt-4">{t("user_review_page.lglw62tk6")}</h2>
            )}
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default Review;
