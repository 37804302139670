import { ApiConfig } from "../config";

const getSettings = () => {
  return ApiConfig.get("/api/user/settings", {
  }).then((res: any) => {
    return res.data;
  });
};

const saveSettings = (data: any) => {
  return ApiConfig.post("/api/user/settings", data).then((res: any) => {
    return res.data;
  });
};

const Settings = {
  getSettings,
  saveSettings,
};

export default Settings;
