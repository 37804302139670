import { useEffect, useState, useRef, FC } from "react";
import Hooks from "../../hooks";
import { useTranslation } from "react-i18next";
import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  ThemeProvider,
  ButtonGroup,
} from "@mui/material";

type GalleryProps = {
  imageArray: string[];
  setShowCars: any;
  sliderIndex?: number | null;
  setSliderIndex?: any;
};

// checked
const ImagesGallery: FC<GalleryProps> = ({
  imageArray,
  setShowCars,
  sliderIndex,
  setSliderIndex,
}): JSX.Element => {
  const screen = Hooks.useScreen();
  const { t } = useTranslation();
  const [galleryHeight, setGalleryHeight] = useState<string>("auto");
  const mainImageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const newImageHeight =
        (document.getElementById("main-image-in-gallery") as HTMLImageElement)
          ?.height || "auto";
      setGalleryHeight(`${newImageHeight}px`); // Update image Height when resize
    };

    window.addEventListener("resize", handleResize);

    handleResize();
    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (imageArray?.length > 0) {
      const newImageHeight =
        (document.getElementById("main-image-in-gallery") as HTMLImageElement)
          ?.height || "auto";
      setGalleryHeight(`${newImageHeight}px`); // Update image Height when update data
    }
  }, [imageArray]);

  const ShowCars = (index: number | null) => {
    setShowCars(true);
    setSliderIndex(index);
  };

  return (
    <>
      {/* below edit by talha */}
      {imageArray?.length > 0 && (
        <div
          className={`flex flex-col lg:flex-row  justify-between items-start gap-1 pt-0 lg:pt-6 2xl:pt-8 sm:pt-0 w-full`}
        >
          <div
            className="lg:w-9/12 xl:w-8/12 w-full cursor-pointer"
            onClick={() => ShowCars(1)}
          >
            <img
              ref={mainImageRef}
              src={`${process.env.REACT_APP_HOST_API_KEY}/${imageArray[0]}`}
              className="w-full rounded-t lg:rounded-tr-none lg:rounded-l shadow-sm hover:shadow-md h-auto object-cover "
              loading="lazy"
              id="main-image-in-gallery"
            />
          </div>
          <div
            className={`lg:w-3/12 xl:w-4/12 w-full showcase-images h-full lg:ps-1 overflow-hidden`}
            style={{ maxHeight: galleryHeight }}
          >
            <div className="">
              <ImageList
                sx={{
                  boxShadow: "none",
                  borderRadius: "0",
                  ".MuiImageListItem-root .MuiImageListItem-img": {},
                }}
                cols={
                  screen.width > 1279
                    ? 2
                    : screen.width > 1023
                      ? 1
                      : screen.width > 640
                        ? 3
                        : 2
                }
              >
                {imageArray?.length > 0 ? (
                  imageArray?.map((imageSrc: string, index: number) => {
                    let last_image_index: number =
                      screen.width > 1279 ? 8 : screen.width > 640 ? 3 : 2;

                    let rounded_image_class =
                      (last_image_index === 8 && index === 2) ||
                      (last_image_index === 3 && index === 1)
                        ? "rounded-bl lg:rounded-tr"
                        : last_image_index === 2 && index === 1
                          ? "rounded-bl"
                          : index === last_image_index
                            ? "rounded-br"
                            : "";

                    return (
                      index > 0 &&
                      index <= last_image_index && (
                        <ImageListItem
                          key={index}
                          onClick={() =>
                            ShowCars(index === last_image_index ? null : index)
                          }
                        >
                          <img
                            src={`${process.env.REACT_APP_HOST_API_KEY}/${imageSrc}`}
                            srcSet={`${process.env.REACT_APP_HOST_API_KEY}/${imageSrc}`}
                            loading="lazy"
                            className={
                              `w-full object-cover cursor-pointer ` +
                              rounded_image_class
                            }
                            id={`${index}`}
                          />
                          {index === last_image_index && (
                            <div
                              className={`w-full h-full absolute top-0 left-0 showMore-text cursor-pointer rounded-br`}
                            >
                              <div
                                className="w-full h-full bg-[rgba(0,0,0,0.5)] flex justify-center items-center rounded-br "
                                onClick={() => setShowCars(true)}
                              >
                                <p className="p00 text-white decoration-0 font-bold text-center cursor-pointer">
                                  {imageArray?.length - 1}+{" "}
                                  {t("auction_details_page.show_more_text")}
                                </p>
                              </div>
                            </div>
                          )}
                        </ImageListItem>
                      )
                    );
                  })
                ) : (
                  <p className="info_message flex justify-center items-center">
                    {t("media_page.mim5ozzre")}
                  </p>
                )}
              </ImageList>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImagesGallery;
