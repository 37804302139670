import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import PageComponents from "../pages/components";
import { Box, Button, Drawer, ThemeProvider } from "@mui/material";
import Utils from "../utils";
import { Sort, Tune } from "@mui/icons-material";

const AuctionNavComponent = memo(function AuctionNavComponent({
  setSort,
  sort,
  setStartYear,
  setEndYear,
  setTransmission,
  setBodyStyle,
  startYear,
  endYear,
  listingsType,
}: any) {
  const { t } = useTranslation();
  const auctionSort = [
    {
      label: t("sort_options.ending_soon"),
      value: "ending_soon",
    },
    {
      label: t("sort_options.newly_listed"),
      value: "newly_listed",
    },
    {
      label: t("sort_options.no_reserve"),
      value: "no_reserve",
    },
    {
      label: t("sort_options.low_mile"),
      value: "lowest_mileage",
    },
  ];
  const resultSort = [
    {
      label: t("sort_options.recent_ended"),
      value: "recently_ended",
    },
    {
      label: t("sort_options.low_mile"),
      value: "lowest_mileage",
    },
    {
      label: t("sort_options.high_mile"),
      value: "highest_mileage",
    },
    {
      label: t("sort_options.low_price"),
      value: "lowest_price",
    },
    {
      label: t("sort_options.high_price"),
      value: "higest_price",
    },
  ];
  const [sortingOptions, setSortingOptions] = useState(auctionSort);
  const [filters, setFilters] = useState({
    year: {
      open: false,
      value: [0, 0],
    },
    transmation: {
      open: false,
      value: "",
    },
    bodyStyle: {
      open: false,
      value: "",
    },
  });
  const [openSort, setOpenSort] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenSort(newOpen);
  };

  useEffect(() => {
    listingsType &&
      setSortingOptions(listingsType === "auctions" ? auctionSort : resultSort);
  }, [listingsType]);

  const applyFilters = () => {
    if (filters.year.open) {
      setStartYear(filters.year.value[0]);
      setEndYear(filters.year.value[1]);
    } else {
      setStartYear(null);
      setEndYear(null);
    }
    if (filters.transmation.open) {
      setTransmission(filters.transmation.value);
    } else {
      setTransmission(null);
    }
    if (filters.bodyStyle.open) {
      setBodyStyle(filters.bodyStyle.value);
    } else {
      setBodyStyle(null);
    }
  };

  const SortOptions = () => {
    return (
      <nav className="sortStyle  ">
        {sortingOptions?.length > 0 &&
          sortingOptions?.map((item, index) => (
            <button
              className={`main-outline-button-rounded ${
                sort === item?.value
                  ? "bg-[var(--main-color)] text-white"
                  : "text-black"
              }`}
              onClick={() => setSort(item?.value)}
              key={index}
            >
              {item?.label}
            </button>
          ))}
      </nav>
    );
  };

  return (
    <section className="containerSpacing flex justify-between flex-wrap gap-2 mt-16">
      <div>
        <div className={` md:hidden `}>
          <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
            <Button
              variant="contained"
              onClick={toggleDrawer(true)}
              sx={{ textTransform: "capitalize" }}
            >
              <Sort className="me-2" /> {t("sort_butn_text")}
            </Button>
          </ThemeProvider>
        </div>
        <div className="hidden md:flex">
          <SortOptions />
        </div>
        <Drawer
          open={openSort}
          onClose={toggleDrawer(false)}
          className="md:hidden"
        >
          <Box
            sx={{
              width: 280,
            }}
            role="presentation"
          >
            <div className="flex flex-col gap-4 py-12 px-6">
              <div className="flex gap-1 justify-start items-baseline ">
                <Sort /> <h4 className="head">{t("sort_title")}</h4>
              </div>
              <div className="pt-5">
                <SortOptions />
              </div>
            </div>
          </Box>
        </Drawer>
      </div>
      <div className="flex-1 flex justify-end">
        <PageComponents.FiltersComponent
          filters={filters}
          setFilters={setFilters}
          applyFilters={applyFilters}
        />
      </div>
    </section>
  );
});

export default AuctionNavComponent;
