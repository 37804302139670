import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AuthPages } from "../index";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { AppImages } from "../../assets";

// checked
function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (location?.search != "") {
      setOpenModal(true);
    } else {
      Swal.fire({
        icon: "error",
        title: t(
          "reset_password_modal.sweat_alert.verified_token_success_message"
        ),
      });
      return navigate("/");
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageComponents.MetaComponent title={t("reset_password_modal.title")} />
      <div className=" pt-12 sm:pt-20 md:pt-24 xl:pt-32">
        <div className="container mx-auto flex  justify-center items-center p-10 pb-0 sm:pb-10">
          <div className="w-full sm:w-5/6 md:w-4/6 lg:w-3/6 xl:w-2/6 px-6   sm:py-12 pt-12 pb-0 border borderColor flex flex-col justify-center items-center shadow-lg drop-shadow-sm rounded">
            <Link
              to="/"
              className="flex title-font font-medium items-center text-gray-900  md:mb-0 "
            >
              <img
                src={AppImages.LogoImage}
                className="md:h-12 h-10 "
                alt="AutoBids Logo"
              />
              <h1 className="ml-3 head capitalize">
                {t("site_title")}
              </h1>
            </Link>
            <div className="">
              <p className="p0 text-semibold text-center pt-2">
                {t("reset_password_modal.description")}
              </p>
              <h1 className="text-center head text-bold py-4">
                {t("reset_password_modal.heading")}...
              </h1>
            </div>
          </div>
        </div>
      </div>
      <AuthPages.ForgotPassword
        open={openModal}
        setOpen={setOpenModal}
        forgotFormType="reset_password"
      />
    </>
  );
}

export default ResetPassword;
