export default function LeftArrowSvg() {
  return (
    <div className="mt-20 mb-20 -rotate-90 lg:rotate-0 lg:mt-0 lg:mb-0 text-xs items-center mx-auto font-bold textColor">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 235.17 61.73"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="w-32 h-10"
      >
        <rect
          x="216.38"
          y="18.25"
          width="3"
          height="4.92"
          transform="translate(195.69 238.44) rotate(-89.61)"
        />
        <rect
          x="227.21"
          y="18.32"
          width="3"
          height="4.92"
          transform="translate(206.36 249.34) rotate(-89.61)"
        />
        <rect
          x="183.87"
          y="18.03"
          width="3"
          height="4.92"
          transform="translate(163.62 205.71) rotate(-89.61)"
        />
        <rect
          x="194.69"
          y="18.1"
          width="3"
          height="4.92"
          transform="translate(174.3 216.61) rotate(-89.61)"
        />
        <rect
          x="205.49"
          y="18.17"
          width="3"
          height="4.92"
          transform="translate(184.95 227.48) rotate(-89.61)"
        />
        <rect
          x="161.35"
          y="17.87"
          width="3"
          height="4.92"
          transform="translate(141.41 183.04) rotate(-89.61)"
        />
        <rect
          x="172.98"
          y="17.95"
          width="3"
          height="4.92"
          transform="translate(152.88 194.75) rotate(-89.61)"
        />
        <rect
          x="128.84"
          y="17.65"
          width="3"
          height="4.92"
          transform="translate(109.34 150.31) rotate(-89.61)"
        />
        <rect
          x="139.67"
          y="17.72"
          width="3"
          height="4.92"
          transform="translate(120.02 161.21) rotate(-89.61)"
        />
        <rect
          x="150.46"
          y="17.8"
          width="3"
          height="4.92"
          transform="translate(130.67 172.08) rotate(-89.61)"
        />
        <rect
          x="96.33"
          y="17.43"
          width="3"
          height="4.92"
          transform="translate(77.27 117.58) rotate(-89.61)"
        />
        <rect
          x="107.15"
          y="17.5"
          width="3"
          height="4.92"
          transform="translate(87.95 128.48) rotate(-89.61)"
        />
        <rect
          x="117.95"
          y="17.57"
          width="3"
          height="4.92"
          transform="translate(98.6 139.35) rotate(-89.61)"
        />
        <rect
          x="73.81"
          y="17.27"
          width="3"
          height="4.92"
          transform="translate(55.06 94.91) rotate(-89.61)"
        />
        <rect
          x="84.64"
          y="17.35"
          width="3"
          height="4.92"
          transform="translate(65.74 105.81) rotate(-89.61)"
        />
        <rect
          x="62.92"
          y="17.2"
          width="3"
          height="4.92"
          transform="translate(44.32 83.95) rotate(-89.61)"
        />
        <rect
          x="30.79"
          y="16.98"
          width="3"
          height="4.92"
          transform="translate(12.62 51.59) rotate(-89.61)"
        />
        <rect
          x="41.61"
          y="17.05"
          width="3"
          height="4.92"
          transform="translate(23.3 62.49) rotate(-89.61)"
        />
        <rect
          x="52.41"
          y="17.13"
          width="3"
          height="4.92"
          transform="translate(33.95 73.36) rotate(-89.61)"
        />
        <rect
          x="19.9"
          y="16.91"
          width="3"
          height="4.92"
          transform="translate(1.88 40.63) rotate(-89.61)"
        />
        <path
          style={{ strokeMiterlimit: 10, strokeWidth: "5px" }}
          d="M18.71,38.15c.59,.59,1.54,.59,2.12,0,.59-.59,.59-1.54,0-2.12L4.39,19.58,20.84,2.67c.58-.59,.56-1.54-.03-2.12-.29-.28-.67-.43-1.05-.43-.39,0-.78,.15-1.08,.45L.18,19.61l18.54,18.53Z"
        />
      </svg>
    </div>
  );
}
