import { ApiConfig } from "../config";

const GetStats = () => {
  return ApiConfig.get("api/user/system/stats").then((res: any) => {
    return res.data;
  });
};

const GetReviews = (offset: number, limit: number) => {
  return ApiConfig.get(
    `/api/user/system/rating-reviews?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  });
};

const System = {
  GetStats,
  GetReviews,
};

export default System;
