import { ApiConfig } from "../config";

const PostComment = (id: number, data: any) => {
  return ApiConfig.post(`/api/user/listings/${id}/comments`, data).then(
    (res: any) => {
      return res.data;
    }
  );
};

const GetComments = (
  id: number,
  offset: number,
  limit: number,
  filter: string | null
) => {
  let url = `/api/user/listings/${id}/comments?offset=${offset}&limit=${limit}`;
  if (filter != null) {
    url = url + `&filter=${filter}`;
  }
  return ApiConfig.get(url).then((res: any) => {
    return res.data;
  });
};

const UpvoteComment = (id: number, comment_id: number, data: any) => {
  return ApiConfig.post(
    `/api/user/listings/${id}/comments/${comment_id}/upvote`,
    data
  ).then((res: any) => {
    return res.data;
  });
};

const FlgComment = (id: number, comment_id: number, data: any) => {
  return ApiConfig.post(
    `/api/user/listings/${id}/comments/${comment_id}/flag`,
    data
  ).then((res: any) => {
    return res.data;
  });
};

const Comment = {
  PostComment,
  GetComments,
  UpvoteComment,
  FlgComment
};

export default Comment;
