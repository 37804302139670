import React, { useState, useEffect } from "react";
import { Avatar, Pagination } from "@mui/material";
import moment from "moment";
import Services from "../../services";
import { useParams } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import { useTranslation } from "react-i18next";
import Theme from "../../utils/Theme.utils";

// checked
const ApplicationComments = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(5);
  const [comments, setComments] = useState<any[]>([]);

  const getComments = () => {
    if (params.list_id) {
      setLoadingdata(true);
      let apiUrl;
      if (Services.Storage.getCurrentRole() !== "user") {
        apiUrl = Services.Listing.GetCommentsOfListingForAdmin(
          params.list_id,
          limit,
          offset
        );
      } else {
        apiUrl = Services.Listing.GetCommentsOfUserListings(
          params.list_id,
          limit,
          offset
        );
      }

      apiUrl
        .then((res) => {
          setLoadingdata(false);
          if (res.status) {
            setComments(res.data);
            setTotal(Math.ceil(res.total / limit));
          }
        })
        .catch((error) => {
          setLoadingdata(false);
        });
    }
  };

  useEffect(() => {
    getComments();
  }, [limit, offset, page]);

  return (
    <>
      <div className="flex flex-col gap-2 sm:gap-3 py-2">
        {loadingdata && <PreLoaderComponent />}
        {!loadingdata &&
          (comments?.length > 0 ? (
            comments?.map((comment: any, index: number) => (
              <div
                className=" border rounded shadow px-2 sm:px-4 py-2"
                key={index}
              >
                <div className="flex justify-start items-center flex-wrap">
                  <Avatar
                    sx={{ width: 24, height: 24 }}
                    alt={comment.user?.username}
                    src={comment.user?.avatar}
                  />
                  <p className="font-semibold px-2 p0">
                    {comment.user.username}
                  </p>
                  <div className=" flex justify-start items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-2 m-auto"
                    >
                      <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                    </svg>
                    <p className="p-0 m-0 ps-0.5 p1">{comment.upvotes}</p>
                    <p className="px-1 p1 opacity-40 block">
                      {moment(comment.created_at).fromNow()}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-2 ">
                  <div className="flex flex-col gap-2 py-2 justify-center items-start p1">
                    <p className=" ">{comment.text}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="pt-4 p0 text-center w-full">
              {t("user_application_page.2pwiaasa5")}
            </p>
          ))}
      </div>
      <div className="flex justify-end items-center py-3">
        {!loadingdata && total > 0 && (
          <Pagination
            count={total}
            hidePrevButton
            hideNextButton
            page={page}
            onChange={(e: any, pageNum) => {
              setPage(pageNum);
              setOffset((pageNum - 1) * limit);
            }}
            shape="rounded"
            sx={Theme.MuiPagenationStyle}
          />
        )}
      </div>
    </>
  );
};

export default ApplicationComments;
