import { useEffect, useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import { Link, useParams } from "react-router-dom";
import { ToggleButton } from "../../components";
import { useTranslation } from "react-i18next";

// checked
const SavedSearch = memo(({ open, setOpen }: any) => {
  const params = useParams();
  const { t } = useTranslation();
  const isUserLogedIn = Services.Auth.IsUserLogedIn();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notifyMe, setNotifyMe] = useState<boolean>(true);
  const [notify_id, setNotifyId] = useState<number>(0);

  const handleSubmit = (e: any) => {
    if (isUserLogedIn) {
      e?.preventDefault();
      const data = {
        name: "when_new_auctions_go_live",
        value: notifyMe,
      };
      setIsLoading(true);
      Services.Settings.saveSettings(data)
        .then((res) => {
          setIsLoading(false);
          setOpen(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      document.getElementById("signin")?.click();
    }
  };

  const getSettings = () => {
    Services.Settings.getSettings().then((res) => {
      res?.status &&
        res?.data?.length > 0 &&
        res?.data?.map((item: any) => {
          if (item?.name === "when_new_auctions_go_live") {
            setNotifyMe(item.value);
            setNotifyId(item.id);
          }
        });
    });
  };

  useEffect(() => {
    isUserLogedIn && getSettings();
  }, []);

  return (
    <>
      <div className="overflow-y-auto">
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          hideBackdrop={true}
          onClose={() => {
            setOpen(false);
          }}
          sx={Utils.Theme.JoyModalStyle}
        >
          <Sheet
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
            sx={Utils.Theme.JoyModalSheetStyle}
          >
            <ModalClose
              variant="outlined"
              sx={Utils.Theme.JoyModalCloseStyle}
            />
            <div className="flex justify-center align-items-center w-100">
              <h4 className="head capitalize ">
                <b>{t("save_search_modal.heading")}</b>
              </h4>
            </div>
            <hr />
            <form
              onSubmit={(e) => handleSubmit(e)}
              noValidate
              autoComplete="off"
              className="modalForm"
            >
              <div className="pt-8 w-100">
                <div className="flex justify-center items-center">
                  <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                    <Button
                      variant="contained"
                      type="button"
                      fullWidth={false}
                      className="two-xl-larger-text uppercase"
                      disabled={isLoading}
                    >
                      {params?.slug?.trim()}
                    </Button>
                  </ThemeProvider>
                </div>
                <p className="py-3 p1">{t("save_search_modal.p1")}</p>
                <h2 className="head font-bold pt-3">
                  {t("save_search_modal.heading2")}...
                </h2>
                <div className="flex justify-between">
                  <h4 className="p0 font-normal my-3">
                    {t("save_search_modal.heading1")}
                  </h4>
                  <ToggleButton
                    id={notify_id}
                    value={notifyMe}
                    handleChange={() => setNotifyMe(!notifyMe)}
                  />
                </div>
              </div>
              <div className="text-center mt-2">
                <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="two-xl-larger-text"
                    disabled={isLoading}
                  >
                    {t("save_search_modal.done_butn_text")}
                  </Button>
                </ThemeProvider>
                <div className="flex justify-center items-center pt-2">
                  <p className="p1">
                    {t("save_search_modal.p2")}{" "}
                    <Link to="/watch-list" className="underline">
                      {t("save_search_modal.a1")}
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </Sheet>
        </Modal>
      </div>
    </>
  );
});

export default SavedSearch;
