// import & export utilities

import Validator from "./Validator.utils";
import Theme from "./Theme.utils";
import RegExp from "./RegExp.utils";
import HtmlToText from "./htmlToText";
import CalculateDays from "./CalculateDays";
import InvertColorByName from "./invertColorByName.utils"

const Utils = {
  InvertColorByName,
  RegExp,
  Validator,
  Theme,
  HtmlToText,
  CalculateDays
};

export default Utils;
