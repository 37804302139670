import React, { useState, useRef, useEffect, memo } from "react";
import { Button, ThemeProvider, IconButton } from "@mui/material";
import { Modal, ModalClose, Sheet, Textarea } from "@mui/joy";
import Utils from "../../utils";
import { Icons } from "../../assets";
import Swal from "sweetalert2";
import Services from "../../services";
import * as Yup from "yup";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { useFormik } from "formik";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type fileInput = null | any;

interface EditProfileProps {
  openProfile: any;
  setOpenProfile: any;
  bio: string | null;
  avatar: string | null;
  GetProfileInfo: any;
}

// checked
const EditProfile = memo(
  ({
    openProfile,
    setOpenProfile,
    bio,
    avatar,
    GetProfileInfo,
  }: EditProfileProps) => {
    const { t } = useTranslation();
    const fileInput = useRef<fileInput>(null);
    const [newAvatar, setAvatar] = useState<any | null>(null);
    const [newBio, setBio] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [avatarPreview, setAvatarPreview] = useState<string>("");
    const [deleteProfile, setDeleteProfile] = useState<boolean>(false);
    const [validationErrors, setErrors] = useState({
      avatar: "",
    });

    const formik = useFormik({
      initialValues: {
        avatar: "",
        bio: "",
      },
      validationSchema: Yup.object().shape({
        avatar: Yup.string(),
        bio: Yup.string().max(300),
      }),
      onSubmit: async (values) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        validationErrors.avatar === "" && handleUpdateProfile();
      },
    });

    const handleUpdateProfile = () => {
      setOpenProfile(false);
      const data: any = {
        bio: newBio,
      };
      newAvatar && (data.avatar = newAvatar);
      deleteProfile && (data.avatar = null);
      setIsLoading(true);
      Services.Profile.EditProfile(data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: res.message,
            });
            formik?.resetForm();
            setAvatar(null);
            GetProfileInfo && GetProfileInfo();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: t("edit_profile_modal.sweat_alert.title"),
          });
        });
    };

    const setAvatarField = () => {
      if (avatar) {
        setAvatarPreview(`${process.env.REACT_APP_HOST_API_KEY}/${avatar}`);
      } else {
        setAvatarPreview(Icons.Profile);
      }
    };

    const setBioFields = () => {
      if (bio) {
        setBio(bio);
      } else {
        setBio("");
      }
    };

    useEffect(() => {
      setBioFields();
    }, [bio, openProfile]);

    useEffect(() => {
      setAvatarField();
    }, [avatar, openProfile]);

    const handleFileInput = async (e: any) => {
      // get size in MBs
      let error: string = "";
      const fileSize = e.target.files[0].size / (1024 * 1024);

      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        if (fileSize < 2) {
          setErrors((old) => {
            return {
              ...old,
              avatar: "",
            };
          });
          const base64 = window.URL.createObjectURL(e.target.files[0]);
          setAvatar(e.target.files[0]);
          setAvatarPreview(base64);
        } else {
          setAvatarField();
          setErrors((old) => {
            return {
              ...old,
              avatar: t("edit_profile_modal.in_large_msg"),
            };
          });
          error = t("edit_profile_modal.in_large_msg");
        }
      } else {
        setAvatarField();
        setErrors((old) => {
          return {
            ...old,
            avatar: t("edit_profile_modal.invalid_format"),
          };
        });
        error = t("edit_profile_modal.invalid_format");
      }

      e.target.files = null;

      return error;
    };

    useEffect(() => {
      formik.setFieldTouched("avatar", true, false);
      validationErrors.avatar
        ? formik.setFieldValue("avatar", "")
        : formik.setFieldValue("avatar", avatarPreview);
      formik.setFieldError("avatar", validationErrors.avatar);
    }, [validationErrors.avatar]);

    const isDisableSubmit = () => {
      if (isLoading) {
        return true;
      } else if (newBio !== bio || newAvatar || deleteProfile) {
        return false;
      } else {
        return true;
      }
    };

    return (
      <>
        {isLoading && <PreLoaderComponent2 />}
        <div>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={openProfile}
            hideBackdrop={true}
            onClose={() => {
              setOpenProfile(false);
            }}
            sx={Utils.Theme.JoyModalStyle}
          >
            <Sheet variant="outlined" sx={Utils.Theme.JoyModalSheetStyle}>
              <ModalClose
                variant="outlined"
                sx={Utils.Theme.JoyModalCloseStyle}
              />
              <div className="flex justify-center align-items-center w-100">
                <h4 className="head capitalize">
                  <b>{t("edit_profile_modal.j53pvv154")}</b>
                </h4>
              </div>
              <hr />
              <form onSubmit={(e) => formik.handleSubmit(e)}>
                <div className="py-4 w-100">
                  <div className="py-1 pb-3 flex flex-col sm:flex-row justify-start items-center gap-3">
                    <div className="w-24 sm:w-28 lg:w-32 h-24 sm:h-28 lg:h-32 relative profileImagePreview">
                      <img
                        src={avatarPreview}
                        alt="profile-icon"
                        loading="lazy"
                        className="rounded-full w-full h-full border shadow-lg"
                      />
                      {avatar && (
                        <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] bg-[rgba(0,0,0,0.3)] w-full h-full border shadow-lg rounded-full flex justify-center items-center d-onHover">
                          <IconButton
                            aria-label="delete"
                            size="large"
                            onClick={() => {
                              setAvatar(null);
                              setAvatarPreview(Icons.Profile);
                              setDeleteProfile(true);
                            }}
                          >
                            <Delete className="text-[var(--main-color)]" />
                          </IconButton>
                        </div>
                      )}
                    </div>
                    <div className="">
                      <input
                        type="file"
                        ref={fileInput}
                        onChange={(e) => {
                          handleFileInput(e);
                          formik.handleChange(e);
                          formik.setFieldTouched("avatar", true, false);
                        }}
                        hidden
                        name="profilePic"
                        id="profilePic"
                        accept="image/png, image/jpg, image/jpeg"
                        onBlur={formik.handleBlur}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          fileInput?.current?.click();
                          formik.setFieldTouched("avatar", true, false);
                        }}
                        className="py-2 px-3 rounded-xl border shadow2 p1"
                      >
                        {newAvatar?.name
                          ? newAvatar.name
                          : "Choose a profile picture"}
                      </button>
                      {validationErrors.avatar ? (
                        <WarningAlert message={validationErrors.avatar} />
                      ) : (
                        <p className="p2 text-center">
                          JPG, PNG, JPEG (Max 2MB)
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="py-1">
                    <label className="darkText p1">
                      {t("edit_profile_modal.fmcrq3b3g")}
                    </label>
                    <div className="InputFieldSetting">
                      <Textarea
                        name="bio"
                        placeholder={t("edit_profile_modal.fmcrq3b3g")}
                        variant="outlined"
                        color="neutral"
                        minRows={3}
                        value={newBio}
                        onChange={(e: any) => {
                          formik.handleChange(e);
                          setBio(e.target.value);
                        }}
                        sx={
                          formik.errors.bio && formik.touched.bio
                            ? Utils.Theme.JoyInputError
                            : Utils.Theme.JoyGrayShadeInputStyle
                        }
                        onBlur={formik.handleBlur}
                        slotProps={{
                          textarea: {
                            maxLength: 300,
                          },
                        }}
                      />
                    </div>
                    {formik.errors.bio && formik.touched.bio && (
                      <WarningAlert message={formik.errors.bio} />
                    )}
                  </div>
                </div>
                <div className="text-center sm:pt-2 flex justify-end items-center ">
                  <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isDisableSubmit() ? true : false}
                      fullWidth
                    >
                      {t("edit_profile_modal.j53pvv154")}
                    </Button>
                  </ThemeProvider>
                </div>
              </form>
            </Sheet>
          </Modal>
        </div>
      </>
    );
  }
);

export default EditProfile;
