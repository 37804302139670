import axios from "axios";
import { ApiConfig, FormapiConfig } from "../config";

const GetProfileUsingAT = (at: string) => {
  return axios
    .get(process.env.REACT_APP_HOST_API_KEY + "/api/user/profile", {
      headers: {
        Authorization: `Bearer ${at}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((res: any) => {
      return res.data;
    });
};

const GetProfile = () => {
  return ApiConfig.get("/api/user/profile").then((res: any) => {
    return res.data;
  });
};

const GetPublicProfile = (username: string) => {
  return ApiConfig.get(`/api/user/profiles/${username}`).then((res: any) => {
    return res.data;
  });
};

const EditProfile = (data: any) => {
  if (data.avatar) {
    return FormapiConfig.post("/api/user/profile", data).then((res: any) => {
      return res.data;
    });
  } else {
    return ApiConfig.post("/api/user/profile", data).then((res: any) => {
      return res.data;
    });
  }
};

const ChangePassword = (data: any) => {
  return ApiConfig.post("/api/user/change/password", data).then((res: any) => {
    return res.data;
  });
};

const GetProfileBidHistory = (
  username: string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(`/api/user/profiles/${username}/bid-history`, {
    params: {
      offset,
      limit,
    },
  }).then((res: any) => {
    return res.data;
  });
};

const GetProfileAuctions = (
  username: string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(`/api/user/profiles/${username}/bid-history`, {
    params: {
      offset,
      limit,
    },
  }).then((res: any) => {
    return res.data;
  });
};

const GetProfileComments = (
  username: string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(`/api/user/profiles/${username}/comments`, {
    params: {
      offset,
      limit,
    },
  }).then((res: any) => {
    return res.data;
  });
};

const Profile = {
  GetProfileAuctions,
  GetPublicProfile,
  GetProfileComments,
  GetProfileBidHistory,
  ChangePassword,
  GetProfile,
  EditProfile,
  GetProfileUsingAT,
};

export default Profile;
