import Main from "./Main.layout";
import User from "./User.layout";
import Application from "./Application.layout";
import General from "./General.layout";
import Dashboard from "./Dashboard.layout";
import DashboardAuth from "./Dashboard.auth.layout";
import NotFoundLayout from "./NotFoundLayout.layout";

const Layout = {
  Dashboard,
  Main,
  User,
  Application,
  General,
  DashboardAuth,
  NotFoundLayout,
};

export default Layout;
