import { ApiConfig } from "../config";

const getFaqs = () => {
  return ApiConfig.get("/api/user/faqs").then((res: any) => {
    return res.data;
  });
};

const getFaqsForAdmin = (
  offset: number,
  limit: number,
  type: string,
  search?: string | null
) => {
  return ApiConfig.get("/api/admin/faqs", {
    params: { offset, limit, type, search },
  }).then((res: any) => {
    return res.data;
  });
};

const createFaq = (data: any) => {
  return ApiConfig.post("/api/admin/faqs", data).then((res: any) => {
    return res.data;
  });
};

const updateFaq = (id: number | string, data: any) => {
  return ApiConfig.post(`/api/admin/faqs/${id}`, data).then((res: any) => {
    return res.data;
  });
};

const deleteFaq = (id: number | string) => {
  return ApiConfig.delete(`/api/admin/faqs/${id}`).then((res: any) => {
    return res.data;
  });
};

const Faqs = {
  deleteFaq,
  getFaqs,
  getFaqsForAdmin,
  createFaq,
  updateFaq,
};

export default Faqs;
