import moment from 'moment';

const CalculateDays = (auction_end: any) => {
  if (auction_end) {
    const auctionEndDate = new Date(moment(auction_end).format('LLLL'));
    const todayDate = new Date();
    let timeDifference = auctionEndDate.getTime() - todayDate.getTime();
    let dayMilliSeconds = 1000 * 60 * 60 * 24;
    return timeDifference / dayMilliSeconds;
  }
  return 0;
};

export default CalculateDays;
