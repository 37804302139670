import  { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ThemeProvider, Button } from "@mui/material";
import Utils from "../../utils";
import Services from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

type listingType = {
  highlights: string;
  equipment: string;
  other_items: string;
  ownership_history: string;
  seller_notes: string;
  service_history?: string;
};

// checked
const Listing = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState<string>("");
  const [equipments, setEquipments] = useState<string>("");
  const [otherItems, setOtherItems] = useState<string>("");
  const [ownership, setOwnership] = useState<string>("");
  const [sellerNotes, setSellerNotes] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [listing, setListing] = useState<any>({});

  const formik = useFormik({
    initialValues: {
      highlights: "",
      equipments: "",
      other_items: "",
      ownership_history: "",
      seller_notes: "",
    },
    validationSchema: Yup.object().shape({
      highlights: Yup.string().required().trim().min(30).max(2000),
      equipments: Yup.string().required().trim().min(30).max(2000),
      other_items: Yup.string().trim().max(2000),
      ownership_history: Yup.string().required().trim().min(30).max(2000),
      seller_notes: Yup.string().trim().max(2000),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  const resetForm = () => {
    setHighlights("");
    setEquipments("");
    setOtherItems("");
    setOwnership("");
    setSellerNotes("");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = () => {
    const data: listingType = {
      highlights: highlights.trim(),
      equipment: equipments.trim(),
      other_items: otherItems.trim(),
      ownership_history: ownership.trim(),
      seller_notes: sellerNotes.trim(),
    };
    if (listing.id) {
      setLoading(true);
      Services.Listing.UpdateLisitng(listing.id, data)
        .then((res) => {
          setLoading(false);
          if (res.status) {
            resetForm();
            Swal.fire(
              t("user_listing_page.gzhwhs3pk"),
              t("user_listing_page.lo46cqxh0")
            );
            return getListingInfo();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.data.message.split(".")[0],
            });
          }
        });
    }
  };

  const getListingInfo = () => {
    params.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res) => {
        if (res.status && res.data) {
          setListing(res.data);
          const data = res.data;
          if (
            data.application_status !== "approved" ||
            !data.title ||
            !data.description ||
            !data.location ||
            !data.engine ||
            !data.drive_train ||
            !data.body_style ||
            !data.exterior_color ||
            !data.interior_color ||
            !data.service_history ||
            data.photos?.exterior?.length === 0 ||
            data.photos?.interior?.length === 0 ||
            data.photos?.mechanical?.length === 0 ||
            data.photos?.docs?.length === 0 ||
            !data.schedule_time_slot ||
            !data.review
          ) {
            return navigate(`/application/${params?.list_id}`);
          }
          const qlEditor = document.querySelectorAll(".ql-editor");
          qlEditor[0] && (qlEditor[0].innerHTML = data.highlights);
          qlEditor[1] && (qlEditor[1].innerHTML = data.equipment);
          qlEditor[2] && (qlEditor[2].innerHTML = data.other_items);
          qlEditor[3] && (qlEditor[3].innerHTML = data.ownership_history);
          qlEditor[4] && (qlEditor[4].innerHTML = data.seller_notes);
          setHighlights(data.highlights ? data.highlights : "");
          setEquipments(data.equipment ? data.equipment : "");
          setOtherItems(data.other_items ? data.other_items : "");
          setOwnership(data.ownership_history ? data.ownership_history : "");
          setSellerNotes(data.seller_notes ? data.seller_notes : "");
        }
      });
  };

  useEffect(() => {
    getListingInfo();
  }, [params]);

  const handleFieldValue = (value: string, fieldType: string) => {
    const { text } = Utils.HtmlToText(value);
    formik?.setFieldTouched(fieldType, true);
    formik?.setFieldValue(fieldType, text);
    fieldType === "highlights"
      ? setHighlights(value)
      : fieldType === "equipments"
        ? setEquipments(value)
        : fieldType === "other_items"
          ? setOtherItems(value)
          : fieldType === "ownership_history"
            ? setOwnership(value)
            : fieldType === "seller_notes"
              ? setSellerNotes(value)
              : alert(t("user_listing_page.error"));
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("user_listing_page.title")} />
      {loading && <PreLoaderComponent2 />}
      <div className="">
        <div className="flex flex-col pb-8">
          <div className="flex justify-between items-center">
            <h1 className="p1 font-normal">
              <span
                className="text-[var(--main-color)] hover:text-[var(--dark-color)] cursor-pointer"
                onClick={() => navigate("/listings")}
              >
                {t("user_listing_page.u0ljl20ow")}
              </span>{" "}
              {listing?.year ? "—" : ""} {listing?.year} {listing?.make}{" "}
              {listing?.model} {listing?.title ? listing?.title : ""}
            </h1>
          </div>
        </div>
        {listing.details_submission_status === "rejected" ? (
          <h1 className="head6 font-bold">
            {t("user_listing_page.7qja1cfoj")}
          </h1>
        ) : (
          <form noValidate onSubmit={formik.handleSubmit} className="pt-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2 md:gap-4 justify-start items-center sm:w-3/4">
                <div className="InputFieldSetting">
                  <label className="p1 font-bold" htmlFor="title">
                    {t("user_listing_page.42u2tzv2k")}
                    <sup className="requireField">*</sup>
                  </label>
                  <ReactQuill
                    id="highlights"
                    theme="snow"
                    onChange={(value) => handleFieldValue(value, "highlights")}
                    className={`fadedStyle ${formik?.errors?.highlights && formik?.touched?.highlights ? "error" : ""}`}

                  />{" "}
                  {formik?.errors?.highlights &&
                    formik?.touched?.highlights && (
                      <WarningAlert message={formik?.errors.highlights} />
                    )}
                </div>
                <div className="InputFieldSetting">
                  <label className="p1 font-bold" htmlFor="title">
                    {t("user_listing_page.a6t4nhbeo")}
                    <sup className="requireField">*</sup>
                  </label>
                  <ReactQuill
                    theme="snow"
                    onChange={(value) => handleFieldValue(value, "equipments")}
                    className={`fadedStyle ${formik?.errors?.equipments && formik?.touched?.equipments ? "error" : ""}`}

                  />{" "}
                  {formik?.errors?.equipments &&
                    formik?.touched?.equipments && (
                      <WarningAlert message={formik?.errors.equipments} />
                    )}
                </div>
                <div className="InputFieldSetting">
                  <label className="p1 font-bold" htmlFor="title">
                    {t("user_listing_page.rchekcrkm")}
                  </label>
                  <ReactQuill
                    theme="snow"
                    onChange={(value) => handleFieldValue(value, "other_items")}
                    className={`fadedStyle ${formik?.errors?.other_items && formik?.touched?.other_items ? "error" : ""}`}

                  />{" "}
                  {formik?.errors?.other_items &&
                    formik?.touched?.other_items && (
                      <WarningAlert message={formik?.errors.other_items} />
                    )}
                </div>
                <div className="InputFieldSetting">
                  <label className="p1 font-bold" htmlFor="title">
                    {t("user_listing_page.mg0ibb19m")}
                    <sup className="requireField">*</sup>
                  </label>
                  <ReactQuill
                    theme="snow"
                    onChange={(value) =>
                      handleFieldValue(value, "ownership_history")
                    }
                    className={`fadedStyle ${formik?.errors?.ownership_history && formik?.touched?.ownership_history ? "error" : ""}`}
                  />{" "}
                  {formik?.errors?.ownership_history &&
                    formik?.touched?.ownership_history && (
                      <WarningAlert
                        message={formik?.errors.ownership_history}
                      />
                    )}
                </div>
                <div className="InputFieldSetting">
                  <label className="p1 font-bold" htmlFor="title">
                    {t("user_listing_page.9yj4hvkx0")}{" "}
                  </label>
                  <ReactQuill
                    theme="snow"
                    onChange={(value) =>
                      handleFieldValue(value, "seller_notes")
                    }
                    className={`fadedStyle ${formik?.errors?.seller_notes && formik?.touched?.seller_notes ? "error" : ""}`}
                  />{" "}
                  {formik?.errors?.seller_notes &&
                    formik?.touched?.seller_notes && (
                      <WarningAlert message={formik?.errors.seller_notes} />
                    )}
                </div>
                <div className="py-2 flex justify-end items-end self-end">
                  <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                    <Button
                      type="submit"
                      variant="contained"
                      className="hover:underline-offset-1 cursor-pointer ml-auto"
                      disabled={
                        loading ||
                        listing?.auction?.auction_end ||
                        listing.details_submission_status !== "pending"
                      }
                    >
                      {t("user_listing_page.rr7xes3ve")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="CurrentColor"
                        viewBox="0 0 448 512"
                        className="w-4 h-4 ml-2"
                      >
                        <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                      </svg>
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default Listing;
