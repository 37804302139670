import React, { memo } from "react";
import { Helmet } from "react-helmet";

interface featureType {
  title: string;
  description: string;
  make: string;
  model: string;
  year: string;
  bid: string;
  location: string;
  image: string[];
}

const FeatureContentComponent = memo(
  ({
    title,
    description,
    make,
    model,
    year,
    bid,
    location,
    image,
  }: featureType) => {
    return (
      <div>
        <Helmet>
          <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org/",
          "@type": "product",
          "name": ${year + ", " + make + ", " + model + ", " + title},
          "description": ${description},
          "bid": ${bid},
          "location: ${location}
          "image":${image}
        }
      `}
          </script>
        </Helmet>
        {/* Your component content */}
      </div>
    );
  }
);

export default FeatureContentComponent;
