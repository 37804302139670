import { Grid } from "@mui/material";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CardSkeletonComponent from "../pages/components/CardsSkeleton.component";

const AuctionDetailsSkeletonComponent = () => {
    const style = {
        borderRadius: "0.5rem",
        height: "26px",
        width: "100%"
    };


    return (
        <div className="w-full flex flex-col mt-6 text-gray-700 bg-white  px-4 py-3 gap-8 h-full ">
            {/* title skeleton */}
            <div className="flex gap-4 justify-start items-end">
                <div className="flex flex-col gap-2 w-2/3">
                    <Skeleton
                        style={{
                            ...style,
                        }}
                    />
                    <Skeleton
                        style={{
                            ...style,
                        }}
                    />
                </div>
                <div className="w-1/3 flex justify-end items-end">
                    <Skeleton
                        style={{
                            ...style,
                            width: "200px",
                            height: "66px"
                        }}
                    />
                </div>
            </div>
            {/* images skeleton */}
            <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                <div className="w-full sm:w-2/3">
                    <Skeleton
                        style={{
                            ...style,
                            height: "300px"
                        }}
                    />
                </div>
                <div className="flex flex-wrap justify-between items-center w-full sm:w-1/3">
                    {
                        _.times(6, (index) => (
                            <div className="w-1/2 p-0.5" key={index}>
                                <Skeleton
                                    style={{
                                        ...style,
                                        height: "95px"
                                    }}
                                    key={index}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="flex gap-4 justify-between items-start w-full">
                {/* left side */}
                <div className="sm:w-2/3 flex flex-col gap-2 ">
                    <Skeleton
                        style={{
                            ...style,
                        }}
                    />
                    <Skeleton
                        style={{
                            ...style,
                            height: "60px"
                        }}
                    />
                    <Skeleton
                        style={{
                            ...style,
                            height: "300px"
                        }}
                    />
                    <Skeleton
                        style={{
                            ...style,
                            height: "100px"
                        }}
                    />
                    {
                        _.times(2, (index) => (
                            <div key={index} className="flex flex-col gap-2 py-4">
                                <Skeleton
                                    style={{
                                        ...style,
                                    }}
                                />
                                <Skeleton
                                    style={{
                                        ...style,
                                        height: "200px"
                                    }}
                                />
                                <hr className="mt-4" />
                            </div>
                        ))
                    }
                    <Skeleton
                        style={{
                            ...style,
                            height: "100px"
                        }}
                    />
                    <Skeleton
                        style={{
                            ...style,
                            height: "100px"
                        }}
                    />

                </div>
                {/* right side */}
                <div className="sm:w-1/3 hidden sm:block">
                    <Skeleton
                        style={{
                            ...style,
                        }}
                    />
                    {
                        _.times(2, (index) => (
                            <div key={index}>
                                <CardSkeletonComponent />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AuctionDetailsSkeletonComponent