import React, { useEffect, useState, memo } from "react";
import { Tabs, TabList, Tab } from "@mui/joy";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import PropTypes from "prop-types";
import CarImagesSwiper from "./CarImagesSwiper.component";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index: Number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type photosType = {
  exterior: string[];
  interior: string[];
  mechanical: string[];
  docs: string[];
  other: string[];
};

type CarShowCaseProps = {
  showCars: boolean;
  setShowCars: any;
  data: any;
  sliderIndex?: number | null;
  setSliderIndex?: any;
};

// checked
const CarShowCase = memo(function CarShowCase({
  showCars,
  setShowCars,
  data,
  sliderIndex,
  setSliderIndex,
}: CarShowCaseProps) {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState<photosType>({
    exterior: [],
    interior: [],
    mechanical: [],
    docs: [],
    other: [],
  });
  const [value, setValue] = useState(0);
  const [cols, setCols] = useState(1);
  const [auction_images, setAuctionImages] = useState<any[]>([]);
  const [i, setI] = useState<any>(null);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data)?.map((key) => {
        setPhotos((old: any) => {
          return { ...old, [key]: data[key] };
        });
      });
    }
  }, [data]);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setShowCars(false);
  };

  const checkScreenSize = () => {
    if (window.matchMedia("(max-width:592px)").matches) {
      return "sm";
    } else if (window.matchMedia("(max-width:768px)").matches) {
      return "md";
    } else if (window.matchMedia("(max-width:1024px)").matches) {
      return "lg";
    } else if (window.matchMedia("(max-width:1536px)").matches) {
      return "xl";
    } else {
      return "2xl";
    }
  };

  const setColsOnResizing = () => {
    const screenSize = checkScreenSize();
    if (screenSize === "2xl") {
      setCols(6);
    } else if (screenSize === "xl") {
      setCols(5);
    } else if (screenSize === "lg") {
      setCols(4);
    } else if (screenSize === "md") {
      setCols(3);
    } else if (screenSize === "sm") {
      setCols(1);
    }
  };

  useEffect(() => {
    setColsOnResizing();
    window.addEventListener("resize", () => {
      setColsOnResizing();
    });
  }, []);

  const handleCarImages = (photos: any) => {
    let array: any[] = [];
    Object.keys(photos).length > 0 &&
      Object.keys(photos)?.map((photoCategory: string) => {
        let photo = photos[photoCategory];
        photo?.map((item: string) => {
          array.push(item);
        });
      });
    setAuctionImages(array);
  };

  useEffect(() => {
    photos && handleCarImages(photos);
  }, [photos]);

  const changeActiveTab = () => {
    const imageLengthUptoExterior = photos?.exterior?.length;
    const imageLengthUptoInterior =
      photos?.interior?.length + imageLengthUptoExterior;
    const imageLengthUptoMechanical =
      photos?.mechanical?.length + imageLengthUptoInterior;
    const imageLengthUptoDocs =
      photos?.docs?.length + imageLengthUptoMechanical;

    let tab = sliderIndex
      ? sliderIndex >= 0
        ? sliderIndex > imageLengthUptoDocs - 1
          ? 5
          : sliderIndex > imageLengthUptoMechanical - 1
            ? 4
            : sliderIndex > imageLengthUptoInterior - 1
              ? 3
              : sliderIndex > imageLengthUptoExterior - 1
                ? 2
                : 1
        : 1
      : 0;

    setValue(tab);

    setI(
      sliderIndex
        ? tab === 5
          ? sliderIndex - imageLengthUptoDocs
          : tab === 4
            ? sliderIndex - imageLengthUptoMechanical
            : tab === 3
              ? sliderIndex - imageLengthUptoInterior
              : tab === 2
                ? sliderIndex - imageLengthUptoExterior
                : sliderIndex
        : null
    );
  };

  useEffect(() => {
    changeActiveTab();
  }, [sliderIndex, photos]);

  return (
    <>
      <div className="">
        <Dialog
          fullScreen
          open={showCars}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }} className="bgColor ">
            <Toolbar className="flex justify-between align-center  ">
              <Tabs
                aria-label="tabs"
                defaultValue={0}
                className="showCaseViewTabs"
                value={value}
                onChange={handleChange}
                sx={{
                  ".MuiTabList-root": {
                    maxWidth: "80vw !important",
                  },
                }}
              >
                <TabList
                  variant="plain"
                  className="tabsIncatorColor mr-16 sm:mr-0 overflow-x-auto overflow-y-hidden"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    ".tabsIncatorColor > button": "transparent",
                    ".Mui-selected, .MuiTab-root:hover": {
                      color: "var(--light-color) !important",
                      fontWeight: 700,
                      minWidth: "fit-content",
                      width: "-webkit-fill-available",
                      fontSize: "1rem",
                    },
                    ".MuiTabList-root": {
                      paddingRight: "12px",
                    },
                    paddingBottom: "8px",
                    ".MuiTab-root": {
                      width: "-webkit-fill-available",
                      paddingX: "5px",
                      minWidth: "fit-content",
                    },
                    ".tabsIncatorColor > button.Joy-selected, .tabsIncatorColor > button.Mui-selected, .tabsIncatorColor > button:hover, .Mui-selected, .tabsIncatorColor > button:hover":
                      {
                        // borderStyle: "solid !important",
                      },
                  }}
                >
                  <Tab {...a11yProps(0)} className="tabsIndictor">
                    {t("auction_details_page.car_show_case.tabs.all")} (
                    {photos?.exterior?.length +
                      photos?.interior?.length +
                      photos?.mechanical?.length +
                      photos?.docs?.length +
                      photos?.other?.length}
                    )
                  </Tab>
                  <Tab {...a11yProps(1)}>
                    {t("auction_details_page.car_show_case.tabs.exterior")}
                  </Tab>
                  <Tab {...a11yProps(2)}>
                    {t("auction_details_page.car_show_case.tabs.interior")}
                  </Tab>
                  <Tab {...a11yProps(3)}>
                    {t("auction_details_page.car_show_case.tabs.mechanical")}
                  </Tab>
                  <Tab {...a11yProps(4)}>
                    {t("auction_details_page.car_show_case.tabs.docs")}
                  </Tab>
                  <Tab {...a11yProps(5)}>
                    {t("auction_details_page.car_show_case.tabs.other")}
                  </Tab>
                </TabList>
              </Tabs>
              <IconButton
                edge="start"
                onClick={handleClose}
                aria-label="close"
                sx={{
                  marginLeft: "12px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-5 h-5 text-white "
                >
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              height: "100%",
              boxShadow:
                "inset 0px 8px 12px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
              ".MuiBox-root": {
                paddingY: 0,
              },
            }}
          >
            <TabPanel value={value} index={0}>
              <ImageList cols={cols}>
                {auction_images?.length > 0 &&
                  auction_images?.map((img: string, index: number) => (
                    <ImageListItem
                      key={index}
                      className="cursor-pointer"
                      onClick={() => setSliderIndex(index)}
                    >
                      <img
                        src={process.env.REACT_APP_HOST_API_KEY + "/" + img}
                        alt="car title"
                        loading="lazy"
                        className="object-cover h-auto"
                      />
                    </ImageListItem>
                  ))}
              </ImageList>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <CarImagesSwiper
                  data={photos?.exterior}
                  sliderIndex={i}
                  setSliderIndex={setI}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CarImagesSwiper
                data={photos?.interior}
                sliderIndex={i}
                setSliderIndex={setI}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <CarImagesSwiper
                data={photos?.mechanical}
                sliderIndex={i}
                setSliderIndex={setI}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CarImagesSwiper
                data={photos?.docs}
                sliderIndex={i}
                setSliderIndex={setI}
              />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <CarImagesSwiper
                data={photos?.other}
                sliderIndex={i}
                setSliderIndex={setI}
              />
            </TabPanel>
          </Box>
        </Dialog>
      </div>
    </>
  );
});

export default CarShowCase;
