import API from "../config/api.config";

const SendPushNotification = (id: string | number, data:any) => {
  return API.post(`/api/user/send-push-notification/${id}`, data).then((res: any) => {
    return res.data;
  });
};

const GetNotification = (offset: number, limit: number, is_unread: boolean) => {
  return API.get(
    `/api/user/notifications?offset=${offset}&limit=${limit}&is_unread=${is_unread}`
  ).then((res: any) => {
    return res.data;
  });
};

const MarkNotificationAsRead = (data: any) => {
  return API.post(`/api/user/notifications/mark-as-read`, data).then(
    (res: any) => {
      return res.data;
    }
  );
};

const GetAdminNotification = (offset: number, limit: number, is_unread: boolean) => {
  return API.get(
    `/api/admin/notifications?offset=${offset}&limit=${limit}&is_unread=${is_unread}`
  ).then((res: any) => {
    return res.data;
  });
};

const MarkAdminNotificationAsRead = (data: any) => {
  return API.post(`/api/admin/notifications/mark-as-read`, data).then(
    (res: any) => {
      return res.data;
    }
  );
};

const Notification = {
  SendPushNotification,
  GetNotification,
  MarkNotificationAsRead,
  GetAdminNotification,
  MarkAdminNotificationAsRead
};

export default Notification;
