import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../assets";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

// checked
const Shipping = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageComponents.MetaComponent
        title={t("shipping_page.title")}
        canonicalUrl={window.location.host}
      />
      <div className="containerSpacing mt-16">
        <div className="shipping">
          <h1 className="head8 font-extrabold pt-6 border-b-8 border-[var(--main-color)] w-fit 	">
            {t("shipping_page.heading")}
          </h1>
          <div className="section my-12">
            <img
              src={Images.Shipping}
              alt="Auto Bids shipping"
              className="w-full h-full"
            />
          </div>
          <div className="section">
            <h3>
              <span className="bg-[var(--main-color)] text-white rounded shadow me-2 px-1.5 py-1 text-base uppercase">
                {t("shipping_page.8riqtdmdg")}
              </span>
              {t("shipping_page.ymaxulz2n")}
            </h3>
            <p className="py-3">{t("shipping_page.ewg8o6zq1")}</p>
          </div>
          <div className="section">
            <h3>{t("shipping_page.qaq53rmav")}</h3>
            <p className="py-3">{t("shipping_page.u5nra40c8")}</p>
          </div>
          <div className="section">
            <h3>{t("shipping_page.s4wvbzkvp")}</h3>
            <div className="flex justify-start lg:justify-between items-start flex-wrap gap-4 py-4">
              <div className="py-4">
                <img
                  src={Images.ZipCode}
                  alt="Auto Bids shipping"
                  className="lg:1/3 h-full w-full"
                />
                <div className="flex justify-start items-center gap-2 py-3">
                  <span className="bg-[var(--main-color)] text-white px-3 py-1 flex justify-center items-center rounded-full font-medium">
                    1
                  </span>
                  <p>{t("shipping_page.hj3p3xj4s")}</p>
                </div>
              </div>
              <div className="py-4">
                <img
                  src={Images.Book}
                  alt="Auto Bids shipping"
                  className="lg:1/3 h-full w-full"
                />
                <div className="flex justify-start items-center gap-2 py-3">
                  <span className="bg-[var(--main-color)] px-3 py-1 flex justify-center items-center rounded-full font-medium text-white">
                    2
                  </span>
                  <p>{t("shipping_page.hmat5eb98")}</p>
                </div>
              </div>
              <div className="py-4">
                <img
                  src={Images.Delivery}
                  alt="Auto Bids shipping"
                  className="lg:1/3 h-full w-full"
                />
                <div className="flex justify-start items-center gap-2 py-3">
                  <span className="bg-[var(--main-color)] px-3 py-1 flex justify-center items-center rounded-full font-medium text-white">
                    3
                  </span>
                  <p>{t("shipping_page.x3tz62lb5")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <h3>{t("shipping_page.hv983id8p")}</h3>
            <p className="py-3">
              {t("shipping_page.imq7189jt")}{" "}
              <Link to="tel:9789552355">(978) 955-2355</Link>{" "}
              {t("shipping_page.mv28ypg3q")}{" "}
              <Link to="mailto:shipping@auto-bids.ch">
                shipping@auto-bids.ch
              </Link>{" "}
              {t("shipping_page.7hmqqrw9p")}
            </p>
          </div>
          <div className="section">
            <h3>{t("shipping_page.qq55tgpzo")}</h3>

            <ul className="py-3 list-disc ps-12 text-base">
              <li>{t("shipping_page.8d05ytqkb")}</li>
              <li>{t("shipping_page.adfi7pncp")}</li>
              <li>
                {t("shipping_page.3r8d5jwqd")}{" "}
                <Link to="tel:9789552355">(978) 955-2355</Link>{" "}
                {t("shipping_page.l5zrs608i")}
                <Link to="mailto:shipping@auto-bids.ch">
                  shipping@auto-bids.ch
                </Link>
                .
              </li>
              <li>
                {t("shipping_page.vt4gbbsgb")}{" "}
                <Link to="/shipping"> {t("shipping_page.vt4gbbsgb1")}</Link>{" "}
                {t("shipping_page.vt4gbbsgb2")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shipping;
