import { memo } from "react";
import { Avatar } from "@mui/material";

// checked
const AuthorQuote = memo(({ data }: any) => {
  return (
    <div className="py-4">
      <div className="bg-gray-100 border px-5 py-7 flex justify-start items-center sm:items-start gap-3 rounded flex-wrap sm:flex-nowrap accordionShadow drop-shadow-sm">
        <Avatar
          src={
            data?.reviewer?.avatar &&
            process.env.REACT_APP_HOST_API_KEY + data?.reviewer?.avatar
          }
          alt={data?.reviewer?.name}
          sx={{
            boxShadow: "0 5px 5px rgb(0 0 0 / 0.15)",
            border: "1px solid rgb(0 0 0 / 0.15",
          }}
        />
        <div className="ps-0 sm:ps-2">
          <h3 className="font-bold p000 tracking-wide	mt-auto capitalize">
            {data?.reviewer?.name}
          </h3>
          <p className="p1 italic text-gray-800 hidden sm:block">
            {data?.review}
          </p>
        </div>
        <p className="p1 italic text-gray-800 sm:hidden w-full">
          {data?.review}
        </p>
      </div>
    </div>
  );
});

export default AuthorQuote;
