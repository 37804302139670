import { useEffect, useState } from "react";
import { Avatar, Button, ThemeProvider } from "@mui/material";
import Utils from "../../utils";
import EditProfile from "../components/EditProfile.component.page";
import Services from "../../services";
import moment from "moment";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

type EditProfileType = Boolean;

// checked
function Profile() {
  const { t } = useTranslation();
  const [openEditProfile, setOpenEditProfile] =
    useState<EditProfileType>(false);
  const user = Services.Storage.getCurrentUser();
  const [username, setUserName] = useState(user?.username);
  const [joingDate, setjoinDate] = useState(
    moment(user?.created_at).format("MMM  YYYY")
  );
  const [avatar, setAvatar] = useState(user?.avatar);
  const [bio, setBio] = useState(user?.bio);

  const GetProfileInfo = () => {
    Services.Profile.GetProfile().then((res) => {
      if (res.status) {
        const data = res.data;
        Services.Storage.updateUser(data);
        setUserName(data.username);
        setjoinDate(moment(data?.created_at).format("MMM  YYYY"));
        setAvatar(data.avatar);
        setBio(data.bio);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageComponents.MetaComponent title={t("user_profile_page.title")} />
      <div className="w-full containerSpacing">
        <div className="flex flex-col sm:flex-row justify-center sm:justify-between items-center sm:items-start gap-2 ">
          <div className="flex justify-center sm:juftify-start items-center sm:items-start ">
            <div className="w-24 md:w-28 lg:w-36 h-24 md:h-28 lg:h-36">
              <Avatar
                src={
                  avatar && process.env.REACT_APP_HOST_API_KEY + "/" + avatar
                }
                alt={username}
                className="w-full h-full rounded-full shadow2 border"
                sx={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="py-2 sm:py-4 md:py-8 ps-4">
              <h3 className="font-semibold head">{username}</h3>
              <p className="text-uppercase p1">
                {t("user_profile_page.joined")} {joingDate}
              </p>
            </div>
          </div>
          <div className="py-2 sm:py-4 md:py-8 w-2/3 sm:w-fit">
            <ThemeProvider theme={Utils.Theme.ButtonTheme}>
              <Button
                variant="contained"
                onClick={() => setOpenEditProfile(true)}
                className="shadow-md"
                fullWidth
              >
                {t("user_profile_page.edit_profile_butn_text")}
              </Button>
            </ThemeProvider>
          </div>
        </div>
        <div className="flex justify-start items-start gap-2 py-6 px-4">
          <p className="p1 w-full sm:w-2/3 break-all">{bio}</p>
        </div>
      </div>
      <EditProfile
        openProfile={openEditProfile}
        setOpenProfile={setOpenEditProfile}
        bio={bio}
        avatar={avatar}
        GetProfileInfo={GetProfileInfo}
      />
    </>
  );
}

export default Profile;
