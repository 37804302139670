import { ApiConfig } from "../config";

const SaveCard = (data: any) => {
  return ApiConfig.post("/api/user/account/cards", data, {}).then(
    (res: any) => {
      return res.data;
    }
  );
};

const FetchCards = (offset: number, limit: number) => {
  return ApiConfig.get("/api/user/account/cards", {params: {offset, limit}}).then((res: any) => {
    return res.data;
  });
};

const getUsersForAdmin = (
  offset: number,
  limit: number,
  search?: string | null
) => {
  return ApiConfig.get(`/api/admin/users`, {
    params: { offset, limit, search },
  }).then((res: any) => {
    return res.data;
  });
};

const getOneUserForAdmin = (id: number | string) => {
  return ApiConfig.get(`/api/admin/users/${id}`).then((res: any) => {
    return res.data;
  });
};

const getUserListingsForAdmin = (
  id: number | string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(
    `/api/admin/users/${id}/listings?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  });
};

const getUserAuctionsForAdmin = (
  id: number | string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(
    `api/admin/users/${id}/auctions?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  });
};

const getUserBidsForAdmin = (
  id: number | string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(
    `/api/admin/users/${id}/bids?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  });
};

const getUserCommentsForAdmin = (
  id: number | string,
  offset: number,
  limit: number
) => {
  return ApiConfig.get(
    `/api/admin/users/${id}/comments?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  });
};

const User = {
  getUserCommentsForAdmin,
  getUserBidsForAdmin,
  getUserListingsForAdmin,
  SaveCard,
  getUsersForAdmin,
  getOneUserForAdmin,
  getUserAuctionsForAdmin,
   FetchCards
};

export default User;
