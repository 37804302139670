import API from "../config/api.config";

const CreateBid = (data: any) => {
  return API.post(`api/user/listings/bids`, data).then((res: any) => {
    return res.data;
  });
};

const UpvoteBid = (id:number, bid_id:number, data: any) => {
  return API.post(`/api/user/listings/${id}/bids/${bid_id}/upvote`, data).then((res: any) => {
    return res.data;
  });
};

const Bid = {
  CreateBid,
  UpvoteBid
};

export default Bid;
