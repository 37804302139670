import { ApiConfig, FormapiConfig } from "../config";

const CreateReviewer = (data: any) => {
  return FormapiConfig.post("/api/admin/reviewers", data).then((res: any) => {
    return res.data;
  });
};

const UpdateReviewer = (id: string | number, data: any) => {
  let API = ApiConfig;
  if (data.avatar) {
    API = FormapiConfig;
  }
  return API.post(`/api/admin/reviewers/${id}`, data).then((res: any) => {
    return res.data;
  });
};

const DeleteReviewer = (id: string | number) => {
  return ApiConfig.delete(`/api/admin/reviewers/${id}`).then((res: any) => {
    return res.data;
  });
};

const GetReviewers = (
  offset?: number,
  limit?: number,
  search?: string | null
) => {
  let url = "/api/admin/reviewers";
  return ApiConfig.get(url, { params: { offset, limit, search } }).then(
    (res: any) => {
      return res.data;
    }
  );
};

const Reviewer = {
  CreateReviewer,
  UpdateReviewer,
  DeleteReviewer,
  GetReviewers,
};

export default Reviewer;
