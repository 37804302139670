// // Function to invert a color by name
// function InvertColorByName(colorName: string) {
//   // Define a mapping of color names to their inverted RGB values
//   const colorMap: any = {
//     black: "white",
//     white: "black",
//     red: "cyan", // Example: Inverting red to cyan
//     green: "magenta", // Example: Inverting green to magenta
//     blue: "yellow", // Example: Inverting blue to yellow
//     silver: "gray", // Example: Inverting silver to gray
//     // Add more color mappings as needed
//   };

//   // Retrieve the inverted color from the color map
//   const invertedColor: string = colorMap[colorName.toLowerCase()]; // Convert color name to lowercase for case-insensitive lookup

//   // Return the inverted color, or return the original color if not found in the map
//   return invertedColor || colorName; // Return original color name if no match found
// }

// export default InvertColorByName;

// method 2
// function colorNameToRGB(colorName: string): number[] {
//   const ctx = document.createElement("canvas").getContext("2d");
//   if (ctx) {
//     ctx.fillStyle = colorName;
//     const rgb = ctx.fillStyle.match(/\d+/g)?.map(Number);
//     if (rgb && rgb.length >= 3) {
//       return [rgb[0], rgb[1], rgb[2]];
//     }
//   }
//   return [0, 0, 0]; // default to black if color name is invalid
// }

// // Helper function to convert RGB to hex
// function rgbToHex(r: number, g: number, b: number): string {
//   return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
// }

// // Function to invert color by name
// function InvertColorByName(colorName: string): string {
//   // Convert the color name to RGB
//   const [r, g, b] = colorNameToRGB(colorName.toLowerCase());

//   // Invert the RGB values
//   const invertedR = 255 - r;
//   const invertedG = 255 - g;
//   const invertedB = 255 - b;

//   // Convert the inverted RGB back to hex color
//   return rgbToHex(invertedR, invertedG, invertedB);
// }

// export default InvertColorByName;

// method 3
// Helper function to convert color name to RGB
function colorNameToRGB(colorName: string): number[] | null {
  const ctx = document.createElement("canvas").getContext("2d");
  if (ctx) {
    ctx.fillStyle = colorName;
    const rgb = ctx.fillStyle.match(/\d+/g)?.map(Number);
    if (rgb && rgb.length >= 3) {
      return [rgb[0], rgb[1], rgb[2]];
    }
  }
  return null; // return null if color name is invalid
}

// Helper function to convert RGB to hex
function rgbToHex(r: number, g: number, b: number): string {
  return (
    "#" +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

// Function to invert color by name
function InvertColorByName(colorName: string): string {
  // Convert the color name to RGB
  const rgb = colorNameToRGB(colorName.toLowerCase());

  if (rgb) {
    // If RGB is valid, invert the RGB values
    const [r, g, b] = rgb;
    const invertedR = 255 - r;
    const invertedG = 255 - g;
    const invertedB = 255 - b;

    // Convert the inverted RGB back to hex color
    return rgbToHex(invertedR, invertedG, invertedB);
  } else {
    // Return a ligh color if color is black else (e.g., black) if the color name is not recognized
    return colorName.toLowerCase() === "black" ? "#ffffff" : "#000000";
  }
}

export default InvertColorByName;
