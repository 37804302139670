import React, { memo } from 'react';
import { OldSaleCardProps } from '../../type/Auction.type';
import { useNavigate } from 'react-router-dom';
import { Images } from '../../assets';
import { Grid } from '@mui/material';

const OldSaleComponent = memo(({ data }: OldSaleCardProps) => {
  const navigate = useNavigate();
  return (
    <>
      {/*  {true && (
        <div className="SoldCardItem" key={data?.id}>
          <div className="flex flex-wrap">
            <img
              className="cardImg "
              src={
                data?.photos?.exterior?.length > 0
                  ? process.env.REACT_APP_HOST_API_KEY +
                    '/' +
                    data?.photos?.exterior[0]
                  : Images?.NoImage
              }
              alt={
                data?.year +
                ' ' +
                data?.make +
                ' ' +
                data?.model +
                (data?.title ? ' ' + data?.title : '')
              }
              loading="lazy"
              onClick={() =>
                navigate(
                  `/auctions/${
                    data?.year?.replace(/\s+/g, '-') +
                    '-' +
                    data?.make?.replace(/\s+/g, '-') +
                    '-' +
                    data?.model?.replace(/\s+/g, '-') +
                    (data?.title ? '-' + data?.title?.replace(/\s+/g, '-') : '')
                  }`,
                  {
                    state: { uuid: data?.uuid },
                  }
                )
              }
            />
            {data?.current_bid?.price && (
              <div className="soldCarLabel">
                <div className="flex flex-wrap">
                  <p className="soldCarLabelItem">
                    <span className="capitalize">{data?.auction?.status}</span>{' '}
                    for
                    {data?.current_bid?.price} CHF
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-2">
            <h1
              className="soldedTitle"
              onClick={() =>
                navigate(
                  `/auctions/${
                    data?.year?.replace(/\s+/g, '-') +
                    '-' +
                    data?.make?.replace(/\s+/g, '-') +
                    '-' +
                    data?.model?.replace(/\s+/g, '-') +
                    (data?.title ? '-' + data?.title?.replace(/\s+/g, '-') : '')
                  }`,
                  {
                    state: { uuid: data?.uuid },
                  }
                )
              }
            >
              {data?.year +
                ' ' +
                data?.make +
                ' ' +
                data?.model +
                ' ' +
                (data?.title ? ' ' + data?.title : '')}
            </h1>
          </div>
        </div>
      )} */}

      <div
        key={data?.id}
        className="relative max-w-[500px] flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl border border-gray-200 px-4 py-3 space-y-1 h-full"
      >
        <div>
          <div
            className="font-bold text-2xl cursor-pointer text-black truncate"
            onClick={() =>
              navigate(
                `/auctions/${
                  data?.year?.replace(/\s+/g, '-') +
                  '-' +
                  data?.make?.replace(/\s+/g, '-') +
                  '-' +
                  data?.model?.replace(/\s+/g, '-') +
                  (data?.title ? '-' + data?.title?.replace(/\s+/g, '-') : '')
                }`,
                {
                  state: { uuid: data?.uuid },
                }
              )
            }
          >
            {data?.year + ' ' + (data?.title ? ' ' + data?.title : '')}
          </div>
        </div>
        <div>
          <div className="relativeoverflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
            <div className="flex justify-center">
              <img
                className="w-full cursor-pointer"
                src={
                  data?.photos?.exterior?.length > 0
                    ? process.env.REACT_APP_HOST_API_KEY +
                      '/' +
                      data?.photos?.exterior[0]
                    : Images?.NoImage
                }
                alt={
                  data?.year +
                  ' ' +
                  data?.make +
                  ' ' +
                  data?.model +
                  (data?.title ? ' ' + data?.title : '')
                }
                loading="lazy"
                onClick={() =>
                  navigate(
                    `/auctions/${
                      data?.year?.replace(/\s+/g, '-') +
                      '-' +
                      data?.make?.replace(/\s+/g, '-') +
                      '-' +
                      data?.model?.replace(/\s+/g, '-') +
                      (data?.title
                        ? '-' + data?.title?.replace(/\s+/g, '-')
                        : '')
                    }`,
                    {
                      state: { uuid: data?.uuid },
                    }
                  )
                }
              />
            </div>
            <Grid container spacing={0} className="rounded-b-xl p-0 h-fu ll">
              <Grid item xs={12} className="text-center bg-yellow-300 p-0">
                <div className="font-bold text-black text-lg">
                  {data?.current_bid?.price ? data?.current_bid?.price : 0} CHF
                </div>
                <div className="text-gray-500 text-md">Sold</div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
});

export default OldSaleComponent;
