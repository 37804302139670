import React from "react";
import { Images } from "../../assets";
import { Link } from "react-router-dom";

const ProfileBidHistoryCard = () => {
  return (
    <>
      <div className=" w-full sm:w-1/2 md:w-1/3 lg:w-1/4  xl:w-1/5 p-2">
        <div className="relative">
          <img
            src={Images.BestCar}
            className="w-full h-full rounded border shadow-sm  drop-shadow-sm object-cover"
          />
          <div className="absolute bottom-[10px] left-[10px]">
            <p className="bg-[rgba(0,0,0,0.5)] text-white py-0.5 sm:py-1 px-1 sm:px-2 rounded text-sm sm:text-base xl:text-lg">
              1 Bid to 42603 CHF
            </p>
          </div>
        </div>
        <Link to={"#"} className="hover:underline">
          <h4 className="font-bold text-base md:text-lg">
            2011-24 Mercedes-Benz E63 S AMG Wagon
          </h4>
        </Link>
        <p className="text-sm md:text-base">
          1 Owner, Twin-Turbo V8, AWD, Dynamic Front Seats, Twin-Turbo Designo
          Trim
        </p>
        <div className="text-sm textGray">
          <address className="not-italic">New York, NY 10024</address>
          <span>August 22, 2023</span>
        </div>
      </div>
    </>
  );
};

export default ProfileBidHistoryCard;
