import Services from "../services";

const isUser_loged_in = () => {
  if (!Services.Auth.IsUserLogedIn()) {
    return false;
  } else {
    return true;
  }
};

const isUser_and_loged_in = () => {
  if (!isUser_loged_in()) {
    return false;
  } else if (
    Services.Storage.getCurrentRole() === "admin" ||
    Services.Storage.getCurrentRole() === "super_admin"
  ) {
    return false;
  } else {
    return true;
  }
};

const isAdmin_and_loged_in = () => {
  if (
    isUser_loged_in() &&
    (Services.Storage.getCurrentRole() === "admin" ||
      Services.Storage.getCurrentRole() === "super_admin")
  ) {
    return true;
  } else {
    return false;
  }
};

const CheckUserType = {
  isUser_and_loged_in,
  isAdmin_and_loged_in,
  isUser_loged_in,
};

export default CheckUserType;

export { isUser_and_loged_in, isAdmin_and_loged_in, isUser_loged_in };
