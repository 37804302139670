import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Services from '../../services';
import { PreLoaderComponent2 } from '../../components';

// checked
const GoogleCallBack = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Services.Auth.IsUserLogedIn() && navigate(-1);
  }, []);

  const handleGoogleCalback = (token: string) => {
    setLoading(true);
    Services.Auth.GoogleAuthCallBack(token)
      .then(async (res) => {
        setLoading(false);
        if (res.status) {
          await Services.Profile.GetProfileUsingAT(res.data.access_token)
            .then(async (response) => {
              if (response.status) {
                const storeinfo = await Services.Storage.setSession(
                  res.data.access_token,
                  response.data
                );
                if (storeinfo) {
                  Services.Storage.setUserRole('user');
                  navigate('/', { replace: true });
                  return window.location.reload();
                }
              }
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {
        setLoading(false);
        navigate('/');
        return window.location.reload();
      });
  };

  useEffect(() => {
    if (location.pathname === '/auth/google') {
      location.search && handleGoogleCalback(location.search);
    }
  }, [location]);

  return <>{loading && <PreLoaderComponent2 />}</>;
};

export default GoogleCallBack;
