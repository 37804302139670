import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
// import Backend from 'i18next-http-backend';
import { DateTime } from "luxon";
import { locizePlugin } from "locize";
import LastUsed from "locize-lastused";
import deCH from "./locales/de-CH/transaltion.json";
import en from "./locales/en/transaltion.json";
import frCH from "./locales/fr-CH/transaltion.json";
import itCh from "./locales/it-CH/transaltion.json";

// const isProduction = process.env.REACT_APP_MODE || "production";
const isProduction = process.env.REACT_APP_ENV_MODE === "prod";

// const locizeOptions = {
//   projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
//   apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
//   referenceLng: "en",
// };

const locizeOptions = {
  projectId:
    process.env.REACT_APP_LOCIZE_PROJECT_ID ||
    "7009db79-0b66-49d7-b61a-9605870f2cc1",
  apiKey:
    process.env.REACT_APP_LOCIZE_API_KEY ||
    "047a2a11-272f-4f78-bad5-a5587f11f326", // YOU should not expose your apps API key to production!!!
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG || "en",
  version: process.env.REACT_APP_LOCIZE_VERSION || "production",
};

if (!isProduction) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18n.use(LastUsed);
}

i18n
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read:https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      "de-CH": {
        translation: deCH,
      },
      "fr-CH": {
        translation: frCH,
      },
      "it-CH": {
        translation: itCh,
      },
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    saveMissing: !isProduction, // you should not use saveMissing in production
  });

// new usage
i18n?.services?.formatter?.add(
  "DATE_HUGE",
  (value: any, lng: any, options: any) => {
    return DateTime.fromJSDate(value)
      .setLocale(lng)
      .toLocaleString(DateTime.DATE_HUGE);
  }
);

export default i18n;
