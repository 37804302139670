import React, { useState, useEffect, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet, Input } from "@mui/joy";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useScreen } from "../../hooks";

const common_colors = [
  // col 1
  {
    label: "Maroon",
    color: "#800000",
  },
  {
    label: "Silver",
    color: "#B8B9B6",
  },
  {
    label: "Purple",
    color: "#663399",
  },
  {
    label: "Yellow",
    color: "#FFFF00",
  },
  // col 2
  {
    label: "Beige",
    color: "#F5F5DC",
  },
  {
    label: "Black",
    color: "#000000",
  },
  {
    label: "White",
    color: "#ffffff",
  },
  {
    label: "Green",
    color: "#008000",
  },
  // col 3
  {
    label: "Gray",
    color: "#808080",
  },
  {
    label: "Pink",
    color: "#FFC0CB",
  },
  {
    label: "Blue",
    color: "#0000FF",
  },
  {
    label: "Gold",
    color: "#FFD700",
  },
  // col 4
  {
    label: "Brown",
    color: "#563d2d",
  },
  {
    label: "Orange",
    color: "#FFA500",
  },
  {
    label: "SkyBlue",
    color: "#87CEEB",
  },
  {
    label: "Red",
    color: "#FF0000",
  },
];

interface dailymailProps {
  open: boolean;
  setIsModalOpen: any;
  colorType: string;
  color: string;
  setColor: any;
  formik: any;
}

// checked
const ColorPicker = memo(function DailyEmail({
  open,
  setIsModalOpen,
  colorType,
  color,
  setColor,
  formik,
}: dailymailProps) {
  const { t } = useTranslation();
  const screen = useScreen();
  const [clr, setClr] = useState<string>("");
  const [otherColor, setOtherColor] = useState<string>("");

  const resetForm = () => {
    setClr("");
    setOtherColor("");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // on click save button save the color
    if (setColor || otherColor) {
      setColor(clr ? clr : otherColor);
      setIsModalOpen(false);
      resetForm();
      formik?.setFieldTouched(
        colorType === "exterior" ? "exteriorcolor" : "interiorcolor",
        true
      );
      formik?.setFieldValue(
        colorType === "exterior" ? "exteriorcolor" : "interiorcolor",
        clr ? clr : otherColor
      );
      return;
    }
    return Swal.fire("error", "Please select a color");
  };

  const handleClr = async () => {
    // color should consiti of label & color code (black #000)
    if (color) {
      // fetch color label and color code and set it into local variable
      let isOtherColor = true;
      common_colors?.length > 0 &&
        common_colors?.map((item) => {
          if (item.label.toLowerCase() === color.toLowerCase()) {
            isOtherColor = false;
          }
        });
      isOtherColor ? setOtherColor(color) : setClr(color);
    }
  };

  useEffect(() => {
    handleClr();
  }, [color]);

  return (
    <>
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          hideBackdrop={true}
          onClose={() => {
            setIsModalOpen(false);
          }}
          sx={Utils.Theme.JoyModalStyle}
        >
          <Sheet
             sx={Utils.Theme.JoyModalSheetStyle}
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
          >
            <ModalClose
              variant="outlined"
              sx={Utils.Theme.JoyModalCloseStyle}
            />
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="my-2 sm:my-3 md:my-4 w-full">
                <div className="flex flex-row  w-full">
                  <div className="w-full">
                    <h3 className="head leading-6 font-bold text-gray-900 text-center pb-2 sm:pb-4 md:pb-6">
                      {t(
                        colorType === "exterior"
                          ? "exterior_heading"
                          : "interior_heading"
                      )}
                    </h3>
                    <div className="w-full ">
                      <div className="flex flex-col sm:flex-row gap-y-1 justify-center items-center sm:gap-y-0 gap-x-3 py-4">
                        <div className="py-2 sm:pt-3 md:pt-5 pb-1 sm:pb-2 md:pb-3">
                          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-3 md:gap-4 grid-flow-row items-center	max-h-[50vh] overflow-y-auto overflow-x-hidden">
                            {common_colors?.length > 0 &&
                              common_colors.map((item, index) => (
                                <ThemeProvider
                                  theme={Utils.Theme.GoogleButtonTheme}
                                >
                                  <Button
                                    onClick={() => {
                                      setClr(item.label);
                                      setOtherColor("");
                                    }}
                                    fullWidth
                                  >
                                    <span
                                      key={index}
                                      className={`m-auto min-w-max px-6 py-2 rounded cursor-pointer border-2 p1 ${item.label === clr ? `shadow-[inset 0 2px 4px 0 rgb(0 0 0 / 0.05),  0 25px 50px -12px rgb(0 0 0 / 0.25)]  drop-shadow-2xl ${item?.label === "Blue" ? "border-[light-color]" : "border-[--main-color]"}` : `shadow-sm drop-shadow-sm border-transparent`}`}
                                      style={{
                                        background: item.label,
                                        color:
                                          item.label === "Black" ||
                                          item.label === "Maroon" ||
                                          item.label === "Purple" ||
                                          item.label === "Red" ||
                                          item.label === "Blue" ||
                                          item.label === "Brown"
                                            ? "white"
                                            : "black",
                                      }}
                                    >
                                      {item.label}
                                    </span>
                                  </Button>
                                </ThemeProvider>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="InputFieldSetting">
                        <div className="sm:pt-3 md:pt-5 pb-1 sm:pb-2 md:pb-3 flex flex-col sm:flex-row justify-between items-center gap-1 ">
                          <Input
                            name="reserve_price"
                            variant="outlined"
                            fullWidth
                            required={!clr}
                            type="text"
                            placeholder="Enter color name"
                            value={otherColor}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(
                                Utils.RegExp.nonTextPattern,
                                ""
                              );
                              setOtherColor(e.target.value);
                              setClr("");
                            }}
                            sx={Utils.Theme.JoyGrayShadeInputStyle2}
                            startDecorator={
                              <p className="text-[--main-color] font-medium tracking-wide p1">
                                {screen?.width > 640
                                  ? t("sopqh3ocv")
                                  : t("sopqh3ocb")}
                              </p>
                            }
                            slotProps={{
                              input: {
                                min: 3,
                                max: 30,
                              },
                            }}
                          />
                          <ThemeProvider theme={Utils.Theme.GoogleButtonTheme}>
                            <Button
                              sx={{
                                width: screen?.width > 640 ? "50px" : "100%",
                                height: "100%",
                                padding: 0,
                              }}
                              fullWidth={screen?.width <= 640}
                            >
                              <span
                                className={`px-6 py-4 rounded cursor-pointer border-2 shadow-[inset 0 2px 4px 0 rgb(0 0 0 / 0.05), 0 25px 50px -12px rgb(0 0 0 / 0.25)]  drop-shadow-2xl shadow-sm border-transparent w-full sm:w-fit`}
                                style={{
                                  background: otherColor,
                                  color:
                                    otherColor === "Black" ||
                                    otherColor === "Maroon" ||
                                    otherColor === "Purple" ||
                                    otherColor === "Red" ||
                                    otherColor === "Blue" ||
                                    otherColor === "Brown"
                                      ? "white"
                                      : "black",
                                }}
                              ></span>
                            </Button>
                          </ThemeProvider>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                        <Button
                          variant="contained"
                          type="submit"
                          className=""
                          fullWidth
                          sx={{
                            ":disabled": {
                              backgroundColor: "var(--light-color)",
                              color: "var(--dark-text-color)",
                              boxShadow:
                                "inset 0 2px 4px 0 rgb(0 0 0 / 0.05), 0 2px 4px 0 rgb(0 0 0 / 0.05)",
                            },
                          }}
                          disabled={!clr && !otherColor}
                        >
                          {t("save_color_butn_txt")}
                        </Button>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Sheet>
        </Modal>
      </div>
    </>
  );
});

export default ColorPicker;
