import { useEffect, useState, useRef, memo, useLayoutEffect } from "react";
import { ThemeProvider, Button, Box, Alert, ButtonGroup } from "@mui/material";
import Utils from "../../utils";
import { Textarea, Input } from "@mui/joy";
import Autocomplete from "@mui/joy/Autocomplete";
import { WarningAlert } from "../../components";
import { useTranslation } from "react-i18next";
import Resizer from "react-image-file-resizer";

type Country = {
  label: string;
  value: string;
};

interface SecondStepProps {
  props: any;
  data: any;
  setData: any;
  formik: any;
  // countries: Country[]

}

type fileInput = null | any;

// checked
const CarInfoSecondStep = memo(function CarInfoSecondStep({
  props,
  data,
  setData,
  formik,
}: SecondStepProps) {
  const { t } = useTranslation();
  const fileInput = useRef<fileInput>(null);
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const [selectedMedia, setSelectedMedia] = useState("exterior");
  const [submit, setSubmit] = useState(false);

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const touchErrorsFields = () => {
    if (!firstTimeRender && props?.handleNext) {
      formik?.setFieldTouched("title_country", true);
      data.title_country === "us" &&
        formik?.setFieldTouched("title_state", true);
      data.title_country === "ca" &&
        formik?.setFieldTouched("title_province", true);
      data.owner_has_title && formik?.setFieldTouched("title_owner", true);
      formik?.setFieldTouched("title_status", true);
      data.has_reserve_price && formik?.setFieldTouched("reserve_price", true);
      formik?.setFieldTouched("referral", true);
      formik?.setFieldTouched("car_photos", true);
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => touchErrorsFields(), [props?.handleNext]);

  const hasPhotoError = () => {
    let err = "",
      carPhotos = [...data?.car_images],
      carPhotoPreviews = [...data?.photo_previews];
    if (!carPhotos || carPhotos?.length === 0) {
      err = t("submit_page.validation.carPhotos.required_message");
    } else if (carPhotos?.length < 6) {
      err = t("submit_page.validation.carPhotos.min_length_message");
    } else if (carPhotos?.length > 20) {
      err = t("submit_page.validation.carPhotos.max_length_message");
    } else {
      let exterirorCount = 0,
        interiorCount = 0;
      carPhotos?.map((item: any) => {
        item.photoType === "exterior" && exterirorCount++;
        item.photoType === "interior" && interiorCount++;
      });
      exterirorCount === 0
        ? (err = t("submit_page.validation.carPhotos.exterior"))
        : interiorCount === 0
          ? (err = t("submit_page.validation.carPhotos.interior"))
          : (err = "");
      if (!err) {
        carPhotoPreviews?.length > 0 &&
          carPhotoPreviews?.map((img) => {
            if (img.error) {
              err = t("submit_page.validation.carPhotos.error");
            }
          });
      }
    }
    formik?.setFieldError("car_photos", err);
    return err ? true : false;
  };

  const checkErrorsExist = () => {
    if (formik?.errors && props?.handleNext) {
      setFirstTimeRender(false);
      if (
        !data.title_country ||
        (data.title_country === "us" &&
          (!data.title_state || formik?.errors?.title_state)) ||
        (data.title_country === "ca" &&
          (!data.title_province || formik?.errors?.title_province)) ||
        (data.owner_has_title &&
          (!data.title_owner || formik?.errors?.title_owner)) ||
        formik?.errors?.title_status ||
        formik?.errors?.referral ||
        hasPhotoError()
      ) {
        !firstTimeRender &&
          props?.setErrMessage(t("submit_page.validation.error_message"));
        props?.handleNext && props?.setHandleNext(false);
      } else {
        props?.setErrMessage("");
        props?.setHandleNext(false);
        setSubmit(true);
      }
      return;
    }
  };

  useLayoutEffect(() => {
    if (submit && !formik?.isSubmitting) {
      setSubmit(false);
      formik?.handleSubmit();
    }
  }, [submit, formik?.handleSubmit, formik?.isSubmitting]);

  useEffect(() => {
    setTimeout(() => checkErrorsExist(), 500);
  }, [formik?.errors, props?.handleNext]);

  function getImageSize(url: any) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;

      img.onload = () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      };

      img.onerror = () => {
        reject(
          new Error(t("submit_page.validation.carPhotos.load_image_error"))
        );
      };
    });
  }

  const handleImagePreviews = async (image: any, index: number) => {
    let imgObj: any = {
      index,
      imgPreview: URL?.createObjectURL(image),
      error: null,
    };
    if (
      image.type === "image/jpg" ||
      image.type === "image/png" ||
      image.type === "image/jpg" ||
      image.type === "image/jpeg"
    ) {
      const imgSize = image.size / (1024 * 1024);
      if (imgSize <= 2) {
        await getImageSize(imgObj?.imgPreview).then(
          ({ width, height }: any) => {
            if (width < 1024 || height < 768) {
              imgObj.error = t(
                "submit_page.validation.carPhotos.min_image_size"
              );
            } else if (width > 3072 || height > 2304) {
              imgObj.error = t(
                "submit_page.validation.carPhotos.max_image_size2"
              );
            } else if (width < height) {
              imgObj.error = t(
                "submit_page.validation.carPhotos.img_landscape_message"
              );
            } else if (width / height < 1.25 || width / height > 1.75) {
              imgObj.error = t(
                "submit_page.validation.carPhotos.image_ratio_message"
              );
            }
            // const ratio = width / height;
            // const newWidth = 450;
            // const newHeight = ratio * newWidth;
            // // image resizing
            // // Call function to resize the image
            // const resizedImageSrc: any = resizeImage(image, 450, 300); // Resize to 450x300 (you can adjust dimensions)
            // resizedImageSrc && setImageSrc(resizedImageSrc);
          }
        );
      } else {
        imgObj.error = t("submit_page.validation.carPhotos.max_image_size");
      }
    } else {
      imgObj.error = t(
        "submit_page.validation.carPhotos.unsupported_file_format"
      );
    }

    return imgObj;
  };

  const resizeFile = (file: any, maxWidth?: number, maxHeight?: number) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth ? maxWidth : 500,
        maxHeight ? maxHeight : 332,
        "webp",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        // "base64"
        "file"
      );
    });

  const handleImages = async (e: any) => {
    e.preventDefault();
    let imageFiles: any[] = [];
    let imagesPreviews: string[] = [];
    let files: any[] = [];
    e.target?.files?.length > 0 && (files = e.target.files);
    imageFiles = data.car_images;
    imagesPreviews = data.photo_previews;
    let resizedFiles: any[] = [...imageFiles];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (files[i]) {
          const filePreview = await handleImagePreviews(file, i);
          imagesPreviews.push(filePreview);
          let resizedImage: any = await resizeFile(file, 768, 512);
          if (resizedImage) {
            resizedImage.photoType = selectedMedia;
            resizedFiles.push(resizedImage);
          }
        }
      }
      setData((old: any) => {
        return {
          ...old,
          car_images: resizedFiles,
          photo_previews: [...imagesPreviews],
        };
      });
      formik?.setFieldValue(
        "car_photos",
        t("submit_page.validation.carPhotos.car_photo_error")
      );
      e.target.files = null;
    }
  };

  const handleDeleteImage = (e: any, index: number) => {
    let imageFiles: any[] = [];
    let imagesPreviews: string[] = [];

    for (let i = 0; i < data?.car_images?.length; i++) {
      if (index != i) {
        imageFiles.push(data.car_images[i]);
        imagesPreviews.push(data.photo_previews[i]);
      }
    }

    setData((old: any) => {
      return { ...old, car_images: imageFiles, photo_previews: imagesPreviews };
    });

    if (imagesPreviews?.length === 0) {
      formik?.setFieldValue("car_photos", null);
      formik?.setFieldError(
        "car_photos",
        t("submit_page.validation.carPhotos.required_message")
      );
      formik?.setFieldTouched("car_photos", true, false);
    }
    return;
  };

  return (
    <div className={props?.page === 1 ? "hidden" : ""}>
      {props?.errMessage && (
        <div className="py-4 w-full p1">
          <Alert severity="error">{props?.errMessage}</Alert>
        </div>
      )}
      <div className="CarStep">
        <div className="flex flex-row gap-2">
          <div className="leftStepStyle"></div>
          <h2 className="head font-bold ">
            {t("submit_page.car_second_step_component.title")}
          </h2>
        </div>
        <div className="pt-5 flex flex-col">
          <label className="p1 px-1" htmlFor="title_country">
            {t("submit_page.car_second_step_component.car_title_label")}
          </label>
          <p className="p2 px-1">
            {t("submit_page.car_second_step_component.note_description")}
          </p>
          <div className="flex flex-row gap-4 mt-3" id="title_country">
            {/* <Box>
              <ThemeProvider
                theme={
                  data?.title_country === "us"
                    ? Utils.Theme.ButtonActiveTheme
                    : Utils.Theme.TransParentButton
                }
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setData((old: any) => {
                      return { ...old, title_country: "us" };
                    });
                    formik?.setFieldTouched("title_country", true);
                    formik?.setFieldValue("title_country", "us");
                  }}
                >
                  {t("submit_page.car_second_step_component.us_country_text")}
                </Button>
              </ThemeProvider>
            </Box>
            <Box>
              <ThemeProvider
                theme={
                  data?.title_country === "ca"
                    ? Utils.Theme.ButtonActiveTheme
                    : Utils.Theme.TransParentButton
                }
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setData((old: any) => {
                      return { ...old, title_country: "ca" };
                    });
                    formik?.setFieldTouched("title_country", true);
                    formik?.setFieldValue("title_country", "ca");
                  }}
                >
                  {t("submit_page.car_second_step_component.ca_country_text")}
                </Button>
              </ThemeProvider>
            </Box> */}
            <Autocomplete
              name="title_country"
              disableClearable={true}
              options={props.countries}
              required={true}
              placeholder="Select Title Country"
              onChange={(e, newValue: Country) => {
                setData((old: any) => {
                  return { ...old, title_country: newValue?.value || "" };
                });
                formik?.setFieldTouched("title_country", true, false);
                formik?.setFieldValue("title_country", newValue?.value || "");
              }}
              sx={
                formik?.errors?.country && formik?.touched?.country
                  ? Utils.Theme.JoyInputError
                  : Utils.Theme.JoyGrayShadeInputStyle2
              }
              onBlur={formik?.handleBlur}
            />
          </div>
          <WarningAlert
            message={
              formik?.touched?.title_country
                ? formik?.errors?.title_country
                : ""
            }
          />
          {data?.title_country === "ca" && (
            <div className="pt-5 pb-3">
              <label className="p1" htmlFor="title_province">
                {t("submit_page.car_second_step_component.province_label")}
              </label>
              <Input
                name="title_province"
                value={formik?.values?.title_province}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(
                    Utils.RegExp.nonTextPattern,
                    ""
                  );
                  formik?.handleChange(e);
                  setData((old: any) => {
                    return { ...old, title_province: e.target.value };
                  });
                }}
                sx={
                  formik?.errors?.title_province &&
                  formik?.touched.title_province
                    ? Utils.Theme.JoyInputError
                    : Utils.Theme.JoyGrayShadeInputStyle2
                }
                onBlur={formik?.handleBlur}
              />
              <WarningAlert
                message={
                  formik?.touched?.title_province
                    ? formik?.errors?.title_province
                    : ""
                }
              />
            </div>
          )}
          {data?.title_country === "us" && (
            <div className="pt-5 pb-3">
              <label className="p1" htmlFor="title_state">
                {t("submit_page.car_second_step_component.state_label")}
              </label>
              <Autocomplete
                name="title_state"
                disableClearable={true}
                options={states}
                required={true}
                placeholder={t(
                  "submit_page.car_second_step_component.state_placeholder"
                )}
                value={data.title_state}
                onChange={(e: any, newValue: any) => {
                  setData((old: any) => {
                    return { ...old, title_state: newValue };
                  });
                  formik?.setFieldTouched("title_state", true, false);
                  formik?.setFieldValue("title_state", newValue);
                }}
                sx={
                  formik?.errors?.title_state && formik?.touched?.title_state
                    ? Utils.Theme.JoyInputError
                    : Utils.Theme.JoyGrayShadeInputStyle2
                }
                onBlur={formik?.handleBlur}
              />
              <WarningAlert
                message={
                  formik?.touched?.title_state
                    ? formik?.errors?.title_state
                    : ""
                }
              />
            </div>
          )}
        </div>
        <div className="pt-5 flex flex-col">
          <label className="p1 px-1" htmlFor="vehicleTitle">
            {t(
              "submit_page.car_second_step_component.vehicle_title_name_label"
            )}
          </label>
          <div className="flex flex-row gap-4 pt-3" id="vehicleTitle">
            <Box>
              <ThemeProvider
                theme={
                  !data?.owner_has_title
                    ? Utils.Theme.ButtonActiveTheme
                    : Utils.Theme.TransParentButton
                }
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setData((old: any) => {
                      return { ...old, owner_has_title: false };
                    });
                  }}
                >
                  {t("submit_page.car_second_step_component.yes")}
                </Button>
              </ThemeProvider>
            </Box>
            <Box>
              <ThemeProvider
                theme={
                  data?.owner_has_title
                    ? Utils.Theme.ButtonActiveTheme
                    : Utils.Theme.TransParentButton
                }
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setData((old: any) => {
                      return { ...old, owner_has_title: true };
                    });
                  }}
                >
                  {t("submit_page.car_second_step_component.no")}
                </Button>
              </ThemeProvider>
            </Box>
          </div>
          {data?.owner_has_title && (
            <div className="pt-5 pb-3">
              <div className="pb-2">
                <p className="p-3 p1 bg-[var(--mid-gray-color)] rounded border shadow-sm">
                  {t(
                    "submit_page.car_second_step_component.vehicle_title_description"
                  )}
                </p>
              </div>
              <label className="pb-1 p1" htmlFor="title_owner">
                {t("submit_page.car_second_step_component.title_owner_label")}
              </label>
              <div className="InputFieldSetting">
                <Textarea
                  minRows={3}
                  name="title_owner"
                  value={formik?.values?.title_owner}
                  onChange={(e) => {
                    formik?.handleChange(e);
                    setData((old: any) => {
                      return { ...old, title_owner: e.target.value };
                    });
                  }}
                  sx={
                    formik?.errors?.title_owner && formik?.touched?.title_owner
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                  onBlur={formik?.handleBlur}
                />
              </div>
              <WarningAlert
                message={
                  formik?.touched?.title_owner
                    ? formik?.errors?.title_owner
                    : ""
                }
              />
            </div>
          )}
        </div>
        <div className=" flex flex-col pt-3">
          <div className="flex flex-row gap-4">
            <div className="flex-col w-full space-y-1">
              <label className="p1 px-1" htmlFor="title_owner">
                {t("submit_page.car_second_step_component.title_status_label")}
              </label>
              <Autocomplete
                disableClearable={true}
                name="title_status"
                options={[
                  "clean",
                  "salvage",
                  "rebuilt",
                  "not actual mileage",
                  "manufacturer buyback",
                ]}
                placeholder={t(
                  "submit_page.car_second_step_component.title_status_placholder"
                )}
                onChange={(e: any, value) => {
                  setData((old: any) => {
                    return { ...old, title_status: value };
                  });
                  formik?.setFieldTouched("title_status", true, false);
                  formik?.setFieldValue("title_status", value);
                }}
                value={data?.title_status}
                sx={
                  formik?.errors?.title_status && formik?.touched?.title_status
                    ? Utils.Theme.JoyInputError
                    : Utils.Theme.JoyGrayShadeInputStyle2
                }
                onBlur={formik?.handleBlur}
              />
              <WarningAlert
                message={
                  formik?.touched?.title_status
                    ? formik?.errors?.title_status
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="CarStep">
        <div className="flex flex-row gap-2">
          <div className="leftStepStyle"></div>
          <h2 className="head font-bold ">
            {t("submit_page.car_second_step_component.reserve_price_label")}
          </h2>
        </div>
        <div className="flex-col w-full space-y-1 pt-8">
          <div className="rounded p-5 ">
            <h2 className="p1">
              {t(
                "submit_page.car_second_step_component.reserve_price_description"
              )}

              <a className="text-[var(--main-color)]" href="/about#sellingacar">
                {" "}
                https://auto-bids.ch/reserve
              </a>
            </h2>
          </div>
        </div>
        <div className="pt-5 flex flex-col">
          <label className="p1 px-1" htmlFor="">
            {t("submit_page.car_second_step_component.has_reserve_price")}
          </label>
          <div className="flex flex-row gap-4 mt-3" id="reserve_price">
            <Box>
              <ThemeProvider
                theme={
                  data?.has_reserve_price
                    ? Utils.Theme.ButtonActiveTheme
                    : Utils.Theme.TransParentButton
                }
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setData((old: any) => {
                      return { ...old, has_reserve_price: true };
                    });
                  }}
                >
                  {t("submit_page.car_second_step_component.yes")}
                </Button>
              </ThemeProvider>
            </Box>
            <Box>
              <ThemeProvider
                theme={
                  !data?.has_reserve_price
                    ? Utils.Theme.ButtonActiveTheme
                    : Utils.Theme.TransParentButton
                }
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    setData((old: any) => {
                      return { ...old, has_reserve_price: false };
                    });
                  }}
                >
                  {t("submit_page.car_second_step_component.no")}
                </Button>
              </ThemeProvider>
            </Box>
          </div>
          {data.has_reserve_price && (
            <div className="py-2">
              <div className="InputFieldSetting">
                <Input
                  name="reserve_price"
                  variant="outlined"
                  required={true}
                  type="text"
                  value={data.reserve_price}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                    formik?.handleChange(e);
                    setData((old: any) => {
                      return { ...old, reserve_price: e.target.value };
                    });
                  }}
                  sx={
                    formik?.errors?.reserve_price &&
                    formik?.touched?.reserve_price
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                  startDecorator="CHF "
                  onBlur={formik?.handleBlur}
                  slotProps={{
                    input: {
                      min: 0,
                    },
                  }}
                />
              </div>
              <WarningAlert
                message={
                  formik?.touched?.reserve_price
                    ? formik?.errors?.reserve_price
                    : ""
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="CarStep">
        <div className="flex flex-row gap-2">
          <div className="leftStepStyle"></div>
          <h2 className="head font-bold ">
            {t("submit_page.car_second_step_component.photos_label")}
          </h2>
        </div>
        <p className="p1 px-1 pt-8">
          {t("submit_page.car_second_step_component.photos_description")}
        </p>
        <div className="flex flex-col items-center justify-center w-full pt-5">
          <div className="w-full ">
            <div className="py-6 flex justify-center">
              <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    type="button"
                    variant={
                      selectedMedia === "exterior" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setSelectedMedia("exterior");
                    }}
                  >
                    {t(
                      "submit_page.car_second_step_component.exteriro_butn_text"
                    )}
                  </Button>
                  <Button
                    type="button"
                    variant={
                      selectedMedia === "interior" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setSelectedMedia("interior");
                    }}
                  >
                    {t(
                      "submit_page.car_second_step_component.interior_butn_text"
                    )}
                  </Button>
                </ButtonGroup>
              </ThemeProvider>
            </div>
            <div
              onClick={() => fileInput?.current?.click()}
              className="bg-white w-full cursor-pointer border-2 border-dotted shadow-sm rounded"
            >
              <input
                name="photos"
                type="file"
                className="hidden"
                multiple
                ref={fileInput}
                onChange={(e) => handleImages(e)}
                accept="image/png, image/jpeg, image/webp"
              />
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  aria-hidden="true"
                  className="w-10 h-10 mb-3 text-[var(--main-color)]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="pb-2 font-semibold p1 text-gray-500 dark:text-gray-400">
                  {t("submit_page.car_second_step_component.upload_text")}
                </p>
                <p className="p2 text-gray-500 dark:text-gray-400">
                  WEBP, PNG, JPG or JPEG (MAX. 1024x768)
                </p>
              </div>
            </div>
            <div className="w-ful h-full">
              <div className="flex justify-start gap-1.5 flex-wrap pt-5">
                {data?.photo_previews &&
                  data?.photo_previews?.length > 0 &&
                  data?.photo_previews?.map((img: any, index: number) => (
                    <div
                      key={index}
                      className={`relative shadow-sm h-[125px] w-[200px] `}
                    >
                      <img
                        src={img?.imgPreview}
                        alt="car images preview"
                        className={`rounded border shadow-sm w-full h-full ${
                          img?.error ? "blur-[1px]" : ""
                        } `}
                        loading="lazy"
                      />
                      <div className="image-item__btn-wrapper ">
                        <button
                          type="button"
                          className="absolute text-white z-50	 bg-[rgba(0,0,0,0.5)]  px-1.5 py-1 rounded-full flex justify-center items-center align-middle align top-1.5 right-1.5 cursor-pointer border shadow-sm border-black p1"
                          onClick={(e) => handleDeleteImage(e, index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="1em"
                            fill="currentColor"
                            stroke="currentColor"
                            viewBox="0 0 384 512"
                          >
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                          </svg>
                        </button>
                      </div>
                      {img?.error && (
                        <div
                          className={`absolute w-full h-full top-0 bottom-0  bg-[rgba(0,0,0,0.3)] flex justify-center items-center p-1 text-[var(--error-color)] flex-wrap p1`}
                        >
                          {img?.error}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <WarningAlert
          message={
            formik?.touched?.car_photos ? formik?.errors?.car_photos : ""
          }
        />
        <p className="pt-2 p1">
          {t("submit_page.car_second_step_component.photo_guide")},{" "}
          <a
            className="text-[var(--main-color)]"
            href="/about#sellingacar"
            target="_self"
          >
            {t("submit_page.car_second_step_component.read_guide_link_text")}
          </a>
        </p>
      </div>
      <div className="CarStep">
        <div className="flex flex-row gap-2">
          <div className="leftStepStyle"></div>
          <h2 className="head font-bold ">
            {t("submit_page.car_second_step_component.referral_heading")}
          </h2>
        </div>
        <h2 className="p1 px-1 pt-8">
          {t("submit_page.car_second_step_component.refferal_username")}{" "}
        </h2>
        <div className="flex flex-col">
          <div className="flex flex-row gap-4 pt-3">
            <div className="flex-col w-full space-y-1">
              <Input
                name="referral"
                variant="outlined"
                value={data.referral}
                onChange={(e: any) => {
                  e.target.value = e.target.value
                    .replace(Utils.RegExp.isSymbolAndSpecialCharExist, "")
                    .trim();
                  formik?.handleChange(e);
                  setData((old: any) => {
                    return {
                      ...old,
                      referral: e.target.value,
                    };
                  });
                }}
                sx={
                  formik?.errors?.referral && formik?.touched?.referral
                    ? Utils.Theme.JoyInputError
                    : Utils.Theme.JoyGrayShadeInputStyle2
                }
                onBlur={formik?.handleBlur}
                slotProps={{
                  input: {
                    maxLength: 30,
                  },
                }}
              />
              <WarningAlert
                message={
                  formik?.touched?.referral ? formik?.errors?.referral : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CarInfoSecondStep;
