import API from "../config/api.config";

const GetDashboardData = () => {
  return API.get(`/api/admin/dashboard`).then((res: any) => {
    return res.data;
  });
};

const Dashboard = {
    GetDashboardData
};

export default Dashboard;
