
import { useEffect } from "react";
import firebase from "firebase/compat/app";
// import "firebase/messaging";
import { getToken, onMessage } from "firebase/messaging";
// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseApp } from "firebase/app";
import { onBackgroundMessage, getMessaging } from "firebase/messaging/sw";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const Config = {
//   apiKey: "AIzaSyD19klpXrgnlEhwstXrL5K5Wwq4wxgButk",
//   authDomain: "carbids-df3fa.firebaseapp.com",
//   projectId: "carbids-df3fa",
//   storageBucket: "carbids-df3fa.appspot.com",
//   messagingSenderId: "493789860199",
//   appId: "1:493789860199:web:c88e7a761e6160b6531fc2",
//   measurementId: "G-568226PKXH",
// };

// const Config = {
//   apiKey: "AIzaSyDgBIY_nx0J2J8D9EPIRKhS2aJKqJ0OBlE",
//   authDomain: "car-and-bids.firebaseapp.com",
//   projectId: "car-and-bids",
//   storageBucket: "car-and-bids.appspot.com",
//   messagingSenderId: "450350191638",
//   appId: "1:450350191638:web:f3917b8e3a64624fa75137",
//   measurementId: "G-DM6684J35F",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDgBIY_nx0J2J8D9EPIRKhS2aJKqJ0OBlE",
  authDomain: "car-and-bids.firebaseapp.com",
  projectId: "car-and-bids",
  storageBucket: "car-and-bids.appspot.com",
  messagingSenderId: "450350191638",
  appId: "1:450350191638:web:f3917b8e3a64624fa75137",
  measurementId: "G-DM6684J35F",
};

// const app:FirebaseApp = initializeApp(firebaseConfig);

// const messaging = getMessaging(app);

const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

// const FireBaseToken = async () => {
//   // Initialize Firebase

//   // Initialize Firebase Cloud Messaging and get a reference to the service
//   const app:FirebaseApp = initializeApp(firebaseConfig);
//   const messaging = getMessaging(app);
//   let message_payload: any = null;

//   onMessage(messaging, (payload) => {
//     console.log("Message received. ", payload);
//     // message_payload = payload;
//     // ...
//   });

//   onBackgroundMessage(messaging, (payload) => {
//     console.log('[firebase-messaging-sw.js] Received background message ', payload);
//     // Customize notification here
//     const notificationTitle = 'Background Message Title';
//     const notificationOptions = {
//       body: 'Background Message body.',
//       icon: '/firebase-logo.png'
//     };

//     // self.registration.showNotification(notificationTitle,
//     //   notificationOptions);
//   });

//   // onBackgroundMessage(messaging, (payload) => {
//   //   console.log('[firebase-messaging-sw.js] Received background message ', payload);
//   // //   // Customize notification here
//   // //   const notificationTitle = 'Background Message Title';
//   // //   const notificationOptions = {
//   // //     body: 'Background Message body.',
//   // //     // icon: 'firebase-logo.png'
//   // //   };

//   // //   // self?.registration?.showNotification(notificationTitle,
//   // //   //   notificationOptions);

//   // //   // console.log("self", window.self)
//   // });

//   // console.log("Requesting permission...");
//   return await Notification.requestPermission()
//     .then(async (permission) => {
//       if (permission === "granted") {
//         // genrate toke
//         const token = await getToken(messaging, {
//           vapidKey:
//             // "BBH7OwpROsEVBuBR9F2MInKjJo8zyXKrBAIqaMWhTdhcU3I6cg8bRq6X-7kxJ0dc-dAL6xF3FI7k2WbHZAsMZ04",
//             "BO_PoR67Ws_WWxnKGYJvZLgKeXuaDxVVOI7Xz6oB08Ih05c56ABu2Q1LcALOkyLD-Ih62ic5InepGifBILSJJBA",
//         });
//         return { token: token, payload: message_payload };
//       }
//     })
//     .catch((error) => {
//       return { error: error };
//     });

//   // return messaging;

//   return getToken(messaging, {vapidKey: "BO_PoR67Ws_WWxnKGYJvZLgKeXuaDxVVOI7Xz6oB08Ih05c56ABu2Q1LcALOkyLD-Ih62ic5InepGifBILSJJBA"}).then((currentToken) => {
//     // return console.log('dasdas')
//     if (currentToken) {
//       console.log("token", currentToken)
//       // Send the token to your server and update the UI if necessary
//       // ...
//     } else {
//       // Show permission request UI
//       console.log('No registration token available. Request permission to generate one.');
//       // ...
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // ...
//   });;

// };

export default firebaseApp;
// export type { FirebaseApp , messaging };
