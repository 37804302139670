import API from "../config/api.config";

const CreateAuction = (data: any) => {
  return API.post(`/api/admin/auctions/create`, data).then((res: any) => {
    return res.data;
  });
};

const ChangeAuctionStatus = (data: any) => {
  return API.put(`/api/admin/auctions/status/change`, data).then((res: any) => {
    return res.data;
  });
};

const GetAuctions = (offset:number, limit:number, search?: string | null, sort?: string | null, filter?: string|null, status?: string|null)  => {
  return API.get(`/api/admin/auctions`, {params: {offset, limit, search,sort, filter, status}}).then((res: any) => {
    return res.data;
  });
};

const GetApprovedListingForDropdown = () => {
  return API.get(`/api/admin/listings/approved/dropdown`).then((res: any) => {
    return res.data;
  });
};

const CountAuctionView = (listing_id:number) => {
  return API.post(`/api/user/auctions/${listing_id}/views`).then((res: any) => {
    return res.data;
  });
};

const Auction = {
  CountAuctionView,
  CreateAuction,
  GetAuctions,
  GetApprovedListingForDropdown,
  ChangeAuctionStatus
};

export default Auction;
