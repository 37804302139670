import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { NavComponent, FooterComponent, ProfileSidebar } from "../components";
import CheckUserType from "../utils/checkUserType.utils";
import { useTranslation } from "react-i18next";

// checked
const User = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isUserAndLogedIn = CheckUserType.isUser_and_loged_in();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !isUserAndLogedIn && navigate(-1);
  }, [isUserAndLogedIn]);

  return (
    <>
      <div className="flex flex-col h-full w-full min-h-screen mx-auto xl:container">
        <NavComponent />
        <div className="my-8 md:my-12 lg:my-16 containerSpacing">
          <div className="my-12 lg:my-16 xl:my-20 flex justify-start items-start gap-0">
            <ProfileSidebar />
            <Outlet />
          </div>
        </div>
        <FooterComponent />
      </div>
    </>
  );
};

export default User;
