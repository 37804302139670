import { ApiConfig } from "../config";

const AddToWatchList = (data: any) => {
  return ApiConfig.post("/api/user/watch-list", data).then((res: any) => {
    return res.data;
  });
};

const GetWatchLists = () => {
  return ApiConfig.get("/api/user/watch-list").then((res: any) => {
    return res.data;
  });
};

const GetWatchList = (fetch: string, offset: number, limit: number, sort:string) => {
  return ApiConfig.get(
    `/api/user/watch-list?fetch=${fetch}&offset=${offset}&limit=${limit}&sort=${sort}`
  ).then((res: any) => {
    return res.data;
  });
};

const DeleteFromWatchList = (id: number) => {
  return ApiConfig.delete(`/api/user/watch-list/${id}`).then((res: any) => {
    return res.data;
  });
};

const WatchList = {
  GetWatchLists,
  AddToWatchList,
  DeleteFromWatchList,
  GetWatchList
};

export default WatchList;
