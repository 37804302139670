// import & export services
import Crypto from "./Crypto.service";
import Storage from "./Storage.service";
import Auth from "./Auth.service";
import Profile from "./Profile.service";
import Faqs from "./Faqs.service";
import Settings from "./Settings.service";
import User from "./User.service";
import Listing from "./Listing.service";
import Comment from "./Comment.service";
import QA from "./QA.service";
import Bid from "./Bid.service";
import Reviewer from "./Reviewer.service";
import Auction from "./Auction.service";
import Admin from "./Admin.service";
import DeviceToken from "./DeviceToken.service";
import Notification from "./Notification.service";
import WatchList from "./WatchList.service";
import Search from "./Search.service"
import Schedule from "./Schedule.service";
import Dashboard from "./Dashboard.service";
import System from "./System.service";

const Services = {
  Dashboard,
  System,
  Schedule,
  Search,
  WatchList,
  Notification,
  DeviceToken,
  Admin,
  Auction,
  Reviewer,
  QA,
  Bid,
  Comment,
  Listing,
  Crypto,
  Storage,
  Auth,
  Profile,
  Faqs,
  Settings,
  User,
};

export default Services;
