import { useState, useEffect } from "react";
import {
  Paper,
  Link as MuiLink,
  Breadcrumbs,
  Box,
  Button,
  ThemeProvider,
} from "@mui/material";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import Utils from "../../utils";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";

// checked
const FaqDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [faq, setFaq] = useState<any>({});

  useEffect(() => {
    if (location.state && location.state.faq) {
      setFaq(location.state.faq);
    } else {
      navigate(-1);
    }
  }, [location]);

  return (
    <>
      <PageComponents.MetaComponent title={t("faq_detail_page.bmx3rhy7u")} />
      <div className=" ">
        <div className=" ">
          {/* list items */}
          <div className="flex justify-center sm:justify-between items-center py-6 flex-wrap gap-2">
            <div className="flex flex-col justify-center items-center sm:items-start gap-2  ">
              <h3 className="head leading-6 font-bold text-gray-900">
                {t("faq_detail_page.qwsdib09v")}
              </h3>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/dashboard" className="hover:underline">
                    {t("faq_detail_page.7xm726m3u")}
                  </Link>
                  <Link to={"/faqs"} className="hover:underline">
                  {t("faqs_page.ms4yoi438")}
                </Link>
                  <Link
                    to={location.pathname}
                    state={{ faq: faq }}
                    className="hover:underline"
                  >
                    {t("faq_detail_page.qwsdib09v")}
                  </Link>
                </Breadcrumbs>
              </div>
            </div>
            <ThemeProvider theme={Utils.Theme.ButtonTheme}>
              <Button variant="contained" onClick={() => navigate("/faqs")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 mr-2"
                  fill="CurrentColor"
                  viewBox="0 0 512 512"
                >
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
                </svg>
                {t("faq_detail_page.hpfwiajvx")}
              </Button>
            </ThemeProvider>
          </div>
          {/* loading data */}
          {loadingdata && <PreLoaderComponent />}
          {/* Show all Lisitng Info */}
          <div>
            {!loadingdata && Object.keys(faq).length > 0 && (
              <>
                <Box
                  component={Paper}
                  sx={{
                    boxShadow:
                      "inset 0px 8px 12px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
                    borderRadius: "1rem",
                    margin: "0.5rem",
                  }}
                >
                  <div className="py-6 px-4">
                    <h3 className="head pb-4 font-bold capitalize overflow-wrap-anywhere">{faq.type}</h3>
                    <p className="p1 overflow-wrap-anywhere">
                      <span className="font-medium p0">
                        {t("faq_detail_page.2yyw8s90d")}:{" "}
                      </span>
                      {faq.question}
                    </p>
                    <p className="p1 overflow-wrap-anywhere">
                      <span className="font-medium p0">
                        {t("faq_detail_page.59o6i3nix")}:{" "}
                      </span>
                      {faq.answer}
                    </p>
                  </div>
                </Box>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqDetail;
