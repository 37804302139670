import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import Services from "../services";
import { useTranslation } from "react-i18next";

// checked
const ApplicationSidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const [listing, setListing] = useState<any>({});

  const getAuctionInfo = () => {
    params.list_id &&
      Services.Listing.GetOneListingForUser(params.list_id).then((res) => {
        res.status && setListing(res.data);
      });
  };

  useEffect(() => {
    getAuctionInfo();
  }, [params]);

  useEffect(() => {
    if (location?.state?.refreshAuction) {
      getAuctionInfo();
    }
  }, [location]);

  const isApplicationDisable = (e?: any) => {
    if (listing?.application_status === "approved") {
      e?.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  const isDetailsDisable = (e?: any) => {
    if (
      listing?.application_status !== "approved" ||
      listing?.details_submission_status === "approved" ||
      listing?.schedule_date_time_slot ||
      listing?.schedule_time_slot
    ) {
      e?.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  const isConditionsDisable = (e?: any) => {
    if (
      listing?.details_submission_status === "approved" ||
      !listing.title ||
      !listing.description ||
      !listing.location ||
      !listing.engine ||
      !listing.drive_train ||
      !listing.body_style ||
      !listing.exterior_color ||
      !listing.interior_color ||
      listing?.schedule_date_time_slot ||
      listing?.schedule_time_slot
    ) {
      e?.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  const isMediaDisable = (e?: any) => {
    if (
      listing?.details_submission_status === "approved" ||
      !listing.service_history ||
      listing?.schedule_date_time_slot ||
      listing?.schedule_time_slot
    ) {
      e?.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  const isScheduleDisable = (e?: any) => {
    if (
      listing?.details_submission_status === "approved" ||
      !listing.photos ||
      (listing.photos && Object.keys(listing.photos)?.length < 4) ||
      listing.photos["exterior"]?.length < 4 ||
      listing.photos["interior"]?.length < 4 ||
      listing.photos["mechanical"]?.length < 6 ||
      listing.photos["docs"]?.length < 2 ||
      (listing?.schedule_date_time_slot !== undefined &&
        listing?.schedule_date_time_slot) ||
      (listing?.schedule_time_slot !== undefined &&
        listing?.schedule_time_slot) ||
      listing?.review
    ) {
      e?.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  const isReviewDisable = (e?: any) => {
    if (
      listing?.application_status !== "approved" ||
      listing?.details_submission_status === "approved" ||
      (listing?.schedule_date_time_slot !== undefined &&
        !listing?.schedule_date_time_slot) ||
      (listing?.schedule_time_slot !== undefined &&
        !listing?.schedule_time_slot) ||
      listing?.review === "" ||
      listing?.review === null
    ) {
      e?.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  const isListingDisable = (e?: any) => {
    if (
      listing?.application_status !== "approved" ||
      listing?.details_submission_status === "approved" ||
      listing?.review === "" ||
      listing?.review === null
    ) {
      e?.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  const isAuctionDisable = (e?: any) => {
    if (
      !listing.title ||
      !listing.description ||
      !listing.location ||
      !listing.engine ||
      !listing.drive_train ||
      !listing.body_style ||
      !listing.exterior_color ||
      !listing.interior_color ||
      !listing.service_history ||
      !listing.photos ||
      (listing.photos &&
        Object.keys(listing.photos).length < 16 &&
        listing.photos["exterior"]?.length < 4 &&
        listing.photos["interior"]?.length < 4 &&
        listing.photos["mechanical"]?.length < 6 &&
        listing.photos["docs"]?.length < 2) ||
      listing?.review === "" ||
      listing?.review === null ||
      listing?.details_submission_status !== "approved"
    ) {
      e?.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="w-full sm:w-4/12 md:w-3/12 xl:w-2/12 py-5 ">
        <div className="h-full w-full overflow-y-auto sm:w-4/5 lg:w-3/5 ">
          <ul className="py-2 font-medium flex sm:flex-col overflow-x-auto h-full gap-2">
            <li>
              <NavLink
                to={`/application/${params.list_id}`}
                className={`applicationProcess ${
                  isApplicationDisable() ? "opacity-70" : ""
                }`}
                onClick={(e: any) => {
                  isApplicationDisable(e);
                }}
              >
                <span className="p1 font-normal">
                  {t("application_sidebar_component.eenoo12kj")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/details/${params.list_id}`}
                className={`applicationProcess ${
                  isDetailsDisable() ? "opacity-70" : ""
                }`}
                onClick={(e: any) => {
                  isDetailsDisable(e);
                }}
              >
                <span className="p1 font-normal">
                  {t("application_sidebar_component.ut0um54og")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/condition/${params.list_id}`}
                className={`applicationProcess ${
                  isConditionsDisable() ? "opacity-70" : ""
                }`}
                onClick={(e: any) => isConditionsDisable(e)}
              >
                <span className="p1 font-normal">
                  {t("application_sidebar_component.tq4mx8z9s")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/media/${params.list_id}`}
                className={`applicationProcess ${
                  isMediaDisable() ? "opacity-70" : ""
                }`}
                onClick={(e: any) => isMediaDisable(e)}
              >
                <span className="p1 font-normal">
                  {t("application_sidebar_component.xlyn0ly24")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/scheduling/${params.list_id}`}
                className={`applicationProcess ${
                  isScheduleDisable() ? "opacity-70" : ""
                }`}
                onClick={(e: any) => isScheduleDisable(e)}
              >
                <span className="p1 font-normal">
                  {t("application_sidebar_component.d7804eiqe")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/review/${params.list_id}`}
                className={`applicationProcess ${
                  isReviewDisable() ? "opacity-70" : ""
                }`}
                onClick={(e: any) => isReviewDisable(e)}
              >
                <span className="p1 font-normal">
                  {t("application_sidebar_component.8j5t3io9x")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/listing/${params.list_id}`}
                className={`applicationProcess ${
                  isListingDisable() ? "opacity-70" : ""
                }`}
                onClick={(e: any) => isListingDisable(e)}
              >
                <span className="p1 font-normal">
                  {t("application_sidebar_component.onjcp73j5")}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/auction/${params.list_id}`}
                className={`applicationProcess ${
                  isAuctionDisable() ? "opacity-70" : ""
                }`}
                onClick={(e: any) => isAuctionDisable(e)}
              >
                <span className="p1 font-normal">
                  {t("application_sidebar_component.rcl72cbct")}
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ApplicationSidebar;
