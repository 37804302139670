import { useEffect, useState, lazy, Suspense } from "react";
import { Pagination, Grid } from "@mui/material";
import PageComponents from "../components";
import Services from "../../services";
import { PreLoaderComponent2 } from "../../components";
import { useTranslation } from "react-i18next";
import Theme from "../../utils/Theme.utils";

const AuctionCardComponent = lazy(
  () => import("../components/AuctionCard.component")
);

const SearchCardComponent = lazy(
  () => import("../components/SearchCard.component")
);

// checked
function WatchList() {
  const { t } = useTranslation();
  const isLogedIn = Services.Auth.IsUserLogedIn();
  const auctionSortOptions = [
    {
      label: t("sort_options.ending_soon"),
      value: "ending_soon",
    },
    {
      label: t("sort_options.newly_listed"),
      value: "newly_listed",
    },
    {
      label: t("sort_options.no_reserve"),
      value: "no_reserve",
    },
    {
      label: t("sort_options.low_mile"),
      value: "lowest_mileage",
    },
  ];
  const resultSortOptions = [
    {
      label: t("sort_options.recent_ended"),
      value: "recently_ended",
    },
    {
      label: t("sort_options.low_mile"),
      value: "lowest_mileage",
    },
    {
      label: t("sort_options.high_mile"),
      value: "highest_mileage",
    },
    {
      label: t("sort_options.low_price"),
      value: "lowest_price",
    },
    {
      label: t("sort_options.high_price"),
      value: "higest_price",
    },
  ];
  const [loading, setLoading] = useState<boolean>(false);
  const [auctions, setAuctions] = useState<any[]>([]);
  const [results, setResults] = useState<any[]>([]);
  const [savedSearches, setSavedSearches] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [resultSort, setResultSort] = useState<string>("");
  const [resultOffset, setResultOffset] = useState<number>(0);
  const [resultLimit, setResultLimit] = useState<number>(10);
  const [resultPage, setResultPage] = useState<number>(1);
  const [resultPages, setResultPages] = useState<number>(1);
  const [auctionSort, setAuctionSort] = useState<string>("");
  const [auctionOffset, setAuctionOffset] = useState<number>(0);
  const [auctionLimit, setAuctionLimit] = useState<number>(10);
  const [auctionPage, setAuctionPage] = useState<number>(1);
  const [auctionPages, setAuctionPages] = useState<number>(1);

  const getWatchLists = (
    fetch: string,
    offset: number,
    limit: number,
    sort: string
  ) => {
    if (isLogedIn) {
      setLoading(true);
      Services.WatchList.GetWatchList(fetch, offset, limit, sort)
        .then((res) => {
          setLoading(false);
          if (res.status) {
            if (fetch == "results") {
              setResults(res.data);
              setResultPages(Math.ceil(res.total / resultLimit));
            } else {
              setAuctions(res.data);
              setAuctionPages(Math.ceil(res.total / auctionLimit));
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getWatchLists("results", resultOffset, resultLimit, resultSort);
  }, [resultSort, resultPage, resultLimit, resultOffset]);

  useEffect(() => {
    getWatchLists("auctions", auctionOffset, auctionLimit, auctionSort);
  }, [auctionSort, auctionPage, auctionOffset, auctionLimit]);

  const getSearch = () => {
    setLoading(true);
    Services.Search.GetSearch()
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSearch();
  }, []);

  const getSavedSearch = () => {
    setLoading(true);
    Services.Search.GetSaveSearchdata(offset, limit)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setSavedSearches(res.data);
          setTotal(Math.ceil(res.total / limit));
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteSearch = (searchKey: string) => {
    const data = {
      search_text: searchKey,
    };
    setLoading(true);
    Services.Search.DeleteSearch(data)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          getSavedSearch();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    isLogedIn && getSavedSearch();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="containerSpacing py-16 ">
      <div className="flex flex-row w-full py-0 md:py-3 lg:py-5 px-2 lg:px-0 flex-wrap items-center justify-between">
        <PageComponents.MetaComponent title={t("watch_list_page.title")} />
        {loading && <PreLoaderComponent2 />}
        <div className="flex flex-col w-full">
          <h2 className="lg:flex head font-bold mb-6 md:mb-10 lg:mb-16 underline decoration-[var(--main-color)] decoration-4">
            {t("watch_list_page.i0oq7ky7r")}
          </h2>
          <div className="lg:flex lg:flex-grow flex-row items-center shrink-0 w-full pb-6">
            <h3 className="head font-bold pr-4 pb-2 ">
              {t("watch_list_page.kndmnwuv6")}
            </h3>
            <div className="lg:flex flex-nowrap gap-4 mt-3 ml:auto lg:mt-0  ">
              <nav className="flex gap-2 flex-wrap">
                {auctionSortOptions?.length > 0 &&
                  auctionSortOptions?.map((item, index) => (
                    <button
                      className={`main-outline-button-rounded ${
                        auctionSort == item?.value
                          ? "bg-[var(--main-color)] text-white"
                          : "text-black"
                      }`}
                      onClick={() => setAuctionSort(item?.value)}
                      key={index}
                    >
                      {item?.label}
                    </button>
                  ))}
              </nav>
            </div>
          </div>
          {auctions?.length === 0 && (
            <p className="leading-relaxed pb-2 text-lg font-normal text-gray-500 info_message">
              {t("watch_list_page.iu8456mty")}{" "}
            </p>
          )}
          {
            <Suspense fallback={<PageComponents.CardSkeletonElements />}>
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {auctions?.length > 0 &&
                    auctions?.map((auction) => (
                      <AuctionCardComponent
                        item={auction}
                        getListings={() =>
                          getWatchLists(
                            "auctions",
                            auctionOffset,
                            auctionLimit,
                            auctionSort
                          )
                        }
                        showDetailsInProp={false}
                        isWatchListPage={true}
                      />
                    ))}
                </div>
                {auctions?.length > 0 && (
                  <div className="flex justify-center items-center pt-8">
                    <Pagination
                      count={auctionPages}
                      page={auctionPage}
                      onChange={(e: any, pageNum) => {
                        setAuctionPage(pageNum);
                        setAuctionOffset((pageNum - 1) * auctionLimit);
                      }}
                      shape="rounded"
                      sx={Theme.MuiPagenationStyle}
                    />
                  </div>
                )}
              </>
            </Suspense>
          }
          <hr className="h-[1px] mt-14 bg-black-200 border dark:bg-gray-700"></hr>
          <h3 className="lg:flex head py-12 font-bold  underline decoration-[var(--main-color)]decoration-4">
            {t("watch_list_page.vsmjfjvik")}
          </h3>
          {savedSearches?.length === 0 && (
            <p className="leading-relaxed pb-2 p1 font-normal text-gray-500">
              {t("watch_list_page.9s7tpfz94")}
            </p>
          )}
          <Suspense fallback={<PageComponents.CardSkeletonElements />}>
            <>
              <Grid container>
                {savedSearches &&
                  Object.keys(savedSearches)?.length > 0 &&
                  Object.keys(savedSearches)?.map((search_text: any, index) => (
                    <Grid
                      item
                      className="px-2 py-2"
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      key={index}
                    >
                      {savedSearches[search_text] &&
                        Object.keys(savedSearches[search_text])?.length > 0 && (
                          <SearchCardComponent
                            item={savedSearches[search_text]}
                            getSavedSearch={getSavedSearch}
                            searchedText={search_text}
                            deleteSearch={deleteSearch}
                            savedSearches={savedSearches}
                            index={index}
                          />
                        )}
                    </Grid>
                  ))}
              </Grid>
              {Object.keys(savedSearches)?.length > 0 && (
                <div className="flex justify-center items-center pt-4">
                  <Pagination
                    count={total}
                    page={page}
                    onChange={(e: any, pageNum) => {
                      setPage(pageNum);
                      setOffset((pageNum - 1) * limit);
                    }}
                    shape="rounded"
                    sx={Theme.MuiPagenationStyle}
                  />
                </div>
              )}
            </>
          </Suspense>
          <>
            <hr className="h-[1px] mt-14 bg-black-200 border dark:bg-gray-700"></hr>
            <div className="lg:flex lg:flex-grow flex-row items-center shrink-0 w-full mb-6 mt-10">
              <h3 className="lg:flex head font-bold py-12">
                {t("watch_list_page.x6vfbtpzr")}
              </h3>
              <div className="lg:flex flex-nowrap gap-4 mt-3 ml:auto lg:mt-0  ">
                <nav className="flex gap-2 flex-wrap">
                  {resultSortOptions?.length > 0 &&
                    resultSortOptions?.map((item, index) => (
                      <button
                        className={`main-outline-button-rounded ${
                          resultSort === item?.value
                            ? "bg-[var(--main-color)] text-white"
                            : "text-black"
                        }`}
                        onClick={() => setResultSort(item?.value)}
                        key={index}
                      >
                        {item?.label}
                      </button>
                    ))}
                </nav>
              </div>
            </div>
            <Suspense fallback={<PageComponents.CardSkeletonElements />}>
              <>
                <Grid container>
                  {results?.length > 0 &&
                    results?.map((result: any, index: number) => (
                      <Grid
                        item
                        className="px-2 py-2"
                        lg={4}
                        md={6}
                        sm={12}
                        xs={12}
                        key={index}
                      >
                        <AuctionCardComponent
                          item={result}
                          getListings={() =>
                            getWatchLists(
                              "results",
                              resultOffset,
                              resultLimit,
                              resultSort
                            )
                          }
                          showDetailsInProp={false}
                          isWatchListPage={true}
                        />
                      </Grid>
                    ))}
                </Grid>
                {results?.length > 0 && (
                  <div className="flex justify-center items-center pt-8">
                    <Pagination
                      count={resultPages}
                      page={resultPage}
                      onChange={(e: any, pageNum) => {
                        setResultPage(pageNum);
                        setResultOffset((pageNum - 1) * resultLimit);
                      }}
                      shape="rounded"
                      sx={Theme.MuiPagenationStyle}
                    />
                  </div>
                )}
              </>
            </Suspense>
          </>
        </div>
      </div>
    </div>
  );
}

export default WatchList;
