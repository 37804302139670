import { AppRoutes } from "./routes/app.routes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { useTranslation } from "react-i18next";

function App() {
  // const { t } = useTranslation();

  const appRoutes = AppRoutes.map(({ Component, children }, key) => (
    <Route key={key} element={<Component />}>
      {children.map(({ path, Component }, key) => (
        <Route key={key} path={path} element={<Component />} />
      ))}
    </Route>
  ));


  return (
    <>
      {/* <div>{t("site_title")}</div> */}
      <BrowserRouter>
        <Routes>
          {/* <Route path="/sitemap.xml" element={<Sitemap />} /> */}
          {appRoutes}
        </Routes>
      </BrowserRouter>

      {/* <div className="container px-3 py-5"> */}
      {/* <div>
          {Object.keys(lngs)?.map((lng: string, index: number) => (
            <button
              key={lng}
              style={{
                fontWeight: i18n?.resolvedLanguage === lng ? "bold" : "normal",
              }}
              type="submit"
              onClick={() => i18n?.changeLanguage(lng)}
              className="px-4 py-1 font-bold text-lg shadow"
            >
              {lngs[lng]?.nativeName}
            </button>
          ))}
        </div> */}
      {/* <div>
          {Object.keys(lngs)?.map((lng) => (
            <button
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              }}
              type="submit"
              onClick={() => {
                i18n.changeLanguage(lng);
                setCounter(count + 1);
              }}
              className="m-2 bg-blue-800 text-white px-4 py-2 rounded shadow"
            >
              {lngs[lng]?.nativeName}
            </button>
          ))}
        </div>
        <p>
          <i>{t("counter", { count })}</i>
        </p>
        <p className="text-4xl py-2">
          <Trans i18nKey="description.part1">
            Edit <code>src/App.js</code> and save to reload.
          </Trans>
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("description.part2")}
        </a>
      </div>
      <div>
        <p> {t("counter_other", { count: 1 })}</p>
      </div> */}
      {/* <div className="Footer">
        <div>{t("footer.date", { date: new Date() })}</div>
      </div> */}
      {/* <div className="Footer">
        <div>
          {t("footer.date", { date: new Date(), context: getGreetingTime() })}
        </div>
      </div>
      <p className="text-2xl text-blue-500">
        {t("new.key", "this will be added automatically")}
      </p> */}
    </>
  );
}

export default App;
