import React, { useState, memo, Dispatch } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, Sheet } from "@mui/joy";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import AddCreditCard from "./AddCreditCard.component";
import { useTranslation } from "react-i18next";

interface VerificationBrowseProps {
  openVerify: boolean;
  setOpenVerify: any;
  reloadWindow?: boolean;
  setReloadWindow?: any;
}

// checked
const VerificationBrowse = memo(function VerificationBrowse({
  openVerify,
  setOpenVerify,
  reloadWindow,
  setReloadWindow,
}: VerificationBrowseProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showCreditCard, setShowCreditCard] = useState(false);


  return (
    <>
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openVerify}
          hideBackdrop={true}
          onClose={() => {
            setOpenVerify(false);
          }}
          sx={Utils.Theme.JoyModalStyle}
        >
          <Sheet
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
            sx={Utils.Theme.JoyModalSheetStyle}
          >
            <div className="flex justify-start align-items-center w-100 h-auto py-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-12 h-12 text-white p-2 bgColor rounded-full shadow-sm mx-auto"
                viewBox="0 0 24 24"
              >
                <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
              </svg>
            </div>
            <div className="flex justify-start align-items-center w-100">
              <h2 className="head capitalize mx-auto pb-2">
                <b>{t("verification_browse_modal.heading")}</b>
              </h2>
            </div>
            <div className="py-12">
              <div className="text-center py-2">
                <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="two-xl-larger-text"
                    onClick={() => {
                      setShowCreditCard(!showCreditCard);
                      return setReloadWindow(true);
                    }}
                  >
                    {t("verification_browse_modal.register_to_bid_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
              <div className="text-center py-2">
                <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="two-xl-larger-text"
                    onClick={() => {
                      setOpenVerify(false);
                      navigate("/sell-car");
                      reloadWindow && window.location.reload();
                    }}
                  >
                    {t("verification_browse_modal.sell_your_car_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
              <div className="text-center py-2">
                <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="two-xl-larger-text"
                    onClick={() => {
                      setOpenVerify(false);
                      navigate("/");
                      reloadWindow && window.location.reload();
                    }}
                  >
                    {t("verification_browse_modal.continue_browsing_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </Sheet>
        </Modal>
      </div>
      <AddCreditCard
        showCreditCard={showCreditCard}
        setShowCreditCard={setShowCreditCard}
        reloadWindow={reloadWindow}
      />
    </>
  );
});

export default VerificationBrowse;
