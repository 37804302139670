export default function RightArrowSvg() {

  return (
    <div className="mt-20 mb-20 lg:mt-0 lg:mb-0 text-xs rotate-90 lg:transform lg:rotate-0 items-center mx-auto font-bold transform textColor">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 235.17 61.73"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        className="w-32 h-10"
      >
        <rect x="10.83" y="17.85" width="4.92" height="3" />
        <rect y="17.85" width="4.92" height="3" />
        <rect x="43.34" y="17.85" width="4.92" height="3" />
        <rect x="32.51" y="17.85" width="4.92" height="3" />
        <rect x="21.72" y="17.85" width="4.92" height="3" />
        <rect x="65.86" y="17.85" width="4.92" height="3" />
        <rect x="54.23" y="17.85" width="4.92" height="3" />
        <rect x="98.37" y="17.85" width="4.92" height="3" />
        <rect x="87.54" y="17.85" width="4.92" height="3" />
        <rect x="76.74" y="17.85" width="4.92" height="3" />
        <rect x="130.88" y="17.85" width="4.92" height="3" />
        <rect x="120.06" y="17.85" width="4.92" height="3" />
        <rect x="109.26" y="17.85" width="4.92" height="3" />
        <rect x="153.4" y="17.85" width="4.92" height="3" />
        <rect x="142.57" y="17.85" width="4.92" height="3" />
        <rect x="164.29" y="17.85" width="4.92" height="3" />
        <rect x="196.43" y="17.85" width="4.92" height="3" />
        <rect x="185.6" y="17.85" width="4.92" height="3" />
        <rect x="174.8" y="17.85" width="4.92" height="3" />
        <rect x="207.32" y="17.85" width="4.92" height="3" />
        <path
          style={{ strokeMiterlimit: 10, strokeWidth: "5px" }}
          d="M212.33,.56c-.59-.58-1.54-.58-2.12,.01-.58,.59-.58,1.54,.01,2.12l16.56,16.33-16.34,17.02c-.57,.6-.55,1.55,.04,2.12,.29,.28,.67,.42,1.05,.42,.39,0,.78-.16,1.07-.46l18.39-19.16L212.33,.56Z"
        />
      </svg>
    </div>
  );
}
