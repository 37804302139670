import React from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Images } from "../../assets";
import { Link } from "react-router-dom";
import { Input } from "@mui/joy";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";

// checked
const Shipping = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="py-2">
        <div className="flex flex-col justify-center items-center">
          <img src={Images.TruckIcon} alt="truck" className="w-16 h-12" />
          <h2 className="head font-semibold pt-2">
            {t("auction_details_page.quote_modal.shipping.title")}
          </h2>
        </div>
        <div className="py-4 border-b-2 p1">
          <p>{t("auction_details_page.quote_modal.shipping.p1")}</p>
          <ul className="px-4 py-2 list-disc">
            <li>{t("auction_details_page.quote_modal.shipping.list.1")}</li>
            <li>{t("auction_details_page.quote_modal.shipping.list.2")}</li>
            <li>{t("auction_details_page.quote_modal.shipping.list.3")}</li>
          </ul>
          <Link to="/" className="text-[var(--main-color)] hover:underline">
            {t(
              "auction_details_page.quote_modal.shipping.learn_more_link_text"
            )}
          </Link>
        </div>
        <div className="flex flex-col justify-center items-center py-4">
          <h3 className="head font-semibold">
            {t(
              "auction_details_page.quote_modal.shipping.shiping_quote_heading"
            )}
          </h3>
        </div>
        <p className="p1">{t("auction_details_page.quote_modal.shipping.p2")}</p>
        <div className="py-3 flex flex-col md:flex-row justify-between items-center gap-2">
          <Input
            name="zip_code"
            id="zip_code"
            placeholder="Destination zip code*"
            variant="outlined"
            fullWidth
            sx={Utils.Theme.JoyGrayShadeInputStyle2}
            slotProps={{
              input: {
                maxLength: 20,
              },
            }}
          />
          <ThemeProvider theme={Utils.Theme.ButtonTheme}>
            <Button variant="contained" className="w-full md:min-w-fit">
              {t("auction_details_page.quote_modal.shipping.quote_butn_text")}
            </Button>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default Shipping;
