import { ApiConfig, FormapiConfig } from "../config";

const MyListing = (offset: number, limit: number) => {
  return ApiConfig.get(
    `/api/user/account/listings?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  });
};

const GetListings = (
  offset: number,
  limit: number,
  fetch: string,
  sort?: string | null,
  startYear?: number | null,
  endYear?: number | null,
  transmission?: string | null,
  search?: string | null,
  bodyStyle?: string | null,
  status?: string | null
) => {
  return ApiConfig.get("api/user/listings", {
    params: {
      offset,
      limit,
      fetch,
      sort,
      start_year: startYear,
      end_year: endYear,
      transmission,
      body_style: bodyStyle,
      search,
      status,
    },
  }).then((res: any) => {
    return res.data;
  });
};

const GetList = (id: number) => {
  return ApiConfig.get(`/api/user/listings/${id}`).then((res: any) => {
    return res.data;
  });
};

const AddLisitng = (data: any) => {
  return FormapiConfig.post(`/api/user/listings`, data).then((res: any) => {
    return res.data;
  });
};

const UpdateLisitng = (id: string | number, data: any) => {
  let API = ApiConfig;
  if (data.photos) {
    API = FormapiConfig;
  }
  return API.post(`/api/user/listings/${id}/update`, data).then((res: any) => {
    return res.data;
  });
};

const ContactSeller = (id: string | number, data: any) => {
  let API = ApiConfig;
  if (data.photos) {
    API = FormapiConfig;
  }
  return API.post(`/api/user/listings/${id}/contact-seller`, data).then(
    (res: any) => {
      return res.data;
    }
  );
};

const UpdateAdminLisitng = (id: string | number, data: any) => {
  return ApiConfig.post(`/api/admin/listings/${id}`, data).then((res: any) => {
    return res.data;
  });
};

const GetAllListings = (
  offset: number,
  limit: number,
  sort?: string | null,
  search?: string | null,
  application_status?: string | null,
  details_submission_status?: string | null,
  has_scheduled_meeting?: boolean | null
) => {
  return ApiConfig.get("api/admin/listings", {
    params: {
      offset,
      limit,
      sort,
      search,
      application_status,
      has_scheduled_meeting,
      details_submission_status,
    },
  }).then((res: any) => {
    return res.data;
  });
};

const GetOneListings = (id: number) => {
  return ApiConfig.get(`api/admin/listings/${id}`).then((res: any) => {
    return res.data;
  });
};

const GetOneListingForUser = (id: string | number) => {
  return ApiConfig.get(`api/user/account/listings/${id}`).then((res: any) => {
    return res.data;
  });
};

const GetBidHistoryOfUserListings = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/user/account/listings/${id}/bids?limit=${limit}&offset=${offset}`
  ).then((res: any) => {
    return res.data;
  });
};

const GetBidHistoryOfListingForAdmin = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/admin/listings/${id}/bids?limit=${limit}&offset=${offset}`
  ).then((res: any) => {
    return res.data;
  });
};

const GetQuestionsOfUserListings = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/user/account/listings/${id}/questions?limit=${limit}&offset=${offset}`
  ).then((res: any) => {
    return res.data;
  });
};

const GetQuestionsOfListingForAdmin = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/admin/listings/${id}/questions?limit=${limit}&offset=${offset}`
  ).then((res: any) => {
    return res.data;
  });
};

const GetCommentsOfUserListings = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/user/account/listings/${id}/comments?limit=${limit}&offset=${offset}`
  ).then((res: any) => {
    return res.data;
  });
};

const GetCommentsOfListingForAdmin = (
  id: string | number,
  limit: number,
  offset: number
) => {
  return ApiConfig.get(
    `api/admin/listings/${id}/comments?limit=${limit}&offset=${offset}`
  ).then((res: any) => {
    return res.data;
  });
};

const ChangeApplicationStatus = (id: string | number, data: any) => {
  return ApiConfig.post(
    `api/admin/listings/${id}/change-application-status`,
    data
  ).then((res: any) => {
    return res.data;
  });
};

const ChangeDetailsStatus = (id: string | number, data: any) => {
  return ApiConfig.post(
    `api/admin/listings/${id}/approve-or-reject-details`,
    data
  ).then((res: any) => {
    return res.data;
  });
};

const GetAdminListInfo = (id: string | number) => {
  return ApiConfig.get(`/api/admin/listings/${id}`).then((res: any) => {
    return res.data;
  });
};

const DeleteListingPhoto = (id: string | number, data: any) => {
  return ApiConfig.post(`/api/user/listings/${id}/photos/delete`, data).then(
    (res: any) => {
      return res.data;
    }
  );
};

const SaveListingReview = (data: any) => {
  return ApiConfig.post(`api/user/system/rating-reviews`, data).then(
    (res: any) => {
      return res.data;
    }
  );
};

const Listing = {
  SaveListingReview,
  DeleteListingPhoto,
  GetAdminListInfo,
  UpdateAdminLisitng,
  ChangeDetailsStatus,
  ChangeApplicationStatus,
  UpdateLisitng,
  GetCommentsOfUserListings,
  GetQuestionsOfUserListings,
  GetBidHistoryOfUserListings,
  GetOneListingForUser,
  GetOneListings,
  GetAllListings,
  AddLisitng,
  MyListing,
  GetListings,
  GetList,
  GetBidHistoryOfListingForAdmin,
  GetQuestionsOfListingForAdmin,
  GetCommentsOfListingForAdmin,
  ContactSeller,
};

export default Listing;
