import React, { useState, useEffect, memo } from "react";
import Services from "../../services";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// checked
const DealerFactsInfo = memo(({ data }: any) => {
  const { t } = useTranslation();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [domainName, setDomainName] = useState<string>("");

  useEffect(() => {
    (Services.Storage.getCurrentRole() === "admin" ||
      Services.Storage.getCurrentRole() === "super_admin") &&
      setIsUserAdmin(true);
  }, []);

  useEffect(() => {
    if (data?.dealership_website) {
      const url = new URL(data?.dealership_website)?.hostname;
      url &&
        setDomainName(
          url?.split(".")[0] === "www" ? url?.split(".")[1] : url?.split(".")[0]
        );
    }
  }, [data?.dealership_website]);

  return (
    <>
      <div className="">
        <div className="w-full border  flex flex-wrap rounded p1 capitalize shadow2">
          {isUserAdmin && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.kucoal9ja")}
                </dt>
                <dd className="facts_dd">
                  {data.dealership_name}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.3if3uynqo")}
                </dt>
                <dd className="facts_dd underline">
                  {data.dealership_website && (
                    <Link to={data.dealership_website} target="_blank">
                      {domainName}
                    </Link>
                  )}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.69a00qjv7")}
                </dt>
                <dd className="facts_dd">
                  {data.dealership_fees} CHF
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.zq9suayqs")}
                </dt>
                <dd className="facts_dd">
                  {data.dealership_monthly_sales}
                </dd>
              </dl>
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default DealerFactsInfo;
