const isSymbolAndSpecialCharExist = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/~`?]+/g;

// only dash are allowed
const sepcialSymbolRegexp = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/~`?]+/;

// only accepts number and text
let symbolPattern = /[^\w\s]/g;

// regexp that only accepts text
let nonTextPattern = /[^A-Za-z\s]/g;

// regexp that only accepts text and dash,  &
let textAndDashPattern = /[^A-Za-z\s-&]/g;

// - , . ~ are allowed
const carDesAllowedSymbols = /[!@#$%^&*()_+\=\[\]{};':"\\|<>\/`?]+/;

const RegExp = {
  isSymbolAndSpecialCharExist,
  sepcialSymbolRegexp,
  symbolPattern,
  nonTextPattern,
  carDesAllowedSymbols,
  textAndDashPattern,
};

export default RegExp;
