import { useState, useEffect } from "react";
import { ThemeProvider, Button, Breadcrumbs } from "@mui/material";
import Utils from "../../utils";
import { useLocation, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Icons } from "../../assets";
import PageComponents from "../components";
import Services from "../../services";
import { useTranslation } from "react-i18next";

// checked
const AdminDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const role = Services.Storage.getCurrentRole();
  const [adminInfo, setAdminInfo] = useState<any>({});

  useEffect(() => {
    location.state && location.state.data && setAdminInfo(location.state.data);
  }, [location]);

  useEffect(() => {
    role !== "super_admin" && navigate(-1);
  }, [role]);

  return (
    <>
      <PageComponents.MetaComponent title={t("admin_details_page.aqxiamdsj")} />
      <div className=" ">
        <div className="">
          {/* heading */}
          <div className="flex flex-wrap justify-between items-center py-6 gap-2">
            <div className="flex flex-col justify-center items-start gap-2">
              <h3 className="head leading-6 font-bold text-gray-900">
                {t("admin_details_page.91919et2b")}
              </h3>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/dashboard" className="hover:underline">
                    {t("admin_details_page.wruztcuqg")}
                  </Link>
                  <Link to={"/admin-users"} className="hover:underline">
                    {t("admins_page.elbj2v19c")}
                  </Link>
                  <Link to={location.pathname} className="hover:underline">
                    {t("admin_details_page.91919et2b")}
                  </Link>
                </Breadcrumbs>
              </div>
            </div>
            <ThemeProvider theme={Utils.Theme.ButtonTheme}>
              <Button variant="contained" onClick={() => navigate(-1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4 mr-2"
                  viewBox="0 0 448 512"
                  fill="CurrentColor"
                >
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                </svg>
                {t("admin_details_page.yvz9rj0tl")}
              </Button>
            </ThemeProvider>
          </div>
          <div className="flex justify-center items-center">
            {adminInfo && Object.keys(adminInfo).length > 0 && (
              <div className="py-4 h-96 mx-auto">
                <div className="flex justify-between items-start gap-2 ">
                  <div className="flex flex-wrap juftify-start items-center ">
                    <div className="w-24 sm:w-28 md:w-36 h-24 sm:h-28 md:h-36">
                      <img
                        src={
                          adminInfo.avatar
                            ? `${process.env.REACT_APP_HOST_API_KEY}/${adminInfo.avatar}`
                            : Icons.Profile
                        }
                        loading="lazy"
                        alt="profile-icon"
                        className="w-full h-full rounded-full shadow2 border"
                      />
                    </div>
                    <div className="py-2 sm:py-4 md:py-8 ps-2 sm:ps-4 overflow-wrap-anywhere">
                      <h3 className="font-semibold p000">
                        {adminInfo.username}
                      </h3>
                      <h3 className="font-medium p0">{adminInfo.email}</h3>
                      <p className="text-uppercase p1 ">
                        {t("admin_details_page.wnlhebjc7")}{" "}
                        {moment(adminInfo.created_at).format("MMMM DD YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDetails;
