import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { AppImages } from "../assets";
import {
  FacebookRounded,
  Instagram,
  LinkedIn,
  Pinterest,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useScreen } from "../hooks";
import { isUser_loged_in } from "../utils/checkUserType.utils";

function FooterComponent() {
  const { t } = useTranslation();
  const screen = useScreen();
  const isUserLogedIn = isUser_loged_in();
  let navigate = useNavigate();

  const baseLine = () => {
    return <div className="my-2"></div>;
  };

  return (
    <footer className="bg-gray-900 md:pt-16 pt-12 pb-3 lg:px-16 px-8">
      <Grid
        container
        spacing={screen?.width > 1024 ? 4 : screen.width > 768 ? 3 : 2}
      >
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <div className="mb-2 sm:mb-6 md:mb-0 space-y-2 sm:space-y-8">
            <Link
              to="/"
              className="flex items-center"
              onClick={() => window?.scrollTo(0, 0)}
            >
              <div>
                <img
                  src={AppImages.LogoImage}
                  className=" me-3 h-auto w-[3.5em] md:w-[4em]"
                  alt="AutoBids Logo"
                />
              </div>
              <span className="self-center text-2xl md:text-3xl lg:text-4xl font-bold whitespace-nowrap text-[var(--main-color)]">
                {t("site_title")}
              </span>
            </Link>
            <div>
              <p className="text-white md:max-w-[320px] max-w-[100%] p1">
                {t("footer.description")}
              </p>
              <div className="flex items-center gap-3 m-0">
                <a
                  href="#"
                  className="text-white hover:text-gray-300 dark:hover:text-white"
                >
                  <FacebookRounded sx={{ fontSize: "24px" }} />
                  <span className="sr-only">
                    {t("footer.social_icons.facebook_label")}
                  </span>
                </a>
                <a
                  href="#"
                  className="text-white hover:text-gray-300 dark:hover:text-white"
                >
                  <span className="text-[24px]">𝕏</span>
                  <span className="sr-only">
                    {t("footer.social_icons.twitter_label")}
                  </span>
                </a>
                <a
                  href="#"
                  className="text-white hover:text-gray-300 dark:hover:text-white"
                >
                  <LinkedIn sx={{ fontSize: "24px" }} />
                  <span className="sr-only">
                    {t("footer.social_icons.linked_in_label")}
                  </span>
                </a>
                <a
                  href="#"
                  className="text-white hover:text-gray-300 dark:hover:text-white"
                >
                  <Instagram sx={{ fontSize: "24px" }} />
                  <span className="sr-only">
                    {t("footer.social_icons.instagram_label")}
                  </span>
                </a>
                <a
                  href="#"
                  className="text-white hover:text-gray-300 dark:hover:text-white"
                >
                  <Pinterest sx={{ fontSize: "24px" }} />
                  <span className="sr-only">
                    {t("footer.social_icons.pinterest_label")}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={2.666} md={6} sm={6} xs={12}>
          {baseLine()}
          <div>
            <div className="mb-2 sm:mb-6 p1 font-light text-white">
              {t("footer.about_menus.title")}
            </div>
            <ul className="text-white font-medium space-y-1 sm:space-y-2 md:space-y-4">
              <li>
                <Link
                  to="/about"
                  state={{ div_id: "#buyingacar" }}
                  className="hover:underline text-bold p0"
                >
                  {t("footer.about_menus.1")}
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  state={{ div_id: "#sellingacar" }}
                  className="hover:underline text-bold p0"
                >
                  {t("footer.about_menus.2")}
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  state={{ div_id: "#finalizingthesale" }}
                  className="hover:underline text-bold p0"
                >
                  {t("footer.about_menus.3")}
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  state={{ div_id: "#faqs" }}
                  className="hover:underline text-bold p0"
                >
                  {t("footer.about_menus.4")}
                </Link>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item lg={2.666} md={6} sm={6} xs={12}>
          {baseLine()}
          <div>
            <div className="mb-2 sm:mb-6 p1 font-light text-white">
              {" "}
              {t("footer.seller_menus.title")}
            </div>
            <ul className="text-white font-medium space-y-1 sm:space-y-2 md:space-y-4">
              <li>
                <Link to="/sell-car" className="hover:underline text-bold p0">
                  {t("footer.seller_menus.1")}
                </Link>
              </li>
              <li>
                <Link
                  className="hover:underline text-bold p0 cursor-pointer"
                  to="/sell-car/submit"
                  target="_blank"
                >
                  {t("footer.seller_menus.2")}
                </Link>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item lg={2.666} md={6} sm={6} xs={12}>
          {baseLine()}
          <div>
            <div className="mb-2 sm:mb-6 p1 font-light text-white">
              {t("footer.support_menus.title")}
            </div>
            <ul className="text-white font-medium space-y-1 sm:space-y-2 md:space-y-4">
              <li>
                <a href="/support" className="hover:underline text-bold p0">
                  {t("footer.support_menus.1")}
                </a>
              </li>
              <li>
                <a href="/shipping" className="hover:underline text-bold p0">
                  {t("footer.support_menus.2")}
                </a>
              </li>
              <li>
                <a href="/inspection" className="hover:underline text-bold p0">
                  {t("footer.support_menus.3")}
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/cars-bids/"
                  target="_blank"
                  className="hover:underline text-bold p0"
                >
                  {t("footer.support_menus.4")}
                </a>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
      <div className="w-full pt-8 sm:pt-12 tracking-wide">
        <div className="p2 text-white ">{t("footer.copy_right")}</div>
        <div className="flex items-end gap-1 sm:gap-2 text-white">
          <a
            href="/term-of-use"
            className="hover:underline font-bold p2"
            target="_blank"
          >
            {t("footer.use_terms")}
          </a>
          <span>&</span>
          <a
            href="/privacy-policy"
            className="hover:underline font-bold p2"
            target="_blank"
          >
            {t("footer.policy")}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
