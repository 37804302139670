import { ApiConfig } from "../config";

const GetSearch = () => {
  return ApiConfig.get("/api/user/saved-searches", {}).then((res: any) => {
    return res.data;
  });
};

const SaveSearch = (data: any) => {
  return ApiConfig.post("/api/user/saved-searches", data).then((res: any) => {
    return res.data;
  });
};

const GetSaveSearchdata = (offset: number, limit: number) => {
  return ApiConfig.get(
    `/api/user/saved-searches/data?offset=${offset}&limit=${limit}`
  ).then((res: any) => {
    return res.data;
  });
};

const DeleteSearch = (data: any) => {
  return ApiConfig.put("/api/user/saved-searches", data).then((res: any) => {
    return res.data;
  });
};

const Service = {
  GetSearch,
  SaveSearch,
  DeleteSearch,
  GetSaveSearchdata,
};

export default Service;
