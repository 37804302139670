import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../../assets";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

// checked
const Support = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageComponents.MetaComponent
        title={t("support_page.title")}
        canonicalUrl={window.location.host}
      />
      <div className="containerSpacing mt-16">
        <div className="support">
          <h1 className="head8 font-extrabold py-6">
            {t("support_page.support_heading")}
          </h1>
          <div className="section">
            <h2>{t("support_page.j95gvlfvo")}</h2>
            <h3>{t("support_page.kp42rjznw")}</h3>
            <p>{t("support_page.mfhlgo6jb")}</p>
            <p className="link">
              <Link to="/about#buyer questions">{t("support_page.mfhlgo6jb1")}</Link>
            </p>
            <hr />
          </div>
          <div className="section">
            <h2>{t("support_page.xkb2q2hyq")}</h2>
            <h3>{t("support_page.cjaxjq12e")}</h3>
            <p>{t("support_page.q6ou60xwa")}</p>
            <p>
              {t("support_page.j9dflvcpf")}{" "}
              <Link title="submit your car" to="/sell-car">
                {t("support_page.j9dflvcpf")}{" "}
              </Link>
              {t("support_page.1gs5eapag")}
            </p>
            <p className="link">
              <Link to="/about#seller questions">{t("support_page.1gs5eapag1")}</Link>
            </p>
            <hr />
          </div>
          <div className="section">
            <h2>{t("support_page.hcy73okm2")}</h2>
            <h3>{t("support_page.1kyrd3gi2")}</h3>
            <p>
              {t("support_page.lce0yhrob")}{" "}
              <a title="My Listings" href="/listings">
                {t("support_page.2xl6uk2w1")}{" "}
              </a>
              {t("support_page.ouqya0nkd")}
            </p>
            <h3>{t("support_page.b46eqh8c7")}</h3>
            <p>
              {t("support_page.fkqirav2s")}{" "}
              <a href="mailto:feedback@auto-bids.ch">feedback@auto-bids.ch</a>.{" "}
            </p>
            <h3>{t("support_page.ue6ktbsor")}</h3>
            <p className="pb-8">
              {t("support_page.feoqf32qq")}{" "}
              <a href="mailto:support@auto-bids.ch">support@auto-bids.ch</a>.{" "}
              {t("support_page.n7mj9rlpt")}
            </p>
            <hr />
          </div>
          <div className="section">
            <h2>{t("support_page.88unazlo4")}</h2>
            <div className="flex gap-5 py-3">
              <p>
                {t("support_page.rlm5v8xf2")}{" "}
                <br />
                {t("support_page.qo08x6rgy")}{" "}
                <br />
                {t("support_page.2ttipvy9c")}{" "}
              </p>
              <img src={Images.Map} alt="Map" className="w-[200px] h-[100px]" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
