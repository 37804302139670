import React, { useState, memo } from "react";
import { Button, ThemeProvider, Tooltip } from "@mui/material";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import Utils from "../../src/utils";
import {
  ContentCopy,
  Facebook,
  Twitter,
  Reddit,
  Email,
  X
} from "@mui/icons-material";
import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";

// checked
const ShareModal = memo(function ShareModal({
  setIsModalOpen,
  auctionInfo,
}: any) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [toolTipTitle, setToolTipTitle] = useState("Click to Copy");
  const pageLocation = window.location.host || window.location.origin;

  const handleCopy = () => {
    return navigator?.clipboard
      ?.writeText(
        `${pageLocation}/auctions/${auctionInfo?.year?.replace(
          /\s+/g,
          "-"
        )}-${auctionInfo?.make?.replace(
          /\s+/g,
          "-"
        )}-${auctionInfo?.model?.replace(/\s+/g, "-")}${
          auctionInfo?.title ? "-" : ""
        }${auctionInfo?.title?.replace(/\s+/g, "-")}/${auctionInfo?.uuid}`
      )
      .then(() => {
        setToolTipTitle("Link Copied");
      });
  };

  const sendMail = () => {
    window.open(`mailto:${process.env.REACT_APP_EMAIL}`, "_blank");
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={true}
          hideBackdrop={true}
          onClose={() => {
            setIsModalOpen(false);
          }}
          sx={Utils.Theme.JoyModalStyle}
        >
          <Sheet variant="outlined" sx={Utils.Theme.JoyModalSheetStyle}>
            <ModalClose
              variant="outlined"
              sx={Utils.Theme.JoyModalCloseStyle}
            />
            <div>
              <div className="w-100">
                <div className="flex flex-row ">
                  <div className=" w-full">
                    <h3 className="head text-center leading-6 font-bold text-gray-900">
                      {t("auction_details_page.share_dialogue_section.title")}
                    </h3>
                    <div className="pt-6">
                      <div className="">
                        <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                          <Tooltip title={toolTipTitle}>
                            <Button
                              variant="contained"
                              type="submit"
                              fullWidth
                              disabled={isLoading}
                              onClick={handleCopy}
                            >
                              <div className="flex justify-start items-center gap-2 min-w-[100px] p1">
                                <ContentCopy />
                                {t(
                                  "auction_details_page.share_dialogue_section.copy_link_text"
                                )}
                              </div>
                            </Button>
                          </Tooltip>
                        </ThemeProvider>
                      </div>
                    </div>
                    <div className="my-4 ">
                      <FacebookShareButton
                        url={pageLocation + "/" + auctionInfo?.uuid}
                        hashtag="#muo"
                        className="w-full"
                      >
                        <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                          <Tooltip
                            title={t(
                              "auction_details_page.share_dialogue_section.social.facebook.title"
                            )}
                          >
                            <Button
                              variant="contained"
                              type="submit"
                              fullWidth
                              disabled={isLoading}
                            >
                              <div className="flex justify-start items-center gap-2 min-w-[100px] p1">
                                <Facebook />
                                {t(
                                  "auction_details_page.share_dialogue_section.social.facebook.label"
                                )}
                              </div>
                            </Button>
                          </Tooltip>
                        </ThemeProvider>
                      </FacebookShareButton>
                    </div>
                    <div className="my-4 ">
                      <TwitterShareButton
                        url={pageLocation + "/" + auctionInfo?.uuid}
                        className="w-full"
                      >
                        <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                          <Tooltip
                            title={t(
                              "auction_details_page.share_dialogue_section.social.twitter.title"
                            )}
                          >
                            <Button
                              variant="contained"
                              type="submit"
                              fullWidth
                              disabled={isLoading}
                            >
                              <div className="flex justify-start items-center gap-2 min-w-[100px] p1">
                                <X />
                                X
                              </div>
                            </Button>
                          </Tooltip>
                        </ThemeProvider>
                      </TwitterShareButton>
                    </div>
                    <div className="my-4 ">
                      <RedditShareButton
                        url={pageLocation + "/" + auctionInfo?.uuid}
                        className="w-full"
                      >
                        <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                          <Tooltip
                            title={t(
                              "auction_details_page.share_dialogue_section.social.reddit.title"
                            )}
                          >
                            <Button
                              variant="contained"
                              type="submit"
                              fullWidth
                              disabled={isLoading}
                            >
                              <div className="flex justify-start items-center gap-2 min-w-[100px] p1">
                                <Reddit />
                                {t(
                                  "auction_details_page.share_dialogue_section.social.reddit.label"
                                )}
                              </div>
                            </Button>
                          </Tooltip>
                        </ThemeProvider>
                      </RedditShareButton>
                    </div>
                    <div className="my-4 ">
                      <ThemeProvider theme={Utils.Theme.ButtonActiveTheme}>
                        <Tooltip
                          title={t(
                            "auction_details_page.share_dialogue_section.social.email.title"
                          )}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            disabled={isLoading}
                            onClick={() => sendMail()}
                          >
                            <div className="flex justify-start items-center gap-2 min-w-[100px] p1">
                              <Email />
                              {t(
                                "auction_details_page.share_dialogue_section.social.email.label"
                              )}
                            </div>
                          </Button>
                        </Tooltip>
                      </ThemeProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Sheet>
        </Modal>
      </div>
    </>
  );
});

export default ShareModal;
