import React from "react";
import { CircularProgress, Box } from "@mui/material";

interface classType {
  className?: string;
}

const PreLoaderComponent = ({ className }: classType) => {
  return (
    <div className={className}>
      <Box className="preLoaderStyling">
        <CircularProgress className="theme-text-color" />
      </Box>
    </div>
  );
};

export default PreLoaderComponent;
