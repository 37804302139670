import { useState, memo } from "react";
import QuestionAnswers from "./QuestionAnswers.component";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import AskQuestion from "./AskQuestion.component";
import { Avatar, Tooltip } from "@mui/material";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import Services from "../../services";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

interface auctionProps {
  data: any;
  getQuestions: any;
  askModalOpen: any;
  setIsAskModalOpen: any;
}

// /checked
const AuctionQuestion = memo(
  ({ data, getQuestions, askModalOpen, setIsAskModalOpen }: auctionProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
    const [qa, setQa] = useState<any>({});

    const updateUpvote = (e: any, item: any) => {
      const data = {
        value: !item.upvoted,
      };
      Services.QA.UpvoteQuestion(item.listing_id, item.id, data).then((res) => {
        if (res.status) {
          Swal.fire(
            t(
              "auction_details_page.ask_question_modal.sweat_alert.success_title"
            ),
            t(
              "auction_details_page.ask_question_modal.sweat_alert." +
              (data.value
                ? "upvoted_success_message"
                : "downvoted_success_message")
            )
          );
          getQuestions();
        }
      });
    };

    return (
      <>
        {data.length > 0 && (
          <>
            <Swiper
              spaceBetween={6}
              grabCursor={true}
              slidesPerView={"auto"}
              navigation={true}
              modules={[Pagination, Navigation]}
            >
              {data?.length > 0 &&
                data?.map((item: any, index: number) => {
                  return (
                    <SwiperSlide
                      className="qSwiperItem rounded relative border shadow-sm swiperWfull "
                      key={index}
                    >
                      <div className="px-3 ">
                        {/*  Question  */}
                        <div className="flex justify-start items-start py-1">
                          <Avatar
                            alt={item?.user?.username}
                            src={
                              item?.user?.avatar &&
                              `${process.env.REACT_APP_HOST_API_KEY}/${item?.user?.avatar}`
                            }
                          />
                          <div className="flex flex-col justify-end items-start pt-1">
                            {/*    profile info     */}
                            <div className="flex justify-start items-center ">
                              <span className="pl-2 pr-0.5 font-medium p1">
                                {item?.user?.username}
                              </span>
                              {item?.user?.is_registered_bidder && (
                                <>
                                  <Tooltip title="Registered Bidder">
                                    <svg
                                      className="verified  me-1"
                                      width={17}
                                      height={17}
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      aria-labelledby="v-36GQ5z0zNateCalv"
                                    >
                                      <path
                                        d="M6.166 1.286c.952-1.715 3.418-1.715 4.37 0l.425.764.84-.24c1.886-.54 3.63 1.205 3.091 3.09l-.24.841.764.425c1.715.952 1.715 3.418 0 4.37l-.764.425.24.84c.54 1.886-1.205 3.63-3.09 3.091l-.841-.24-.424.764c-.953 1.715-3.419 1.715-4.371 0l-.425-.764-.84.24c-1.886.54-3.63-1.205-3.091-3.09l.24-.841-.764-.424c-1.715-.953-1.715-3.419 0-4.371l.764-.425-.24-.84C1.27 3.015 3.015 1.27 4.9 1.81l.841.24.425-.764z"
                                        fill="var(--main-color)"
                                      />
                                      <path
                                        d="M11.5 6.351l-3.625 4.5L6 9.033"
                                        stroke="var(--dark-text-color)"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </Tooltip>
                                </>
                              )}
                              <div className="px-2 flex justify-start items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="w-2 m-auto"
                                >
                                  <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                                </svg>
                                <p className="p-0 m-0 ps-0.5 p2">
                                  {item?.user?.reputation_score}
                                </p>
                                {(item?.user?.is_seller ||
                                  item?.user?.is_bidder) && (
                                    <p className="  font-medium p3 capitalize bgColor text-white px-1 rounded ms-1.5 my-auto py-[0.1rem] shadow-sm">
                                      {item?.user?.is_seller
                                        ? t(
                                          "auction_details_page.ask_question_modal.seller"
                                        )
                                        : item?.user?.is_bidder &&
                                        t(
                                          "auction_details_page.ask_question_modal.bidder"
                                        )}
                                    </p>
                                  )}
                              </div>
                            </div>
                            {/*   Asked Question */}
                            <p className="p1 leading-5	 p-1  truncate w-full max-h-[46px] text-pretty overflow-wrap-anywhere">
                              <strong>Q: </strong>
                              {item?.text}
                            </p>
                          </div>
                        </div>
                        {/*  Answer */}
                        {item?.answers?.length > 0 &&
                          item?.answers?.map((answer: any, index: number) => (
                            <div className="flex justify-start items-start py-1 mb-3">
                              <Avatar
                                src={
                                  answer?.user?.avatar &&
                                  `${process.env.REACT_APP_HOST_API_KEY}/${answer?.user?.avatar}`
                                }
                                alt={answer?.user?.username}
                                onClick={() =>
                                  navigate(`/user/${answer?.user?.username}`)
                                }
                              />
                              <div className="flex flex-col justify-end items-start pt-1">
                                {/*   profile info  */}
                                <div className="flex justify-start items-center ">
                                  {/* profile username */}
                                  <span
                                    className="pl-2 pr-0.5 font-medium p1"
                                    onClick={() =>
                                      navigate(`/user/${item.user.username}`)
                                    }
                                  >
                                    {answer?.user?.username}
                                  </span>
                                  {answer?.user?.is_registered_bidder && (
                                    <>
                                      <Tooltip title="Registered Bidder">
                                        <svg
                                          className="verified  me-1"
                                          width={17}
                                          height={17}
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-labelledby="v-36GQ5z0zNateCalv"
                                        >
                                          <path
                                            d="M6.166 1.286c.952-1.715 3.418-1.715 4.37 0l.425.764.84-.24c1.886-.54 3.63 1.205 3.091 3.09l-.24.841.764.425c1.715.952 1.715 3.418 0 4.37l-.764.425.24.84c.54 1.886-1.205 3.63-3.09 3.091l-.841-.24-.424.764c-.953 1.715-3.419 1.715-4.371 0l-.425-.764-.84.24c-1.886.54-3.63-1.205-3.091-3.09l.24-.841-.764-.424c-1.715-.953-1.715-3.419 0-4.371l.764-.425-.24-.84C1.27 3.015 3.015 1.27 4.9 1.81l.841.24.425-.764z"
                                            fill="var(--main-color)"
                                          />
                                          <path
                                            d="M11.5 6.351l-3.625 4.5L6 9.033"
                                            stroke="var(--dark-text-color)"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </Tooltip>
                                    </>
                                  )}
                                  <div className="px-2 flex justify-start items-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 384 512"
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="w-2 m-auto"
                                    >
                                      <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                                    </svg>
                                    <p className="p-0 m-0 ps-0.5 p2 ">
                                      {answer?.user?.reputation_score}
                                    </p>
                                    {(answer?.user?.is_seller ||
                                      answer?.user?.is_bidder) && (
                                        <p className="  font-medium p3 capitalize bgColor text-white px-1 rounded ms-1.5 my-auto py-[0.1rem] shadow-sm">
                                          {answer?.user?.is_seller
                                            ? t(
                                              "auction_details_page.ask_question_modal.seller"
                                            )
                                            : answer?.user?.is_bidder &&
                                            t(
                                              "auction_details_page.ask_question_modal.bidder"
                                            )}
                                        </p>
                                      )}
                                  </div>
                                </div>
                                {/*   Give Answer */}
                                <p className="p1 leading-5	 p-1 truncate w-full max-h-[46px] text-pretty overflow-wrap-anywhere">
                                  <strong>A: </strong>
                                  {answer?.text}
                                </p>
                              </div>
                            </div>
                          ))}
                        {/*   Bottom Bar  */}
                        <div className="px-3 py-3  top-shadow-swiper rounded absolute bottom-0 w-full blur-[0.1px] swipper-bottom-bg left-0 ">
                          <div className="flex blur-none justify-between items-center px-5 ">
                            {/* not upvoted */}
                            <button
                              className={`flex  px-2 border rounded-xl p1 cursor-pointer ${item.upvoted
                                  ? "bg-[--main-color] borderColor opacity-80 text-white"
                                  : "bg-transparent opacity-60"
                                } shadow-sm`}
                              onClick={(e) => updateUpvote(e, item)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                                stroke="currentColor"
                                fill="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-2 m-auto"
                              >
                                <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                              </svg>
                              <p className="ps-1">
                                {item?.upvotes ? item?.upvotes : 0}
                              </p>
                            </button>
                            <button
                              onClick={() => {
                                setQa(item);
                                setModal(!modal);
                              }}
                              className="cursor-pointer textColor p2"
                            >
                              {t(
                                "auction_details_page.ask_question_modal.view_answers"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              {/*   empty swipper for responsiveness  */}
              <SwiperSlide className="qSwiperItem border-0"></SwiperSlide>
            </Swiper>
          </>
        )}

        {/* show all questions and thier answers modal */}
        {modal && (
          <QuestionAnswers
            modalOpen={modal}
            setIsModalOpen={setModal}
            askModalOpen={askModalOpen}
            setIsAskModalOpen={setIsAskModalOpen}
            data={qa}
            displayAllQuestions={false}
            getQuestions={getQuestions}
          />
        )}

        {/* ask a question modal form */}
        {askModalOpen && (
          <AskQuestion
            modalOpen={askModalOpen}
            setIsModalOpen={setIsAskModalOpen}
            getQuestions={getQuestions}
          />
        )}
      </>
    );
  }
);

export default AuctionQuestion;
