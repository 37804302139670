import React, { useEffect } from "react";
import PageComponents from "../components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Services from "../../services";
import { useTranslation } from "react-i18next";
import { AppImages } from "../../assets";

// checked
const UnsubscribeDailyMail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const UnsubscribeDailyMail = (email: string) => {
    Services.Auth.UnSubscribeToDailyEmail({ email })
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: t("unsubscription_daily_mail.sweat_alert.success_message"),
          });
          navigate("/");
          return Services.Storage.unsubscribeDailyEmail();
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
          });
          return navigate("/");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  useEffect(() => {
    if (location?.search != "") {
      const email = location?.search?.split("=")[1];
      email ? UnsubscribeDailyMail(email) : navigate("/404");
    } else {
      return navigate("/404");
    }
  }, [location]);

  return (
    <>
      <PageComponents.MetaComponent
        title={t("unsubscription_daily_mail.title")}
      />
      <div className=" pt-12 sm:pt-20 md:pt-24 xl:pt-32">
        <div className="container mx-auto flex  justify-center items-center p-10 pb-0 sm:pb-10">
          <div className="w-full sm:w-5/6 md:w-4/6 lg:w-3/6 xl:w-2/6 px-6   sm:py-12 pt-12 pb-0 border borderColor flex flex-col justify-center items-center shadow-lg drop-shadow-sm rounded">
            <Link
              to="/"
              className="flex title-font font-medium items-center text-gray-900  md:mb-0 "
            >
              <img
                src={AppImages.LogoImage}
                className="md:h-12 h-10"
                alt="AutoBids Logo"
              />
              <h1 className="ml-3 head capitalize">
                {t("site_title")}
              </h1>
            </Link>
            <div className="">
              <p className="p0 text-semibold text-center pt-2">
                {t("unsubscription_daily_mail.description")}
              </p>
              <h1 className="text-center head text-bold py-4">
                {t("unsubscription_daily_mail.heading")}...
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnsubscribeDailyMail;
