import React, { useState, useEffect } from "react";
import AuctionCardComponent from "./AuctionCard.component";
import { useLocation } from "react-router-dom";
import Services from "../../services";
import { Grid, Pagination } from "@mui/material";
import { PreLoaderComponent } from "../../components";
import { useTranslation } from "react-i18next";
import { CardSkeletonElements } from "./CardsSkeleton.component";

// checked
const UserBids = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const user = Services.Storage.getCurrentUser();
  const [bids, setBids] = useState<any[]>([]);
  const [loadingdata, setLoadingdata] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const getListings = () => {
    setLoadingdata(true);
    location?.state?.data?.id &&
      Services.User.getUserBidsForAdmin(
        location?.state?.data?.id,
        offset,
        limit
      )
        .then((res) => {
          setLoadingdata(false);
          if (res?.status) {
            let data = res?.data;
            let newData: any[] = [];
            if (
              user?.role !== "user" &&
              Array.isArray(data) &&
              data?.length > 0
            ) {
              data?.map((item, index) => {
                newData.push({
                  ...item,
                  current_bid: {
                    ...item?.user_bid,
                  },
                });
              });
            }
            setBids(user?.role !== "user" ? newData : data);
            res?.total && setTotal(Math.ceil(res?.total / limit));
          }
        })
        .catch((error) => {
          setLoadingdata(false);
        });
  };

  useEffect(() => {
    getListings();
  }, [offset, limit, page]);

  return (
    <>
      {loadingdata && (
        <Grid container spacing={2}>
          <CardSkeletonElements />
        </Grid>
      )}
      <div className="flex flex-col px-3 sm:px-1 py-5 mx-auto">
        <div className="flex flex-wrap">
          {!loadingdata &&
            (bids?.length > 0 ? (
              bids?.map((item: any, index: number) => (
                <div
                  className="p-3 w-full md:w-1/2 xl:w-1/3 2xl:w-1/4 justify-center items-start"
                  key={index}
                >
                  <AuctionCardComponent
                    item={item}
                    getListings={getListings}
                    showStatus={
                      item?.current_bid?.status === "won" ? true : false
                    }
                    showDetailsInProp={true}

                  />
                </div>
              ))
            ) : (
              <p className="info_message">{t("user_details_page.thzlgqo8q")}</p>
            ))}
        </div>
        <div className="py-3 flex justify-end items-center">
          {!loadingdata && total !== 0 && (
            <div className="">
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum: number) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserBids;
