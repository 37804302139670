import { useState, memo, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import Services from "../../services";
import Utils from "../../utils";
import { Button, ThemeProvider } from "@mui/material";
import CreateBid from "./CreateBid.components";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { Input, Autocomplete } from "@mui/joy";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

type checkout = {
  setShowModal: any;
  auction_info: any;
  getComments: any;
  getListOfAuction: any;
  hideBidModal?: boolean;
  hideAgreementModal?: any;
  showCreditCard: boolean;
  reloadWindow?: boolean;
};

// checked
const CheckoutForm = memo(
  ({
    setShowModal,
    auction_info,
    getComments,
    getListOfAuction,
    hideBidModal,
    hideAgreementModal,
    showCreditCard,
    reloadWindow,
  }: checkout) => {
    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();
    const location = useLocation();
    let user = Services.Storage.getCurrentUser();
    const isAuctionPage = location?.pathname?.split("/")?.[1] === "auctions";
    const navigate = useNavigate();
    const countryOptions = [
      {
        label: t("checkout_form.location_options.1"),
        value: t("checkout_form.location_options.1"),
      },
      {
        label: t("checkout_form.location_options.2"),
        value: t("checkout_form.location_options.2"),
      },
    ];
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showBidModal, setShowBidModal] = useState<boolean>(false);
    const [newCard, setNewCard] = useState<boolean>(true);
    const [userCards, setUserCards] = useState<any[]>([]);
    const [selectedCard, setSelectedCard] = useState<any>({});
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);

    const formik = useFormik({
      initialValues: {
        name: "",
        // country: "us/ca",
        country: "",
        phone: "",
        selected_card: "",
      },
      validationSchema: Yup.object().shape({
        name: newCard
          ? Yup.string().required().trim().min(3).max(20)
          : Yup.string(),
        country: newCard ? Yup.string().required() : Yup.string(),
        phone: newCard ? Yup.string().required().min(8).max(11) : Yup.string(),
        selected_card: newCard ? Yup.string() : Yup.string().required(),
      }),
      onSubmit: async (values) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        handleSubmit();
      },
    });


    const handleSubmit = async () => {
      setLoading(true);
      if (!stripe || !elements) {
        console.log("stripe not loaded");
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        if(reloadWindow){
                    window.location.reload();
                    return navigate("/");
                  }
        return;
      }

      if (!newCard) {
        if (!formik?.values?.selected_card) {
          return;
        } else {
          if (isAuctionPage) {
            return setShowBidModal(true);
          } else {
            return window.location.reload();
          }
        }
      }

      const cardElement = elements.getElement("card");
      const data = { name: formik?.values?.name };

      if (cardElement) {
        stripe
          .createToken(cardElement, data)
          .then((payload) => {
            setSelectedCard(payload?.token?.card?.id);
            if (payload?.token?.id) {
              // setSelectedCard(payload)
              const data = {
                token: payload?.token?.id,
                country: formik?.values?.country,
                phone_number: formik?.values?.phone,
              };
              Services.User.SaveCard(data)
                .then((res: any) => {
                  setLoading(false);
                  if(reloadWindow){
                    window.location.reload();
                    return navigate("/");
                  }
                  if (!isAuctionPage && !user.is_registered_bidder) {
                    return navigate("/");
                  }
                  if (res.status) {
                    setShowBidModal(!hideBidModal && isAuctionPage);
                    if (!user.is_registered_bidder) {
                      user.is_registered_bidder = true;
                      Services.Storage.updateUser(user);
                      return window.location.reload();
                    }
                  }
                  hideAgreementModal && hideAgreementModal();
                })
                .catch((error: any) => {
                  setLoading(false);
                  if(reloadWindow){
                    window.location.reload();
                    return navigate("/");
                  }
                  if (!isAuctionPage) {
                    return navigate("/");
                  }
                });
            } else {
              setLoading(false);
              if (!isAuctionPage) {
                return navigate("/");
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            if(reloadWindow){
                    window.location.reload();
                    return navigate("/");
                  }
            if (!isAuctionPage) {
              return navigate("/");
            }
          });
      }
    };

    const cardElementOptions = {
      style: {
        base: {
          fontSize: "16px",
          color: "#424770",
          "::placeholder": {
            color: "#aab7c4",
          },
          paddingTop: "10px",
          border: "1px solid #ced4da", // Add border styling
          borderRadius: "4px", // Optional: Add border-radius for rounded corners
          padding: "10px", // Optional: Add padding for better visual appearance
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };

    const fetchUserCreditCards = () => {
      setLoading(true);
      Services.User.FetchCards(offset, limit)
        .then((res: any) => {
          setLoading(false);
          if (res?.status && res?.data?.length > 0) {
            setUserCards(
              res?.data?.map((item: any) => {
                return {
                  ...item,
                  label:
                    (item?.name?.split(" ")?.length > 0
                      ? item?.name
                          ?.split(" ")
                          ?.map((n: any) => {
                            return n
                              ? n[0]?.toUpperCase() +
                                  (n.substring(1)?.toLowerCase()
                                    ? n.substring(1)?.toLowerCase()
                                    : "")
                              : "";
                          })
                          ?.join(" ")
                      : item?.name) +
                    " " +
                    item?.brand?.toUpperCase() +
                    " " +
                    t("checkout_form.expired_at_text") +
                    " " +
                    item.exp_month +
                    "-" +
                    item.exp_year,
                };
              })
            );
            setNewCard(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    useEffect(() => {
      showCreditCard && fetchUserCreditCards();
    }, [showCreditCard]);

    return (
      <>
        {loading && <PreLoaderComponent2 />}
        <div>
          {userCards?.length > 0 && (
            <div className="pb-4 flex gap-2 items-center">
              <div className="p1">
                <input
                  type="radio"
                  name="cards"
                  onChange={(e) => setNewCard(true)}
                  className="text-[--main-color] radioInput"
                />
                <label className="px-1" htmlFor="cards">
                  {t("checkout_form.new_card_label")}
                </label>
              </div>
              <div className="p1">
                <input
                  type="radio"
                  name="cards"
                  onChange={(e) => setNewCard(false)}
                  checked={!newCard}
                  className="text-[--main-color] radioInput"
                />
                <label className="px-1" htmlFor="cards">
                  {t("checkout_form.saved_card_label")}
                </label>
              </div>
            </div>
          )}
          <form onSubmit={formik?.handleSubmit}>
            {userCards?.length > 0 && !newCard ? (
              <div className="flex-col w-full pb-4 p1">
                <label className="px-1" htmlFor="creditCards">
                  {t("checkout_form.credit_card_label")}
                  <sup className="requireField">*</sup>
                </label>
                <Autocomplete
                  disableClearable={true}
                  name="creditCards"
                  options={userCards}
                  onChange={(e: any, value: any) => {
                    formik?.setFieldTouched("selected_card", true, false);
                    formik?.setFieldValue("selected_card", value?.id);
                    setSelectedCard(value?.id);
                  }}
                  onBlur={formik?.handleBlur}
                  placeholder="Select a Credit Cards"
                  sx={
                    formik?.errors?.selected_card &&
                    formik?.touched?.selected_card
                      ? Utils.Theme.JoyInputError
                      : Utils.Theme.JoyGrayShadeInputStyle2
                  }
                />
                <WarningAlert
                  message={
                    formik?.touched?.selected_card
                      ? formik?.errors?.selected_card
                      : ""
                  }
                />
              </div>
            ) : (
              <>
                <div className="flex-col w-full pb-4 p1">
                  <label className="px-1" htmlFor="name">
                    {t("checkout_form.name_label")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Input
                    name="name"
                    variant="outlined"
                    size="sm"
                    value={formik?.values?.name}
                    onChange={formik?.handleChange}
                    slotProps={{
                      input: {
                        maxLength: 150,
                        width: "100%",
                      },
                    }}
                    placeholder="Name"
                    onBlur={formik.handleBlur}
                    sx={
                      formik?.touched?.name && formik?.errors?.name
                        ? Utils.Theme.JoyInputError
                        : Utils.Theme.JoyGrayShadeInputStyle2
                    }
                    className="2xl:py-2"
                  />
                  <WarningAlert
                    message={formik?.touched?.name ? formik?.errors?.name : ""}
                  />
                </div>
                <div className="flex-col w-full pb-4 p1">
                  <label htmlFor="card-details" className="py-3 font-bold">
                    {t("checkout_form.card_details_label")}
                  </label>
                  <CardElement id="card-details" options={cardElementOptions} />
                </div>
                <div className="pb-4">
                  <h4 className="font-bold head">
                    {t("checkout_form.contact_title")}
                  </h4>
                  <p className="p1 opacity-75 pb-2">
                    {t("checkout_form.phone_number_label")}
                  </p>
                  <div>
                    <div className="flex justify-start items-center gap-2 p1">
                      <Autocomplete
                        disableClearable={true}
                        name="country"
                        options={countryOptions}
                        onChange={(e: any, selectedOption: any) => {
                          formik?.setFieldTouched("country", true, false);
                          formik?.setFieldValue(
                            "country",
                            selectedOption.value
                          );
                        }}
                        onBlur={formik?.handleBlur}
                        placeholder="Select a Country"
                        sx={
                          formik?.errors?.country && formik?.touched?.country
                            ? Utils.Theme.JoyInputError
                            : Utils.Theme.JoyGrayShadeInputStyle2
                        }
                      />
                      <div className="InputFieldSetting">
                        <Input
                          name="phone"
                          variant="outlined"
                          size="sm"
                          value={formik?.values?.phone}
                          onChange={formik?.handleChange}
                          slotProps={{
                            input: {
                              maxLength: 150,
                            },
                          }}
                          type="number"
                          placeholder="Phone Number"
                          onBlur={formik.handleBlur}
                          sx={
                            formik?.touched?.phone && formik?.errors?.phone
                              ? Utils.Theme.JoyInputError
                              : Utils.Theme.JoyGrayShadeInputStyle2
                          }
                          className="2xl:py-2 w-full"
                        />
                      </div>
                    </div>
                    <WarningAlert
                      message={
                        formik?.touched?.phone ? formik?.errors?.phone : ""
                      }
                    />
                  </div>
                </div>
              </>
            )}
            <div className="pb-4">
              <ThemeProvider theme={Utils.Theme.ButtonTheme}>
                <Button
                  variant="contained"
                  color={isLoading ? "inherit" : "primary"}
                  size="medium"
                  sx={{ textTransform: "none" }}
                  type="submit"
                  disabled={isLoading}
                  fullWidth
                >
                  {userCards?.length > 0
                    ? t("checkout_form.submit_button_texts.1")
                    : t("checkout_form.submit_button_texts.2")}
                </Button>
              </ThemeProvider>
            </div>
          </form>
        </div>
        <CreateBid
          showBid={showBidModal}
          setShowBid={setShowBidModal}
          setShowCheckoutModal={setShowModal}
          auction_info={auction_info}
          getListOfAuction={getListOfAuction}
          getComments={getComments}
          selectedCard={selectedCard}
        />
      </>
    );
  }
);

export default CheckoutForm;
